export const englishTextsMobile = {
        'soluciones y productos distribuidos': 'Solutions and distributed products',
        'Nuestra historia ': 'Our Story',
        'Nuestro origen': 'Our Origin',
        'Filosof\xEDa ': 'Philosophy',
        'RSE': 'CSR',
        'Nuestros aliados ': 'Our partners',
        'Robot davinci': 'da Vinci Robot',
        'Llevando la precisión quirúrgica más allá ': 'Taking surgical precision beyond ',
        'de los límites de nuestras manos':'the limits of our hands',
        'Conoce Más 2': 'Learn more',
        'Representamos las marcas ':'We represent the most',
        'de mayor prestigio a nivel ':'prestigious brands worldwide',
        'mundial en investigación y':'in health technology',
        'desarrollo de ':'research and',
        'tecnología en salud':'development.',
        'El origen de nuestra ':'Our origin story ',
        'historia es hoy la esencia ':'is now theessence of',
        'de nuestra marca':'our brand',
        'Conoce más de ':'Learn more about',
        'Grupo Amarey dando clic ':'Amarey Group by clicking',
        'Grupo Amarey dando': 'Amarey Group by ',
        'clic en cada uno ': 'clicking on each one',
        'en cada uno ':'on each one',
        'El servicio es uno de los pilares del Grupo ': 'The service is one of the pillars of the Amarey',
        'Amarey, una misión que se ha convertido en ': 'Group,a mission that has become the companys',
        'la vocación de la compañía para cumplir el ': 'become the companys vocation to fulfill the ',
        'compromiso de ser un actor determinante ':'commitment of being a decisive actor in the ',
        'en la labor de cuidar y salvar vidas.': 'task of caring for and saving lives.',
        'Grupo Amarey, la cadena que ':'Amarey Group, the chain',
        'salva vidas. ':'that saves lives.',
        'NUESTROS CLIENTES, ': 'Our clients, our',
        'NUESTRA RAZÓN    ':"purpose for being:",
        'DE SER': '',
        'Resultados medición de ':'Experience and loyalty measurement',
        ' experiencia y ':'' ,
        'lealtad (NPS) 2023:':'results (NPS) for 2023:',
        'NPS: Net Promoter Score ':'',
        'Cobertura': 'Coverage',
        'SERVICIO LOGÍSTICO': 'World-class',
        'DE CLASE MUNDIAL':'logistics service',
        'Nuestra red en ':'Our distribution ',
        'distribución incluye ':'network includes ',
        '18 transportadoras':'18 carriers that ',
        'que nos permiten ':'allow us to reach',
        'llegar a ':'any part ',
        'cualquier ':'of the',
        'parte del país':'country.',
        'Entregas de urgencias ': 'Emergency the 4-hour',
        'en ': 'deliveries ',
        '4 horas': '',
        'Pedidos electrónicos ': 'Electronic orders ',
        'Trazabilidad y ': 'Cold Chain ',
        'monitoreo de la ': 'Traceability',
        'Seguimiento por ':"GPS tracking",
        'GPS para garantizar ':'to guarantee',
        'Equipo logístico de      ':'A 91-person logistics ',
        '91 personas en ':'team available in',
        'Colombia, Panamá ':'Colombia, Panama,',
        'y Costa Rica ':'and Costa Rica',
        'Portafolio': 'Portfolio',
        'Somos un grupo empresarial con una clara ': 'We are a business group with a clear ',
        'inspiración profesional y humana; ofrecemos un ':'professional and humane inspiration, offering',
        'portafolio de soluciones en salud de la más alta ':'a high quality, patient-care-focused health ',
        'calidad para el cuidado de los pacientes. ':'solutions portfolio',
        'y genética': 'and genetics',
        'Instrumental': 'Instrumental',
        'y esterilización': 'and sterilization',
        'Logística': 'Hospital',
        'hospitalaria': 'logistics',
        'Mapa': "Map",
        'Línea de atención al cliente 018000 180066 - ': 'Customer service line 018000 180066 -',
        'Reporte de eventos adversos o PQR: ': 'Report adverse events or Claims: ',
        'Línea ética: linea.etica@grupoamarey.com': 'Ethics line: linea.etica@grupoamarey.com',
        'Soluciones en salud​': 'Health Solutions',
        'Ética y compliance​': 'Ethics and Compliance',
        'Trabaje con nosotros': 'Work with us',
        'Nuestra Historia': 'OUR HISTORY',
        'Filosofía': 'Philosophy',
        'Reconocimientos': 'RECOGNITIONS',
        'Nuestros aliados':'OUR ALLIES',
        'Logistica': 'Hospital',
        'hospitalario': 'logistics',
        'Mantenimiento Biomédico': 'BIOMEDICAL MAINTENANCE ',
        'Eventos': 'Events',
        'Noticias': 'News',
        'NUESTRA HISTORIA ': 'OUR HISTORY',
        'Grupo Amarey es una compañía con más de 40 ':'Grupo Amarey is a company with over 40 years',
        'años de trayectoria, comprometida con ':'of experience, dedicated to bringing knowledge,',
        'acercar el conocimiento, la ciencia y la ': 'science, and technology to healthcare sectors',
        'tecnología al sector de la salud en Colombia, ':'in Colombia, Panama, and Costa Rica.',
        'Panamá y Costa Rica.  ':'',
        'Con 6 sedes en Colombia y operación ':'With six different facilities in Colombia and',
        'internacional en Panamá y Costa Rica, el grupo ':'international operations in both Panama and',
        'empresarial cuenta con un equipo de ':'Costa Rica, our business group has a team of',
        'colaboradores que trabajan bajo una única ':'collaborators all work ing under a single premise:',
        'premisa, ': '',
        '“un compromiso inquebrantable ': '"an unwavering commitment to',
        'con la salud y la vida”.  ':'health and life."',
        'Nace Amarey Nova Medical':'Amarey Nova Medical is',
        'al obtener la distribución':'established after securing',
        'de la inmunoglobulina':'the distribution rights for',
        'humana IgG enriquecida,':'enriched human',
        'que le dio vida al':'immunoglobulin IgG,',
        'pentámero que ha sido el ':'which became the',
        'símbolo de la compañía. ':'company’s logo.',
        'Se crea la línea respiratoria ':'A respiratory solutions',
        'con el lanzamiento de un ':'line is introduced with the ',
        'surfactante pulmonar para ':'launch of a pulmonary ',
        'el tratamiento del síndrome ':'surfactant for treating',
        'de distrés respiratorio en ':'respiratory distress ',
        'neonatos':'syndrome in neonates.',
        'internacional UCITECH': 'brand, UCITECH.',
        "implantes ortopédicos de ":"orthopedic implants, ",
        "Stryker, equipos médicos, ":"medical equipment,",
        "pruebas  diagnósticas ":"oncological diagnostic ",
        "oncológicas y cuidado ":"tests, and advanced ",
        "avanzado de heridas ":"wound care.",
        "Se crea la línea de apoyo ":"The biomedical technology ",
        "en gestión de tecnología ":"management support line",
        "biomédica en el modelo ":"is established along with in",
        "inhouse y servicio de ":"house biomedical",
        "mantenimiento biomédico.":"maintenance services.",
        "robótico da Vinci en ":"da Vinci robotic system in ",
        "Colombia para cirugía ": "Colombia for minimally",
        "ínimamente invasiva.":"invasive surgery.",
        "Se inauguran cinco ":"Five company owned ",
        "sedes propias en ":"facilities are inaugurated",
        "Bogotá, Cali, Medellín, ":"in Bogotá, Cali, Medellín, ",
        "Barranquilla y Pereira.":"Barranquilla, and Pereira.",
        "Obtiene el Premio ":"Amarey wins the ",
        "Colombiano a la ":"Colombian Award for ",
        "Calidad de la Gestión.":"Management Quality.",
        "Los Premios Portafolio ":"Colombia’s Portfolio ",
        "otorgan la Mención de ":"Awards grant Honorable",
        "Honor a Amarey Nova ":"Mention to Amarey Nova ",
        "Medical en la categoría ":"Medical in the Customer ",
        "de Servicio al cliente. ":"Service category.",
        "positivamente con los más ": 'antibiotic solution, marking',
        "altos estándares de calidad e ": 'positive growth throughout',
        'El portafolio del Grupo ': 'The Amarey Group',
        "Amarey sigue creciendo   ":"expands its portfolio with",
        "con la representación de  ":"representation of KLS",
        "KLS Martin para ortopedia ":"Martin for",
        "cráneo maxilofacial, ":"craniomaxillofacial ",
        "instrumental y esterilización.":"orthopedics, instruments,",
        "2023-1-end": "and sterilization.",
        "Grupo Amarey renueva ":"Grupo Amarey undergoes",
        "su identidad de marca, ":"brand identity renewal, ",
        "logrando que refleje los ":"aligning it with the ",
        "valores, la cultura y la ":"company's values, ",
        "misi\xF3n de la compa\xF1\xEDa.":"culture, and mission.",
        "Distribución Logística – CEDI ":"Center CEDI is inaugurated",
        "en la zona industrial de ":"in the industrial zone of",
        "Montevideo en Bogotá, con ":"Montevideo in Bogotá, ",
        "el objetivo de lograr mejores ":"aiming to enhance logistics ",
        "niveles de servicio logístico ":"service levels for customers.",
        "para los clientes.":"",
        "Somos un grupo empresarial orgullosamente ":"We are a proudly Colombian business group that ",
        "colombiano que representa productos y ":"distribute quality products and services, guided",
        "servicios de calidad guiados por ":"by our SER philosophy (Service, Education, ",
        "nuestra filosofía SER (Servicio, Educación, ":"Relationships). ",
        "Relacionamiento). ":"",
        "Generamos valor a los pacientes, sus familias, ":"We generate value for patients, their families, ",
        "instituciones de salud y accionistas con un ":"health institutions and shareholders with",
        "talento humano excepcional, con sentido ético y ":"exceptional human talent, with an ethical sense",
        "altamente comprometido con la salud y la vida.":"and highly committed to health and life.",
        "En el 2025 el Grupo Amarey será el ":"In 2025, the Amarey Group will be the preferred ",
        "aliado preferido de las instituciones de salud ":"ally of health institutions and manufacturers of",
        "y de los fabricantes de ":"specialized health products, in Colombia and ",
        "productos especializados, en Colombia y los ":"Latin America. ",
        "países del área andina y Centroamérica.":"",
        "Esto será posible por nuestra trayectoria, ":"This will be possible due to our track record,",
        "reputación, por cumplir lo que prometemos y ":"reputation, for delivering what we promise and ",
        "por la capacidad para ofrecer soluciones ":"for our ability to offer innovative comprehensive",
        "integrales innovadoras.":"solutions. ",
        "Valoramos y respetamos ":"We value and respect the",
        "el punto de vista, el saber ":"point of view, knowledge",
        "y el quehacer de otros. ":"and work of others. We",
        "Nos caracteriza el ":"and work of others. We",
        "relacionamiento basado ":"cmaintaining relationships",
        "en las buenas maneras.":"based on good manners. ",
        "Realizamos las cosas con":"We do things with quality, ",
        "calidad, oportunidad y ":"opportunity and attitude",
        "actitud desde el principio, ":"from the beginning, fulfilling ",
        "cumpliendo con la promesa ":"the promise of value to our ",
        "de valor a nuestros clientes ":"customers and promoting ",
        "y propendiendo con el ":"continuous improvement. ",
        "mejoramiento continuo.":"",
        "Actuamos con ":"We act with in-depth ",
        "conocimiento profundo ":"knowledge about the",
        "sobre los bienes y ":"goods and services we ",
        "servicios que le brindamos ":"provide to the community, ",
        "a la comunidad, ":"identifying ourselves with",
        "identificándonos con la ":"the corporate philosophy.",
        "filosofía corporativa.":"",
        "Nuestras actuaciones ":"Our actions are always ",
        "siempre se realizan a la luz ":"carried out in the public ",
        "pública y están regidas ":"light and are always ",
        "siempre por la buena ":"governed by good will, ",
        "voluntad, la buena fe y la ":"good faith and oriented",
        "orientación a los resultados ":"to achieve ethical and",
        "éticos y morales. ":"moral results.",
        "Sentido de":"Sense of",
        "responsabilidad":"responsibility",
        "con otros ":"with others",
        "Somos sensibles a las ":"We are sensitive to the",
        "necesidades de los demás ":"needs of others by",
        "comprometiéndonos con el ":"committing ourselves to",
        "mejoramiento de su calidad ":"improving their quality",
        "de vida y bienestar.": "of life and well-being.",
        "El Grupo Amarey trabaja bajo una única ":"The Amarey Group works under a single premise:",
        "premisa: la filosofía SER (Servicio, Educación ":"the SER philosophy (Service, Education and",
        "y Relacionamiento), pilares que marcan la ":"Relationships), pillars that",
        "Una compañía con sentido humano, que ":"We are a company with a humane sense, ",
        "ofrece un servicio que trasciende hacia brindar ":"offering a service that transcends all others by ",
        "más oportunidades para la vida. ":"providing more opportunities for life. ",
        "Representa el compromiso del Grupo Amarey ":"The Amarey Group is committed to the ",
        "con la generación de conocimiento para el ":"generation of knowledge for HCPs, contributing ",
        "personal de la salud, contribuyendo a la ":"to the construction of a society that offers",
        "construcción de un país que ofrezca mayores ":"greater benefits for patient’s quality of life.",
        "beneficios para la calidad de vida de los ":"",
        "pacientes. ":"",
        "Ha permitido construir la base de confianza y ":"It has allowed the Amarey Group to build a solid ",
        "reputación del Grupo Amarey frente a sus ":"foundation of trust and reputation amongst all",
        "públicos de interés. Hoy la compañía es ":"its stakeholders. Today the company is ",
        "reconocida por su integridad, compromiso, ":"recognized for its integrity, commitment,",
        "excelencia, humanidad e innovación. ":"excellence, humanity and innovation.",
        'Certificado Novatécnica Sistema ':'Quality Management System',
        'de gestión de calidad en ':'Certificate in Medical Devices ',
        'dispositivos médicos ISO 13485':'ISO 13485 Novatécnica.',
        'Certificación OEA (Operador ':'Colombia’s DIAN OEA ',
        'Económico Autorizado) de la ':'(Authorized Economic Operator) ',
        'DIAN, obtenida en 2001, ':'Certificate obtained in 2001, for',
        'demostrando la seguridad en ':'demonstrating security in the ',
        'la cadena de suministro ':'companys international supply chain.',
        ' es una entidad sin ánimo ':' a non profit entity',
        'de lucro con ':'with',
        '18 años de trayectoria ':' 18 years of experience is an',
        ', que hace ':' integral part of',
        'parte del ':'the Amarey Group. Its mission is to foster the',
        'Grupo Amarey y que fue creada con el ':'',
        'propósito de promover el desarrollo humano y ':'human and professional development of',
        'profesional de jóvenes colombianos.':'young Colombians.',
        'Apoya a jóvenes con potencial académico y ':'The foundation extends support to individuals',
        'dificultades económicas para acceder a ': 'academic potential and financial constraints,',
        'educación superior de calidad, contribuyendo a ': 'enabling them to pursue top tier higher',
        'su formación como líderes y profesionales ':'education. This initiative contributes to their',
        'competitivos comprometidos con el desarrollo ': 'growth as leaders and competitive professionals',
        'social del país. Lo que hace la ': 'dedicated to the social advancement nation.',

        'avanzando de la nacion': 'The essence of what',
        'Fundación ': ' the OCMAES Foudation',
        'OCMAES ': 'does is simple yet profound:',
        ' es simple pero profundo: ': '',
        ' Por eso otorga ': 'University ',
        ' becas  ':'scholarships',
        ' universitarias a ': 'granted to ',
        ' estudiantes con ':'students with high',
        ' un alto potencial ':'academic',
        ' académico que ':'potential who find ',
        ' se encuentran en ':'themselves in',
        ' circunstancias ':'difficult economic',
        ' económicas y ':'and social ',
        ' sociales difíciles':'circumstances.',
        '+ de 4.600 beneficiarios': '+ de 4.600 beneficiaries',
        'Conoce más de La Fundación OCMAES ': 'Learn more about The OCMAES Foundation',
        'NUESTROS ': 'Our',
        'ALIADOS ':'ALLIES',
        'Somos la compañía de servicio técnico':'We are the largest biomedical technical ',
        'biomédico más grande del país y la primera':'service company in the country and the first',
        'empresa a nivel nacional en certificarse con':'company nationwide to be certified with the ',
        ' la ':'ICONTEC’s ISO 13485 standard for technical ',
        ' norma ISO 13485 de ICONTEC':'',
        'para el':'',
        ' servicio técnico de dispositivos médicos.':'service of medical devices. ',
        'Mantenimiento biomédico': 'BIOMEDICAL MAINTENANCE ',
        'Ofrecemos soluciones de mantenimiento':'We offer biomedical maintenance solutions for ',
        ' biomédico ':'all actors in the health sector of our markets. ',
        'para todos los actores del sector':'',
        'salud. Nuestro apoyo es ejecutado con':'Our support is executed with thorough training,',
        'entrenamiento, experiencia e innovación en los':'ample experience, and innovation across both ',
        'procesos humanos y técnicos.':'human and technical processes.',
        'Nuestro modelo de':'Our in-house maintenance and post-sale ',
        'mantenimiento inhouse y':'',
        'mantenimiento posventa':'maintenance model allows us to build ',
        'nos permiten construir':'',
        'soluciones personalizadas para cada cliente,':'personalized solutions for each client, ensuring',
        'asegurando los más altos estándares de calidad':'the highest quality standards and an efficient',
        'y un plan de mantenimiento eficiente para toda':'maintenance plan for all any biomedical ',
        '  la ':'technology involved. ',
        ' tecnología biomédica involucrada. ':'',
        'Nuestras manos expertas pueden entender y ':'Our expert hands can understand and take care',
        'cuidar la tecnología para la tranquilidad de ':'of any type of medical technology, for the peace ',
        'nuestros clientes y proveedores, todo para ':'of mind of our clients and suppliers, all focused',
        'crear soluciones para el cuidado de la salud y ':'on finding new and better solutions for patients ',
        'los pacientes. ':'and health care in general. ',
        '12.000 equipos ': '12.000 devices',
        'de marcas representadas': ' from all our represented brands.',
        'dedicados al cuidado de la tecnología ':'dedicated to the care of biomedical',
        'biomédica y comprometidos con la seguridad ':'technology and committed to patient safety.',
        'de los pacientes ': '',
        'Soluciones ':'SOLUTIONS AND',
        'en salud':'DISTRIBUTED PRODUCTS',
        'Genómica y':'Genomics and',
        'genética':'genetics',
        'Instrumental y':'Instruments and',
        'esterilización': 'sterilization',
        'Soluciones en salud': 'Solutions and distributed products',
        'MEDICAMENTOS  ': 'Medications',
        'Con medicamentos de la más alta tecnología ':'We deliver medications of the highest quality',
        'para el cuidado de la salud de los pacientes, ya ':'and cutting-edge technology, all center around',
        'sea adultos, niños o neonatos, ofrecemos mayor ':'the patients benefit. Regardless of age—adults,',
        'eficacia y tolerabilidad al paciente en ':' children, or neonates—we provide enhanced ',
        'productos.':'effectiveness and tolerability across all our',
        'productos-2': 'products.',
        'UCI Neonatología': 'Neonatology ICU',
        'CIRUGÍA ROBÓTICA': 'Robotic surgery',
        'Una extensión natural de los ojos, a través de ':'A natural extension of the human eye, through',
        'una visión en 3D y magnificación hasta 10 veces. ':'3D vision and 10x magnification. Intuitive ',
        'Movimientos intuitivos con mayor rango de ':'movements with a greater range of motion that ',
        'movimiento que exceden las capacidades de la ':'exceed the capabilities of the human hand.',
        'mano humana. ':'',
        'Somos el comercializador del sistema quirúrgico ': 'We proudly represent and distribute the da Vinci ',
        'da Vinci, con una experiencia de más de 3.000 ':'surgical system, with more than 3,000 surgeries ',
        'cirugías en nuestro país, una sofisticada ':'performed in our country, a sophisticated robotic ',
        'plataforma robótica que permite potenciar las ':'platform that allows enhancing the surgeon’s ',
        'capacidades del cirujano y ofrecer una opción ':'capabilities, thus offering a minimally invasive ',
        'mínimamente invasiva en procedimientos de ':'option in complex surgical procedures.  ',
        'cirugía compleja.  ':'',
        'El sistema da Vinci da tratamiento a cuatro de ':'The da Vinci system treats four of the five most',
        'los cinco tipos de cáncer más frecuente en ':'common types of cancer in Colombia: prostate',
        'Colombia: cáncer de próstata, cáncer de ':'cancer, stomach cancer, lung cancer, colorectal',
        'estómago, cáncer de pulmón, cáncer colorrectal ':'cancer and many more pathologies. ',
        'y muchas más patologías.':'',
        'Recuperación con menos dolor y riesgo de':'Recovery with less pain and less risk of',
        'infección. ': 'infection',
        'Rápido retorno a las actividades diarias.':'Quick return to daily activities ',
        'Movimientos intuitivos. Rotación de 540°,':'Intuitive movements, 540° rotation, 180° ',
        'articulación de 180°.':'articulation',
        'Mayor precisión y seguridad.':'Greater precision and safety ',
        'Atracción de nuevos pacientes y cirujanos. ': 'Attracting new patients and surgeons',
        'Con medicamentos de la más alta tecnología': 'We deliver medications of the highest quality',
        'EQUIPOS MÉDICOS': 'Medical equipment',
        'Ofrecemos a los especialistas equipos de la más ':'We offer specialists the highest technology',
        'alta tecnología para el servicio y asistencia de ':'equipment for patient service and assistance: ',
        'los pacientes.':'',
        ' Soluciones en salud' :'Solutions and distributed products',
        'Monitores de signos vitales': 'Vital signs monitors',
        'Centrales de monitoreo':'Monitoring centers',
        'Electrocardiografía':'Electrocardiography',
        'Ventilación ':'Ventilation',
        'Equipos para neurología:':'Neurology equipment: ',
        'electroencefalografía, polisomnografía y': 'electroencephalography, polysomnography, ',
        'electromiografía.':'and electromyography',
        'Camas y camillas hospitalarias':'Hospital beds and stretchers ',
        'Mesas de cirugía':'Surgery tables ',
        'Incubadora neonatal y lámparas de':'Neonatal incubator and phototherapy ',
        'fototerapia':'lamps',
        'Desfibrilador Externo Automático (DEA)':'Automatic External Defibrillator (AED)',
        'Lámparas para quirófano, procedimientos':'Lamps for operating room, procedures, and ',
        'y exploración':'examination ',
        'Unidades de suministro medicinales':'Medicinal supply units ',
        'Con una variada gama de soluciones para áreas ':'We offer an ample range of solutions for ',
        'especializadas, UCIs, quirófanos, urgencias y ':'specialized areas, ICU, operating rooms, ',
        'hospitalización. ':'emergencies, and hospitalization. ',
        'En cumplimiento de la Ley 1672 del 19 de julio de ':'In compliance with Colombia’s Law 1672, July 19, ',
        '2013, Decreto 284 de 2018 y Resolución 851 del ':'2013, Decree 284 of 2018 and Resolution 851 of',
        '2022. Amarey Nova Medical S.A, Ucipharma S.A. ':'2022, our group’s companies: Amarey Nova ',
        'y Novatécnica S.A.S., ponen a su disposición ':'Medical SA, Ucipharma SA and Novatécnica SAS, ',
        'nuestro proceso de disposición y gestión de los ':'put at your service a process for waste disposal ',
        'residuos de aparatos eléctricos y electrónicos ':'and waster management for electrical and',
        '(RAEE) relacionados específicamente con los ':'electronic equipment (WEEE), specifically related ',
        'equipos biomédicos eléctricos o electrónicos ': 'to electrical or electronic biomedical equipment ',
        '(con excepción de todos los productos ':'(with the exception of implanted and infected ',
        'implantados e infectados) que distribuye ':'products) distributed by our companies. For this ',
        'nuestras compañías. Para este fin, solicitamos ':'purpose, we kindly request that you contact our ',
        'comunicarse directamente con nuestro ':'sales representative directly assigned to your ',
        'representante de ventas asignado a su ':'institution in order to schedule this collection.',
        'institución con el objetivo de programar su ':'Management of final disposal of WEEE is ',
        'recolección. Para la disposición final de los RAEE ':'executed through a company authorized by the ',
        'realizamos la gestión a través de una empresa ':'national Environmental Authority. ',
        'autorizada por la Autoridad Ambiental.  ':'' ,
        'Somos líderes con tecnología que aporta a los ':'We are leaders in technology providing health',
        'profesionales de la salud y sus pacientes las ':'professionals and their patients the needed ',
        'herramientas en cirugías que permiten alcanzar ':'tools for surgeries that allow a more active and',
        'una calidad de vida más activa y satisfactoria ':'satisfactory life, specifically in the following four',
        'mediante las líneas de:  ':'verticals: ',
        'Trauma':'Trauma',
        'Reemplazo articular':'Articulation replacements',
        'Artroscopia':'Arthroscopy',
        'Cráneo y maxilofacial':'Skull and maxillofacial',
        'ortopedia ofrece disponibilidad 24/7 los 365':'Our orthopedics integral model of service offers ',
        'días del año, con un servicio que ofrece ':'availability 24/7, 365 days a year, providing',
        'acompañamiento en el quirófano y servicio ':'accompaniment both at the operating table and ',
        'posventa. ':'in post-sales service needs. ',
        'Contamos con una red logística de ortopedia ':'Our logistic network for orthopedic surgery ',
        'para atención de cirugías en ciudades ':'support has presence across the main and ',
        'principales e intermedias de Colombia y':'intermediate cities of both Colombia and',
        'Costa Rica. ':'Costa Rica. ',
        'LOGÍSTICA ':'Hospital',
        'HOSPITALARIA ':'logistics',
        'Nuestro portafolio de soluciones tecnológicas':'Our technological solutions portfolio is focused ',
        'está enfocado en la automatización de ':'on the automation of intrahospital processes for ',
        'procesos intrahospitalarios para el ':'medication and medical-surgical material ',
        'almacenamiento y la dispensación de ':'storage and dispensing, thus increasing patient ',
        'medicamentos y material médico-quirúrgico, ': 'safety and efficiency across all processes. ',
        'aumentando la seguridad del paciente y la ':'',
        'eficiencia en los procesos.':'',
        'Alineamos estos procesos a los lineamientos de ':'We align these processes with the guidelines of',
        'LA ':'the Joint Commission International (JCI), always ',
        'Joint Commission International,':'',
        'buscando las ':'',
        'mejores prácticas para asegurar la trazabilidad ':'seeking the best practices to ensure the ',
        'de medicamentos y generando en tiempo real ':'traceability of medicines and generating all',
        'los movimientos de inventario.':'inventory movements in real time. ',
        'Somos un aliado estratégico para los ':'We are a strategic ally for professionals who ',
        'profesionales que realizan procedimientos ':'perform surgical procedures with sophisticated',
        'quirúrgicos con equipos y dispositivos médicos ':'medical equipment and devices that improve',
        'que mejoran los resultados del paciente en ':'patient outcomes in an intervention.',
        'una intervención.':'',
        'Equipos y dispositivos médicos para':'Medical equipment and devices for ',
        'cirugía laparoscópica':'laparoscopic surgery',
        'Dispositivos para reparo de hernias':'Devices for inguinal hernia repair and ',
        'inguinales y ventrales':'ventral ',
        'Ropa quirúrgica y barreras de protección':'Surgical clothing and specialized ',
        'especializadas':'protective barriers',
        'Hemostáticos y sellantes para cirugía':'Hemostatic products and sealants for ',
        'mínimamente invasiva ':'minimally invasive surgery ',
        'Todos productos de nuestro portafolio están ':'All products in our portfolio are approved by the ',
        'aprobados por la FDA y cuentan con altos ':'FDA and have high quality standards.',
        'estándares de calidad. ':'',
        'Contamos con un centro de lavado con la última ':'We have a washing center with the latest ',
        'tecnología y estándares, para permitirnos ':'technology and standards, to allow us to offer a ',
        'ofrecer un producto con la mejor calidad y ':'product with the best quality and guarantee the ',
        'garantizar las condiciones sanitarias de kits de ':'sanitary conditions of used surgery kits. ',
        'cirugía utilizados. Adicionalmente, realizamos ':'Additionally, we provide surgical support during ',
        'acompañamiento quirúrgico en cirugía. ':'such surgeries. ',
        'Genómica  ':'Genomics',
        'La gen\xF3mica analiza la forma en que se ':'Genomics the study of sets of genes and their ',
        'comporta un conjunto de genes. ':'functions. ',
        'La gen\xE9tica es el estudio de rasgos y genes ':'Genetics is the study of inherited traits and ',
        'heredados que pueden predisponer a una ':'genes that may predispose a person to any ',
        'persona a una condici\xF3n de salud particular.':'health condition.',
        'Las pruebas genómicas (también conocidas ':'Genomic testing (also known as multigene ',
        'como pruebas multigénicas) analizan una ':'testing) analyzes a sample of a cancerous tumor ',
        'muestra del tumor del cáncer para analizar el ':'to understand the level of activity of certain ',
        'nivel de actividad de determinados genes, el ':'genes, which affect the behavior of the disease, ',
        'cual afecta el comportamiento del cáncer, ':'including growth and spread likelihoods. One of ',
        'incluyendo su probabilidad de crecimiento y ':'these genomic tests also provides an estimate',
        'propagación. Una de estas pruebas genómicas ':'of the benefit of chemotherapy. They are used to ',
        'en particular proporciona, además, una ':'help make decisions about treatment after ',
        'estimación del beneficio de la quimioterapia. Se ':'surgery. ',
        'usan para ayudar en la toma de decisiones ':'',
        'sobre tratamiento después de la cirugía. ':'',
        'En el caso del cáncer de mama puede ayudar a':'This test can help determine the aggressiveness of',
        'determinar la agresividad de un tumor o la':'a tumor or the probability that a certaintreatment',
        'probabilidad que un determinado tratamiento':'will improve the result specifically In the case of',
        'mejore el resultado, permitiendo escoger el plan':'breast cancer, allowing the physician to choose a',
        'de cuidado adecuado para el paciente.':'determined treatment plan best fitting for the patient.  ',
        'Genética': 'genetics',
        'Esto significa que si usted tiene un historial ':'This means that if you have a strong family ',
        'familiar marcado de cáncer de mama, es ':'history of breast cancer, you have likely ',
        'probable que haya heredado un gen anormal ':'inherited an abnormal gene linked to an ',
        'vinculado a un mayor riesgo de sufrir cáncer ':'increased risk of developing breast cancer ',
        'de mama (como las mutaciones de los genes ':'(such as mutations in the BRCA 1 or 2 genes).   ',
        'BRCA 1 o 2).':'',
        '¿Por qué realizar pruebas genómicas':'¿Why perform genomic and genetic tests? ',
        'y genéticas?': '',
        'Son las pruebas base de medicina de':'They are the basic tests of precision ',
        'precisión o medicina personalizada, ya que':'medicine or personalized medicine, ',
        'cada individuo es diferente.':'understanding that everyone is different. ',
        'Guían al profesional en la elección del':'They guide professionals in choosing the',
        'tratamiento más adecuado para el':'most appropriate treatment for patients',
        'paciente.':'',
        'Permite calcular el riesgo de recurrencia.':'They usher recurrence risk calculation',
        'Muestra un beneficio real para el paciente':'They show a real benefit for patients and ',
        'y su familia.':'their families ',
        'Se pueden analizar más de 3.800':'More than 3,800 diseases can be analyzed ',
        'enfermedades.':'',
        'Determinan calidad de vida del paciente. ':'They determine a patients quality of life ',
        'Con un completo portafolio de instrumentos ':'We have an ample portfolio of instruments for ',
        'para las diferentes especialidades quirúrgicas, y ':'different surgical specialties that optimizes the ',
        'la interacción entre los productos y los procesos ':'interaction between products and their washing',
        'de lavado y esterilización.':'and sterilization processes, our professional.',
        'nuestro equipo profesional está presto a brindar ':'Our team is ready to provide information, advice, ',
        'información, asesoría, acompañamiento, ':'support, suggestions and solutions to your plans',
        'sugerencias y soluciones a sus planes y ':'and projects with:',
        'proyectos con:':'',
        'CUIDADO AVANZADO':'Advanced',
        'DE HERIDAS ':'wound care',
        'Ofrecemos una línea de apósitos avanzados y ':'We offer a line of advanced medical dressings',
        'terapia de presión negativa para el tratamiento ':'and negative pressure therapy for the ',
        'integral de heridas agudas, crónicas y ':'comprehensive treatment of acute, chronic and',
        'complejas, como las ocasionadas por pie ':'complex wounds, like those caused by diabetic',
        'diabético, lesiones por presión y lesiones de ':'foot, pressure injuries and injuries of vascular',
        'origen vascular.':'origin.',
        'Ayudando al paciente a mejorar su calidad de ':'Thus helping the patient to improve their ',
        'vida, mediante:':'quality of life, through:',
        'Estimulación de la formación de':'Stimulation of the formation',
        'nuevo tejido':'of new tissue',
        'Nuestro compromiso es asesorar a los ':'Our commitment is to advise health ',
        'profesionales de la salud en el uso, manejo y ':'professionals in the correct use, implementation',
        'aprovechamiento correcto de dichas tecnologías. ':'and management of these technologies. ',
        'El ACV - Accidente Cerebro Vascular es algo que ':'A Cerebral Vascular Accident (CVA) can occur to ',
        'le puede ocurrir a 1 de cada 4 personas y menos ':'1 out of every 4 people and less than 5% of those',
        'del 5% de las personas que lo sufren son ':'who suffer from it are treated in time. ',
        'atendidas a tiempo.':'',
        'La ciencia viene evolucionando en forma rápida ':'Science has been evolving rapidly to identify and ',
        'para identificar y tratar esta patología conocida ':'treat this pathology known as a stroke, that can',
        'como ictus, dividido entre isquémico (trombo) y ':'be ischemic (thrombus) or hemorrhagic ',
        'hemorrágico (aneurisma), donde el ':'(aneurysm). This is where the youg discipline',
        'neurointervencionismo como disciplina joven se ':'known as neuro-interventionism is increasingly',
        'va consolidando como alternativa para ':'consolidating as an alternative for certain cases. ',
        'determinados casos. ':'',
        'Nuestro portafolio es reconocido por su calidad ':'Our portfolio is recognized for its quality and ',
        'y renombre, con fortalezas en opciones de ':'reputation, with strengths in ACCESS options, a',
        'ACCESO, una gran variedad de Coils y una ':'wide variety of Coils and a unique ',
        'tecnología única para trombectomía, incluyendo ':'thrombectomy technology, including a reverse ',
        'una estructura de logística inversa que nos ':'logistics structure that allows us to ensure a',
        'permite asegurar un servicio de alta calidad ':'high quality service for all time requirements and ',
        'para los requerimientos de tiempo y ':'all requested therapeutic window availability.',
        'disponibilidad en las ventanas terapéuticas. ':'',
        ' Neurointervencionismo ' :'Neurointerventionism',
        'GRUPO AMAREY EN SUS 40 ':'AMAREY GROUP LAUNCHES',
        'AÑOS ESTRENA SEDES':'NEW HEADQUARTERS ',
        'Gracias a su actual y exitoso crecimiento, ':'The successful growth of the company made it ',
        'unificó sus bodegas en un gran centro de ':'necessary to unify its warehouses into a large ',
        'distribución logística, CEDI y trasladó su sede ':'logistics distribution center, CEDI, and move its ',
        'administrativa a una exclusiva torre empresarial ':'administrative headquarters to an exclusive business tower. ',
        'El Grupo Amarey, una institución emblemática en ':'Amarey Group, an emblematic institution in ',
        'el ámbito de la salud en Colombia, está de ':'Colombia’s health market, is celebrating 40 years ',
        'celebración al cumplir 40 años de servicio ':'of dedicated service to the countrys medical ',
        'dedicado a la comunidad médica del país. En ':'community. In this important milestone, the ',
        'este hito importante, nos complace anunciar la ':'company is pleased to announce the opening of',
        'apertura de sus nuevas oficinas, ubicadas en ':'its new headquarters, located in one of the most ',
        'uno de los lugares más exclusivos de Bogotá. ':'exclusive locations in Bogotá.',
        'Como resultado de sus continuos esfuerzos y ':'As a result of its continuous efforts and excellent ',
        'excelentes resultados, el Grupo Amarey marca el ':'results, Amarey Group marks the beginning of a',
        'inicio de una nueva etapa en su desarrollo ':'new stage in its organizational development and',
        'organizacional y su visión de expansión hacia el ':'its expansion into the future. For this reason, the ',
        'futuro. Por ello, la sección administrativa de la ':'administrative section of the company opens a',
        'compañía estrena una nueva sede, que abarca ':'new headquarters, which covers more than 2,000',
        'más de 2.000 metros cuadrados en una torre ':'square meters in a business tower located in Bogotá.',
        'empresarial ubicada en la calle 100 con carrera ':'',
        'séptima en Bogotá. ':'',
        'Las nuevas instalaciones están diseñadas para ':'The new facilities are designed to provide ',
        'proporcionar espacios modernos y funcionales, ':'modern and functional spaces, including offices,',
        'incluyendo oficinas, salas de reuniones, ':'meeting rooms, a showroom, social areas and ',
        'showroom, áreas sociales y espacios de ':'coworking spaces. These facilities reinforce the ',
        'coworking. Estas instalaciones refuerzan la ':'new image Amarey Group and its commitment ',
        'nueva imagen del Grupo Amarey y su ':'to innovation and excellence. ',
        'compromiso con la innovación y la excelencia. ':'',
        'LIDERES EN DISTRIBUCIÓN': 'DISTRIBUTION LEADERS ',
        'Además de este traslado a sus nuevas oficinas ':'In addition to this, the company unified its ',
        'administrativas, la compañía unificó sus ':'warehouses to provide a more efficient service ',
        'bodegas para brindar un servicio más eficiente y ':'to its customers, with the opening of the ',
        'oportuno a sus clientes, con la apertura del ':'Logistics Distribution Center (CEDI), strategically ',
        'Centro de Distribución Logística (CEDI), ubicado ':'located in the industrial zone of Montevideo in',
        'estratégicamente en la carrera 68 con calle 17, ':'Bogotá. ',
        'en la zona industrial de Montevideo en Bogotá. ':'',
        'El CEDI está dotado con la más alta tecnología y ':'This CEDI is equipped with the highest',
        'los más altos estándares operativos enfocados ':'technology and operational standards focused ',
        'en optimizar los procesos de valor tanto para los ':'on optimizing value processes for both clients ',
        'clientes como para los pacientes. Este centro ':'and patients. This center allows delivery ',
        'permite cumplir de manera más efectiva las ':'promises to be met more effectively and improve',
        'promesas de entrega y mejorar la eficiencia en ':'efficiency throughout the operational chain.',
        'toda la cadena operativa. ':'',
        'Con el lanzamiento del CEDI, se reafirma una vez ':'With the launch of the CEDI, the commitment to',
        'más el compromiso de liderar la logística en el ':'lead logistics in the sector is reaffirmed once',
        'sector, manteniendo un servicio operativo las 24 ':'again, maintaining an operational service 24 ',
        'horas del día, los 7 días de la semana, los 365 ':'hours a day, 7 days a week, 365 days a year. This ',
        'días del año. Esto permitirá seguir sirviendo a ':'will allow the company to continue serving ',
        'instituciones y pacientes de manera ':'institutions and patients uninterruptedly, ',
        'ininterrumpida, garantizando la prontitud y ':'guaranteeing promptness and quality in each ',
        'calidad en cada entrega. ':'delivery. ',
        'GRUPO AMAREY EN SUS 40 AÑOS ESTRENA SEDES': 'AMAREY GROUP LAUNCHES NEW HEADQUARTERS',
        'GRUPO AMAREY EN SUS': 'AMAREY GROUP LAUNCHES',
        '40 AÑOS ESTRENA SEDES ':'NEW HEADQUARTERS ',
        'Otras noticias ':'In other news',
        'Grupo ': 'Amarey group',
        'amarey en ':'launches new',
        'sus 40 años ':'headquarters',
        'estrena ':'',
        'sedes ':'',
        'estrena sedes ': '',
        'GRUPO AMAREY EN SUS 40 AÑOS ':'Amarey group',
        'ESTRENA SEDES':' launches new headquarters ',
        'Gracias a su actual y exitoso crecimiento, unificó sus bodegas en un gran ':'The successful growth of the company made it necessary to unify its ',
        'centro de distribución logística, CEDI y trasladó su sede administrativa a una ':'warehouses into a large logistics distribution center, CEDI, and move its ',
        'exclusiva torre empresarial ':'administrative headquarters to an exclusive business tower. ',
        'El Grupo Amarey, una institución emblemática en el ámbito de la salud en Colombia, está de celebración al ':'Amarey Group, an emblematic institution in Colombia’s health market, is celebrating 40 years of dedicated service ',
        'cumplir 40 años de servicio dedicado a la comunidad médica del país. En este hito importante, nos complace ':'to the country∂s medical community. In this important milestone, the company is pleased to announce the',
        'anunciar la apertura de sus nuevas oficinas, ubicadas en uno de los lugares más exclusivos de Bogotá. ':'opening of its new headquarters, located in one of the most exclusive locations in Bogotá.',
        'Como resultado de sus continuos esfuerzos y excelentes resultados, el Grupo Amarey marca el inicio de una ':'As a result of its continuous efforts and excellent results, Amarey Group marks the beginning of a new stage in its ',
        'nueva etapa en su desarrollo organizacional y su visión de expansión hacia el futuro. Por ello, la sección ':'organizational development and its expansion into the future. For this reason, the administrative section of the',
        'administrativa de la compañía estrena una nueva sede, que abarca más de 2.000 metros cuadrados en una torre ':'company opens a new headquarters, which covers more than 2,000 square meters in a business tower located in Bogotá. ',
        'empresarial ubicada en la calle 100 con carrera séptima en Bogotá. ':'',
        'Las nuevas instalaciones están diseñadas para proporcionar espacios modernos y funcionales, incluyendo ':'The new facilities are designed to provide modern and functional spaces, including offices, meeting rooms, a',
        'oficinas, salas de reuniones, showroom, áreas sociales y espacios de coworking. Estas instalaciones refuerzan la ':'showroom, social areas and coworking spaces. These facilities reinforce the new image Amarey Group and its',
        'nueva imagen del Grupo Amarey y su compromiso con la innovación y la excelencia. ':'commitment to innovation and excellence. ',
        'Además de este traslado a sus nuevas oficinas administrativas, la compañía unificó sus bodegas para brindar un ':'In addition to this, the company unified its warehouses to provide a more efficient service to its customers, with ',
        'servicio más eficiente y oportuno a sus clientes, con la apertura del Centro de Distribución Logística (CEDI), ':'the opening of the Logistics Distribution Center (CEDI), strategically located in the industrial zone of Montevideo ',
        'ubicado estratégicamente en la carrera 68 con calle 17, en la zona industrial de Montevideo en Bogotá. ':'in Bogotá.',
        'El CEDI está dotado con la más alta tecnología y los más altos estándares operativos enfocados en optimizar los ':'This CEDI is equipped with the highest technology and operational standards focused on optimizing value ',
        'procesos de valor tanto para los clientes como para los pacientes. Este centro permite cumplir de manera más ':'processes for both clients and patients. This center allows delivery promises to be met more effectively and ',
        'efectiva las promesas de entrega y mejorar la eficiencia en toda la cadena operativa. ':'improve efficiency throughout the operational chain. ',
        'Con el lanzamiento del CEDI, se reafirma una vez más el compromiso de liderar la logística en el sector, ':'With the launch of the CEDI, the commitment to lead logistics in the sector is reaffirmed once again, maintaining ',
        'manteniendo un servicio operativo las 24 horas del día, los 7 días de la semana, los 365 días del año. Esto ':'an operational service 24 hours a day, 7 days a week, 365 days a year. This will allow the company to continue ',
        'permitirá seguir sirviendo a instituciones y pacientes de manera ininterrumpida, garantizando la prontitud y ':'serving institutions and patients uninterruptedly, guaranteeing promptness and quality in each delivery. ',
        ' calidad en cada entrega.':'',
        'Prográmese con Grupo Amarey ': 'Schedule withGrupo Amarey',
        '02 de febrero de 2024': 'February 2, 2024',
        'Febrero 2024 ': 'February 2024',
        'Do ':'Su',
        'Lu ':'Mo',
        'Ma ':'Tu',
        'Mi ':'We',
        'Ju ':'Th',
        'Vi ':'Fr',
        'Sa ':'Sa',
        '06 de marzo de 2024': 'March 6, 2024',
        'ÉTICA Y COMPLIANCE ': 'Code of Ethics',
        'En el Grupo Amarey estamos comprometidos en ':'At the Amarey Group, we are committed to ',
        'mantener relaciones honestas, constructivas e ':'maintaining honest, constructive, and ',
        'idóneas con nuestros grupos de interés. Guiados ':'appropriate relationships with all our ',
        'por nuestros valores corporativos hemos creado ':'stakeholders. Guided by our set of corporate',
        'el presente Código de ética, a través del cual ':'values, we have created a code of ethics to',
        'establecemos las reglas de conducta ética que ':'establish the rules of ethical conduct that must ',
        'deben ser seguidas y observadas en su ':'be followed and observed by all individuals',
        'comportamiento diario por todas las personas ':'associated with the company on a daily basis. ',
        'vinculadas a la empresa, tanto los miembros de ':'This includes members of the board of directors, ',
        'la junta directiva, directores, empleados de ':'C-suite executives, managers, and employees at',
        'todos los niveles, proveedores, asesores y ':'all levels as well as suppliers, advisors, ',
        'contratistas, así como para los empleados ':'contractors, and indirect employees.',
        'indirectos. ':'',
        'Ver Código de ética': 'Read our Code of Ethics',
        'La línea ética es un canal diseñado para que ':'The ethics line is a channel designed for ',
        'empleados, proveedores, aliados estratégicos y ':'employees, suppliers, strategic allies, and clients ',
        'clientes puedan reportar de forma anónima y ':'to anonymously and confidentially report ',
        'confidencial presuntas violaciones a nuestro ':'alleged violations of our code of ethics, as well',
        'Código de ética, así como posibles actos ':'as possible incorrect acts, fraud, corruption, ',
        'incorrectos, fraudes, corrupción, acoso y otros ':'harassment, and other aspects that might ',
        'aspectos que afecten la imagen y buen nombre ':'affect the good name and image of the ',
        'de la compañía.':'company.',
        'Las denuncias pueden realizarse a través del ':'Reports can be made via email seven days a ',
        'correo electrónico los 7 días de la semana, las ':'week, 24 hours a day.',
        '24 horas del día.':'',
        '¿Cómo puedo comunicarme con la línea ética? ':'How can I contact the ethics line?',
        'A través del correo electrónico: ':'Through email: linea.etica@grupoamarey.com',
        'linea.etica@grupoamarey.com':'',
        '¿Cuándo debo utilizar': 'When should I use',
        'la línea ética?':'the ethics line?',
        'Cuando identifique situaciones no éticas que ' :'You should use the ethics line when you ' ,
        'puedan afectar los intereses del Grupo ':'identify unethical situations that may affect ',
        'Amarey como: ':'the interests of the Amarey Group, such as:',
        'Incumplimiento de ':'Non-compliance with ',
        'leyes y regulaciones':'laws and regulations',
        'La situación en virtud de la cual una ':'This occurs when a person, due to ',
        'persona, por su actividad o cargo, o ':'their activity or position, or due to the',
        'por la información que posee, se ':'information they possess, is faced',
        'enfrenta a distintas alternativas de ':'with different behavioral alternatives',
        'conducta en relación con intereses ':'in relation to incompatible interests., ',
        'incompatibles, siendo incorrecto ':'where it would be incorrect to ',
        'privilegiar alguno de ellos, pues se ':'privilege any of them, as it would ',
        'vulneraría o debilitaría injustamente ':'unfairly violate or weaken the position ',
        'la posición del otro.':'of the other.',
        'Negocios de la compañía que no se ':'This refers to any business related to ',
        'realicen dentro del marco legal y/o que ':'the company that would not be carried ',
        'sus colaboradores no obren dentro de ':'out within a legal framework and/or',
        'este. Incumplimiento a lo dispuesto en ':'when collaborators do not act within it.',
        'el código de ética, reglamento interno ':'It includes non-compliance with the ',
        'de trabajo, políticas de contratación y ':'provisions of the code of ethics, internal',
        'pago a terceros, reglamentos, políticas ':'work regulations, third-party hiring and',
        'y/o procedimientos específicos ':'payment policies, regulations, and/or other',
        'establecidos por el Grupo Amarey.':'specific policies and procedures established by the Amarey Group.',
        'Confidencialidad ': 'Confidentiality',
        'Los empleados utilizarán la ':'Employees are expected to use non-',
        'información no pública que sea de su ':'public information they have access ',
        'conocimiento, para realizar las ':'to in a confidential and reserved ',
        'actividades que desempeñen, de ':'manner. They should refrain at all ',
        'manera confidencial y reservada, y ':'times from obtaining or receiving ',
        'se abstendrán en todo momento de ':'personal benefits or causing damage ',
        'obtener o recibir beneficios ':'to the company due to the disclosure',
        'personales o provocar perjuicios a la ':'or improper use of such information.',
        'empresa por su divulgación o ':'',
        'utilización indebida.':'',
        'Son operaciones que busca dar ':'These are operations that give the ',
        'apariencia de legalidad a recursos ':'appearance of legality seeking ',
        'provenientes de actividades ilícitas, ':'resources coming from illicit ',
        'a través de diversas transacciones ':'activities through various ',
        'ocultando así el origen y trayectoria ':'transactions, thus hiding the origin',
        'de los recursos.':'and trajectory of such resources.',
        'Actividades ': 'Inappropriate',
        'inapropiadas':'activities',
        'La relación entre los empleados del ':'Amarey Group employees must always',
        'Grupo Amarey deberá estar siempre ':'conduct their interpersonal ',
        'basada en el debido y mutuo respeto, ':'engagements with a basis of mutual ',
        'a fin de asegurar un ambiente ':'respect to ensure a harmonious ',
        'armonioso conducente al trabajo ':'environment conducive to productive ',
        'productivo.':'work.',
        'En la relación con los clientes de ':'When engaging with the company’s',
        'Grupo Amarey, los empleados de la ':'clients, Grupo Amarey’s employees',
        'organización siempre deberán actuar ':'must always act with objectivity, ',
        'con objetividad, imparcialidad, ':'impartiality, fairness, excellence, and',
        'equidad, excelencia y de acuerdo con ':'in accordance with the companys',
        'los objetivos de imagen, crecimiento ':'image, growth, and profitability ',
        'y rentabilidad de la empresa.':'objectives.',
        'Son todas aquellas ventajas, artículos ':'These include all advantages, all items, ',
        'o incentivos en dinero o especie que ':'or incentives in the form of money or ',
        'se entregan a terceros (cualquier ':'any other kind given to third parties ',
        'relacionado con compra, distribución, ':'(meaning, people related to the ',
        'comercialización, prescripción y ':'purchase, distribution, marketing, ',
        'dispensación de fármacos, ': 'prescription, and dispensing of ',
        'dispositivos médicos o insumos de ':'medicines, medical devices, or health',
        'salud), con el propósito de obtener ':'supplies) with the purpose of ',
        'beneficios comerciales que no se ':'obtaining commercial benefits that do',
        'ajustan a los principios de la ':'not comply with the principles of ',
        'promoción ética, de la competencia ':'ethical promotion, fair competition, ',
        'leal y del respeto por los principios, la ':'and respect for general principles, ',
        'moral y el buen actuar.':'morality and good behavior.',
        'Juntos trabajamos por ':'Together we work to achieve',
        'alcanzar nuestro prop\xF3sito de ':'our purpose of an unwavering ',
        'un compromiso inquebrantable ':'commitment to health and life.',
        'con la salud y la vida.':'',
        '\xBFC\xF3mo unirse?': 'How to join? ',
        'Cada d\xEDa nos esforzamos por el bienestar de ':'Every day we strive for the well-being of our ',
        'nuestros colaboradores. Somos una empresa de ':'collaborators. We are a company with ',
        'capital colombiano ':'Colombian capital, committed to health and',
        ', comprometida con la salud ': '',
        'y dedicada a ampliar las oportunidades de vida.':'dedicated to expanding life opportunities. ',
        'En Grupo Amarey, sabemos que el talento ':'At Grupo Amarey, we know that exceptional ',
        'excepcional es la clave para hacer ':'talent is the key to doing incredible things and',
        'cosas ':'',
        'incre\xEDbles y alcanzar resultados extraordinarios. ':'achieving extraordinary results. We generate ',
        'Generamos':'inclusive, collaborative, challenging and ',
        'entornos de trabajo inclusivos, ':'',
        'colaborativos, retadores y estimulantes':'stimulating work environments where each ',
        ' donde ':'',
        'cada colaborador puede aportar, crecer y ':'employee can grow and contribute significantly',
        'contribuir de manera significativa al':'to the achievement of our objectives. ',
        ' logro de ':' ',
        'nuestros objetivos.':'',
        'Estamos en b\xFAsqueda de personas':'We are looking for passionate and committed ',
        'apasionadas ':'',
        'y comprometidas,':'people who share and reflect our values, aligned ',
        'que ':'',
        'compartan y reflejen':'',
        'nuestros valores, ':'with the SER philosophy (Service, Education and ',
        ' alineados con':'',
        ' la':'',
        'Filosofia ser':'',
        '(Servicio, Educaci\xF3n y Relacionamiento)':'Relationships) and that are willing to take on ',
        'y est\xE9n ':'',
        'dispuestos a asumir retos diarios y llevar la ':'daily challenges to lead the company to ',
        'Organizaci\xF3n a otros niveles. ':'success.',

        'Si est\xE1s interesado en formar parte del Grupo ':'If you are interested in being part of the Amarey ',
        'Amarey, te invitamos a enviar tu hoja de vida a ':'Group, we invite you to send your resume to',
        'atracciondetalento@grupoamarey.com ':'atracciondetalento@grupoamarey.com, ',
        ',':'',
        'indicando la ciudad donde vives.':'indicating the city where you live. ',


}       


export const spanishTextsMobile = {
        'Nuestra historia ': 'Nuestra historia ',
        'Nuestro origen': 'Nuestro origen',
        'Filosof\xEDa ':'Filosof\xEDa ',
        'RSE':'RSE',
        'Nuestros aliados ': 'Nuestros aliados ',
        'Robot davinci':'Robot davinci',
        'Llevando la precisión quirúrgica más allá ': 'Llevando la precisión quirúrgica más',
        'de los límites de nuestras manos':'allá de los límites de nuestras manos',
        'Representamos las marcas ':'Representamos las marcas ',
        'de mayor prestigio a nivel ':'de mayor prestigio a nivel ',
        'mundial en investigación y':'mundial en investigación y',
        'desarrollo de ':'desarrollo de ',
        'tecnología en salud':'tecnología en salud',
        'El origen de nuestra ':'El origen de nuestra ',
        'historia es hoy la esencia ':'historia es hoy la esencia ',
        'de nuestra marca':'de nuestra marca',
        'Conoce más de ':'Conoce más de ',
        'Grupo Amarey dando clic ':'Grupo Amarey dando clic ',
        'en cada uno ':'en cada uno ',
        'El servicio es uno de los pilares del Grupo ': 'El servicio es uno de los pilares del Grupo',
        'Amarey, una misión que se ha convertido en ': 'Amarey, una misión que se ha convertido en',
        'la vocación de la compañía para cumplir el': 'la vocación de la compañía para cumplir el',
        'compromiso de ser un actor determinante ':'compromiso de ser un actor determinante ',
        'en la labor de cuidar y salvar vidas.': 'en la labor de cuidar y salvar vidas.',
        'Grupo Amarey, la cadena que ':'Grupo Amarey, la cadena que ',
        'salva vidas. ':'salva vidas. ',
        'NUESTROS CLIENTES, ':'NUESTROS CLIENTES, ',
        'NUESTRA RAZÓN    ':'NUESTRA RAZÓN    ',
        'DE SER':'DE SER',
        'Resultados medición de ':'Resultados medición de',
        ' experiencia y ':' experiencia y ' ,
        'lealtad (NPS) 2023:':'lealtad (NPS) 2023:',
        'NPS: Net Promoter Score ':'NPS: Net Promoter Score ',
        'Cobertura': 'Cobertura',
        'SERVICIO LOGÍSTICO ': 'SERVICIO LOGÍSTICO ',
        'DE CLASE MUNDIAL':'DE CLASE MUNDIAL',
        'Nuestra red en ':'Nuestra red en ',
        'distribución incluye ':'distribución incluye ',
        '18 transportadoras':'18 transportadoras',
        'que nos permiten ':'que nos permiten ',
        'llegar a ':'llegar a ',
        'cualquier ':'cualquier ',
        'parte del país':'parte del país',
        'Entregas de urgencias ': 'Entrega de urgencias ',
        'en ': 'en ',
        '4 horas': '4 horas',
        'Pedidos electrónicos ': 'Pedidos electrónicos ',
        'Trazabilidad y ': 'Trazabilidad y ',
        'monitoreo de la ': 'monitoreo de la ',
        'Seguimiento por ': 'Seguimiento por ',
        'GPS para garantizar ':'GPS para garantizar ',
        'Equipo logístico de      ':'Equipo logístico de      ',
        '91 personas en ':'91 personas en ',
        'Colombia, Panamá ':'Colombia, Panamá ',
        'y Costa Rica ':'y Costa Rica ',
        'Portafolio':'Portafolio',
        'Somos un grupo empresarial con una clara ': 'Somos un grupo empresarial con una clara ',
        'inspiración profesional y humana; ofrecemos un ':'inspiración profesional y humana; ofrecemos un ',
        'portafolio de soluciones en salud de la más alta ':'portafolio de soluciones en salud de la más alta ',
        'calidad para el cuidado de los pacientes. ':'calidad para el cuidado de los pacientes. ',
        'y genética':'y genética',
        'Instrumental': 'Instrumental',
        'y esterilización':'y esterilización',
        'Logística': 'Logística',
        'hospitalaria':'hospitalaria',
        'Mapa':'Mapa',
        'Customer service line 018000 180066 -' :'Customer service line 018000 180066 -',
        'Reporte de eventos adversos o PQR: ':'Reporte de eventos adversos o PQR: ',
        'Línea ética: linea.etica@grupoamarey.com': 'Línea ética: linea.etica@grupoamarey.com',
        'Soluciones en salud​':'Soluciones en salud​',
        'Ética y compliance​' :'Ética y compliance​',
        'Trabaje con nosotros': 'Trabaje con nosotros',
        'Nuestra Historia': 'Nuestra Historia',
        'Filosofía': 'Filosofía',
        'Reconocimientos': 'Reconocimientos',
        'Nuestros aliados': 'Nuestros aliados',
        'Mantenimiento Biomédico': 'Mantenimiento Biomédico',
        'Eventos':'Eventos',
        'Noticias':'Noticias',
        'NUESTRA HISTORIA ': 'NUESTRA HISTORIA ',
        'Grupo Amarey es una compañía con más de 40 ':'Grupo Amarey es una compañía con más de 40 ',
        'años de trayectoria, comprometida con ':'años de trayectoria, comprometida con ',
        'acercar el conocimiento, la ciencia y la ':'acercar el conocimiento, la ciencia y la ',
        'tecnología al sector de la salud en Colombia, ':'tecnología al sector de la salud en Colombia, ',
        'Panamá y Costa Rica.  ':'Panamá y Costa Rica.  ',
        'Con 6 sedes en Colombia y operación ':'Con 6 sedes en Colombia y operación ',
        'internacional en Panamá y Costa Rica, el grupo ':'internacional en Panamá y Costa Rica, el grupo ',
        'empresarial cuenta con un equipo de ':'empresarial cuenta con un equipo de ',
        'colaboradores que trabajan bajo una única ':'colaboradores que trabajan bajo una única ',
        'premisa, ':'premisa, ',
        '“un compromiso inquebrantable ': '“un compromiso inquebrantable ',
        'con la salud y la vida”.  ':'con la salud y la vida”. ',
        'Nace Amarey Nova Medical':'Nace Amarey Nova Medical',
        'al obtener la distribución':'al obtener la distribución',
        'de la inmunoglobulina':'de la inmunoglobulina',
        'humana IgG enriquecida,':'humana IgG enriquecida,',
        'que le dio vida al':'que le dio vida al',
        'pentámero que ha sido el ':'pentámero que ha sido el',
        'símbolo de la compañía. ':'símbolo de la compañía,',
        'Se crea la línea respiratoria ':'Se crea la línea respiratoria ',
        'con el lanzamiento de un ':'con el lanzamiento de un ',
        'surfactante pulmonar para ':'surfactante pulmonar para ',
        'el tratamiento del síndrome ':'el tratamiento del síndrome ',
        'de distrés respiratorio en ':'de distrés respiratorio en ',
        'neonatos':'neonatos',
        'internacional UCITECH ':'internacional UCITECH ',
        "implantes ortopédicos de ":"implantes ortopédicos de ",
        "Stryker, equipos médicos, ":"Stryker, equipos médicos, ",
        "pruebas  diagnósticas ":"pruebas  diagnósticas ",
        "oncológicas y cuidado ":"oncológicas y cuidado ",
        "avanzado de heridas ":"avanzado de heridas ",
        "Se crea la línea de apoyo ":"Se crea la línea de apoyo ",
        "en gestión de tecnología ":"en gestión de tecnología ",
        "biomédica en el modelo ":"biomédica en el modelo ",
        "inhouse y servicio de ":"inhouse y servicio de ",
        "mantenimiento biomédico.":"mantenimiento biomédico.",
        "robótico da Vinci en ":"robótico da Vinci en ",
        "Colombia para cirugía ": "Colombia para cirugía ",
        "ínimamente invasiva.":"ínimamente invasiva.",
        "Se inauguran cinco ":"Se inauguran cinco ",
        "sedes propias en ":"sedes propias en ",
        "Bogotá, Cali, Medellín, ":"Bogotá, Cali, Medellín, ",
        "Barranquilla y Pereira.":"Barranquilla y Pereira.",
        "Obtiene el Premio ":"Obtiene el Premio ",
        "Colombiano a la ":"Colombiano a la ",
        "Calidad de la Gestión.":"Calidad de la Gestión.",
        "Los Premios Portafolio ":"Los Premios Portafolio ",
        "otorgan la Mención de ":"otorgan la Mención de ",
        "Honor a Amarey Nova ":"Honor a Amarey Nova ",
        "Medical en la categoría ":"Medical en la categoría ",
        "de Servicio al cliente. ":"de Servicio al cliente. ",
        "positivamente con los más ": "positivamente con los más ",
        "altos estándares de calidad e ":"altos estándares de calidad e ",
        'El portafolio del Grupo ': 'El portafolio del Grupo ',
        "Amarey sigue creciendo   ":"Amarey sigue creciendo   ",
        "con la representación de  ":"con la representación de  ",
        "KLS Martin para ortopedia ":"KLS Martin para ortopedia ",
        "cráneo maxilofacial, ":"cráneo maxilofacial, ",
        "instrumental y esterilización.":"instrumental y esterilización.",
        "Grupo Amarey renueva ":"Grupo Amarey renueva ",
        "su identidad de marca, ":"su identidad de marca, ",
        "logrando que refleje los ":"logrando que refleje los ",
        "valores, la cultura y la ":"valores, la cultura y la ",
        "misi\xF3n de la compa\xF1\xEDa.":"misi\xF3n de la compa\xF1\xEDa.",
        "Distribución Logística – CEDI ":"Distribución Logística – CEDI ",
        "en la zona industrial de ":"en la zona industrial de ",
        "Montevideo en Bogotá, con ":"Montevideo en Bogotá, con ",
        "el objetivo de lograr mejores ":"el objetivo de lograr mejores ",
        "niveles de servicio logístico ":"niveles de servicio logístico ",
        "para los clientes.":"para los clientes.",
        "Somos un grupo empresarial orgullosamente ":"Somos un grupo empresarial orgullosamente ",
        "colombiano que representa productos y ":"colombiano que representa productos y ",
        "servicios de calidad guiados por ":"servicios de calidad guiados por ",
        "nuestra filosofía SER (Servicio, Educación, ":"nuestra filosofía SER (Servicio, Educación, ",
        "Relacionamiento). ":"Relacionamiento).",
        "Generamos valor a los pacientes, sus familias, ":"Generamos valor a los pacientes, sus familias, ",
        "instituciones de salud y accionistas con un ":"instituciones de salud y accionistas con un ",
        "talento humano excepcional, con sentido ético y ":"talento humano excepcional, con sentido ético y ",
        "altamente comprometido con la salud y la vida.":"altamente comprometido con la salud y la vida.",
        "En el 2025 el Grupo Amarey será el ":"En el 2025 el Grupo Amarey será el ",
        "aliado preferido de las instituciones de salud ":"aliado preferido de las instituciones de salud ",
        "y de los fabricantes de ":"y de los fabricantes de ",
        "productos especializados, en Colombia y los ":"productos especializados, en Colombia y los ",
        "países del área andina y Centroamérica.":"países del área andina y Centroamérica.",
        "Esto será posible por nuestra trayectoria, ":"Esto será posible por nuestra trayectoria, ",
        "reputación, por cumplir lo que prometemos y ":"reputación, por cumplir lo que prometemos y ",
        "por la capacidad para ofrecer soluciones ":"por la capacidad para ofrecer soluciones ",
        "integrales innovadoras.":"integrales innovadoras.",
        "Valoramos y respetamos ":"Valoramos y respetamos ",
        "el punto de vista, el saber ":"el punto de vista, el saber ",
        "y el quehacer de otros. ":"y el quehacer de otros. ",
        "Nos caracteriza el ":"Nos caracteriza el ",
        "relacionamiento basado ":"relacionamiento basado ",
        "en las buenas maneras.":"en las buenas maneras.",
        "Realizamos las cosas con":"Realizamos las cosas con ",
        "calidad, oportunidad y ":"calidad, oportunidad y ",
        "actitud desde el principio, ":"actitud desde el principio, ",
        "cumpliendo con la promesa ":"cumpliendo con la promesa ",
        "de valor a nuestros clientes ":"de valor a nuestros clientes ",
        "y propendiendo con el ":"y propendiendo con el ",
        "mejoramiento continuo.":"mejoramiento continuo.",
        "Actuamos con ":"Actuamos con ",
        "conocimiento profundo ":"conocimiento profundo ",
        "sobre los bienes y ":"sobre los bienes y ",
        "servicios que le brindamos ":"servicios que le brindamos ",
        "a la comunidad, ":"a la comunidad, ",
        "identificándonos con la ":"identificándonos con la ",
        "filosofía corporativa.":"filosofía corporativa.",
        "Nuestras actuaciones ":"Nuestras actuaciones ",
        "siempre se realizan a la luz ":"siempre se realizan a la luz ",
        "pública y están regidas ":"pública y están regidas ",
        "siempre por la buena ":"siempre por la buena ",
        "voluntad, la buena fe y la ":"voluntad, la buena fe y la ",
        "orientación a los resultados ":"orientación a los resultados ",
        "éticos y morales. ":"éticos y morales.",
        "Sentido de":"Sentido de",
        "responsabilidad":"responsabilidad",
        "con otros ":"con otros",
        "Somos sensibles a las ":"Somos sensibles a las ",
        "necesidades de los demás ":"necesidades de los demás ",
        "comprometiéndonos con el ":"comprometiéndonos con el ",
        "mejoramiento de su calidad ":"mejoramiento de su calidad ",
        "El Grupo Amarey trabaja bajo una única ":"El Grupo Amarey trabaja bajo una única ",
        "premisa: la filosofía SER (Servicio, Educación ":"premisa: la filosofía SER (Servicio, Educación ",
        "y Relacionamiento), pilares que marcan la ":"y Relacionamiento), pilares que marcan la ",
        "Una compañía con sentido humano, que ":"Una compañía con sentido humano, que ",
        "ofrece un servicio que trasciende hacia brindar ":"ofrece un servicio que trasciende hacia brindar ",
        "más oportunidades para la vida. ":"más oportunidades para la vida. ",
        "Representa el compromiso del Grupo Amarey ":"Representa el compromiso del Grupo Amarey ",
        "con la generación de conocimiento para el ":"con la generación de conocimiento para el ",
        "personal de la salud, contribuyendo a la ":"personal de la salud, contribuyendo a la ",
        "construcción de un país que ofrezca mayores ":"construcción de un país que ofrezca mayores ",
        "beneficios para la calidad de vida de los ":"beneficios para la calidad de vida de los ",
        "pacientes. ":"pacientes. ",
        "Ha permitido construir la base de confianza y ":"Ha permitido construir la base de confianza y ",
        "reputación del Grupo Amarey frente a sus ":"reputación del Grupo Amarey frente a sus ",
        "públicos de interés. Hoy la compañía es ":"públicos de interés. Hoy la compañía es ",
        "reconocida por su integridad, compromiso, ":"reconocida por su integridad, compromiso, ",
        "excelencia, humanidad e innovación. ":"excelencia, humanidad e innovación. ",
        'Certificado Novatécnica Sistema ':'Certificado Novatécnica Sistema ',
        'de gestión de calidad en ':'de gestión de calidad en ',
        'dispositivos médicos ISO 13485':'dispositivos médicos ISO 13485',
        'Certificación OEA (Operador ':'Certificación OEA (Operador ',
        'Económico Autorizado) de la ':'Económico Autorizado) de la ',
        'DIAN, obtenida en 2001, ':'DIAN, obtenida en 2001, ',
        'demostrando la seguridad en ':'demostrando la seguridad en ',
        'la cadena de suministro ':'la cadena de suministro ',
        ' es una entidad sin ánimo ':' es una entidad sin ánimo ',
        'de lucro con ':'de lucro con ',
        '18 años de trayectoria ':'18 años de trayectoria',
        ', que hace ':', que hace ',
        'parte del ':'parte del ',
        'Grupo Amarey y que fue creada con el ':'Grupo Amarey y que fue creada con el ',
        'propósito de promover el desarrollo humano y ':'propósito de promover el desarrollo humano y ',
        'profesional de jóvenes colombianos.':'profesional de jóvenes colombianos.',
        'Apoya a jóvenes con potencial académico y ':'Apoya a jóvenes con potencial académico y ',
        'dificultades económicas para acceder a ':'dificultades económicas para acceder a ',
        'educación superior de calidad, contribuyendo a ':'educación superior de calidad, contribuyendo a ',
        'su formación como líderes y profesionales ':'su formación como líderes y profesionales ',
        'competitivos comprometidos con el desarrollo ':'competitivos comprometidos con el desarrollo ',
        'social del país. Lo que hace la ':'social del país. Lo que hace la ',
        'Fundación ':'nacion Fundación ',
        'OCMAES':'OCMAES',
        ' es simple pero profundo: ':'es simple pero profundo: ',
        ' Por eso otorga ': 'Por eso otorga ',
        ' becas ':' becas ',
        ' universitarias a ': ' universitarias a ',
        ' estudiantes con ':' estudiantes con ',
        ' un alto potencial ':' un alto potencial ',
        ' académico que ':' académico que ',
        ' se encuentran en ':' se encuentran en ',
        ' circunstancias ':' circunstancias ',
        ' económicas y ':' económicas y ',
        ' sociales difíciles':'sociales difíciles',
        '+ de 4.600 beneficiarios': '+ de 4.600 beneficiarios',
        'Conoce más de La Fundación OCMAES ':'Conoce más de La Fundación OCMAES ',
        'NUESTROS ': 'NUESTROS',
        'ALIADOS ':'ALIADOS',
        'Somos la compañía de servicio técnico':'Somos la compañía de servicio técnico',
        'biomédico más grande del país y la primera':'biomédico más grande del país y la primera',
        'empresa a nivel nacional en certificarse con':'empresa a nivel nacional en certificarse con ',
        ' la ':'la',
        ' norma ISO 13485 de ICONTEC':'norma ISO 13485 de ICONTEC',
        'para el':'para el',
        ' servicio técnico de dispositivos médicos.':'servicio técnico de dispositivos médicos.',
        'Mantenimiento biomédico':'Mantenimiento biomédico',
        'Ofrecemos soluciones de mantenimiento':'Ofrecemos soluciones de mantenimiento',
        ' biomédico ':' biomédico ',
        'para todos los actores del sector':'para todos los actores del sector',
        'salud. Nuestro apoyo es ejecutado con':'salud. Nuestro apoyo es ejecutado con',
        'entrenamiento, experiencia e innovación en los':'entrenamiento, experiencia e innovación en los',
        'procesos humanos y técnicos.':'procesos humanos y técnicos.',
        'Nuestro modelo de':'Nuestro modelo de',
        'mantenimiento inhouse y':'mantenimiento inhouse y',
        'mantenimiento posventa':'mantenimiento posventa',
        'nos permiten construir':'nos permiten construir',
        'soluciones personalizadas para cada cliente,':'soluciones personalizadas para cada cliente,',
        'asegurando los más altos estándares de calidad':'asegurando los más altos estándares de calidad',
        'y un plan de mantenimiento eficiente para toda':'y un plan de mantenimiento eficiente para toda',
        '  la ':'la ',
        ' tecnología biomédica involucrada. ':' tecnología biomédica involucrada. ',
        'Nuestras manos expertas pueden entender y ':'Nuestras manos expertas pueden entender y ',
        'cuidar la tecnología para la tranquilidad de ':'cuidar la tecnología para la tranquilidad de ',
        'nuestros clientes y proveedores, todo para ':'nuestros clientes y proveedores, todo para ',
        'crear soluciones para el cuidado de la salud y ':'crear soluciones para el cuidado de la salud y ',
        'los pacientes. ':'los pacientes. ',
        '12.000 equipos ': '12.000 equipos ',
        'de marcas representadas':'de marcas representadas',
        'dedicados al cuidado de la tecnología ':'dedicados al cuidado de la tecnología ',
        'biomédica y comprometidos con la seguridad ':'biomédica y comprometidos con la seguridad ',
        'de los pacientes ': 'de los pacientes ',
        'Soluciones ':'Soluciones Y',
        'en salud':'productos distribuidos',
        'Genómica y':'Genómica y',
        'genética':'genética',
        'Instrumental y':'Instrumental y',
        'esterilización': 'esterilización',
        'Soluciones en salud': 'Soluciones y productos distribuidos',
        'MEDICAMENTOS  ':'MEDICAMENTOS',
        'Con medicamentos de la más alta tecnología ':'Con medicamentos de la más alta tecnología ',
        'para el cuidado de la salud de los pacientes, ya ':'para el cuidado de la salud de los pacientes, ya ',
        'sea adultos, niños o neonatos, ofrecemos mayor ':'sea adultos, niños o neonatos, ofrecemos mayor ',
        'eficacia y tolerabilidad al paciente en ':'eficacia y tolerabilidad al paciente en ',
        'productos.':'productos.',
        'UCI Neonatología': 'UCI Neonatología',
        'CIRUGÍA ROBÓTICA':'CIRUGÍA ROBÓTICA',
        'Una extensión natural de los ojos, a través de ':'Una extensión natural de los ojos, a través de ',
        'una visión en 3D y magnificación hasta 10 veces. ':'una visión en 3D y magnificación hasta 10 veces. ',
        'Movimientos intuitivos con mayor rango de ':'Movimientos intuitivos con mayor rango de ',
        'movimiento que exceden las capacidades de la ':'movimiento que exceden las capacidades de la ',
        'mano humana. ':'mano humana. ',
        'Somos el comercializador del sistema quirúrgico ': 'Somos el comercializador del sistema quirúrgico ',
        'da Vinci, con una experiencia de más de 3.000 ':'da Vinci, con una experiencia de más de 3.000 ',
        'cirugías en nuestro país, una sofisticada ':'cirugías en nuestro país, una sofisticada ',
        'plataforma robótica que permite potenciar las ':'plataforma robótica que permite potenciar las ',
        'capacidades del cirujano y ofrecer una opción ':'capacidades del cirujano y ofrecer una opción ',
        'mínimamente invasiva en procedimientos de ':'mínimamente invasiva en procedimientos de ',
        'cirugía compleja.  ':'cirugía compleja.  ',
        'El sistema da Vinci da tratamiento a cuatro de ':'El sistema da Vinci da tratamiento a cuatro de ',
        'los cinco tipos de cáncer más frecuente en ':'los cinco tipos de cáncer más frecuente en ',
        'Colombia: cáncer de próstata, cáncer de ':'Colombia: cáncer de próstata, cáncer de ',
        'estómago, cáncer de pulmón, cáncer colorrectal ':'estómago, cáncer de pulmón, cáncer colorrectal ',
        'y muchas más patologías.':'y muchas más patologías.',
        'Recuperación con menos dolor y riesgo de':'Recuperación con menos dolor y riesgo de',
        'infección. ':'infección. ',
        'Rápido retorno a las actividades diarias.':'Rápido retorno a las actividades diarias.',
        'Movimientos intuitivos. Rotación de 540°,':'Movimientos intuitivos. Rotación de 540°,',
        'articulación de 180°.':'articulación de 180°.',
        'Mayor precisión y seguridad.':'Mayor precisión y seguridad.',
        'Atracción de nuevos pacientes y cirujanos. ':'Atracción de nuevos pacientes y cirujanos. ',
        'Con medicamentos de la más alta tecnología': 'Con medicamentos de la más alta tecnología',
        'EQUIPOS MÉDICOS':'EQUIPOS MÉDICOS',
        'Ofrecemos a los especialistas equipos de la más ':'Ofrecemos a los especialistas equipos de la más ',
        'alta tecnología para el servicio y asistencia de ':'alta tecnología para el servicio y asistencia de ',
        'los pacientes.':'los pacientes.',
        ' Soluciones en salud':'Soluciones en salud',
        'Monitores de signos vitales': 'Monitores de signos vitales ',
        'Centrales de monitoreo':'Centrales de monitoreo ',
        'Electrocardiografía':'Electrocardiografía ',
        'Ventilación ':'Ventilación  ',
        'Equipos para neurología:':'Equipos para neurología: ',
        'electroencefalografía, polisomnografía y': 'electroencefalografía, polisomnografía y ',
        'electromiografía.':'electromiografía. ',
        'Camas y camillas hospitalarias':'Camas y camillas hospitalarias ',
        'Mesas de cirugía':'Mesas de cirugía ',
        'Incubadora neonatal y lámparas de':'Incubadora neonatal y lámparas de ',
        'fototerapia':'fototerapia ',
        'Desfibrilador Externo Automático (DEA)':'Desfibrilador Externo Automático (DEA) ',
        'Lámparas para quirófano, procedimientos':'Lámparas para quirófano, procedimientos ',
        'y exploración ':'y exploración',
        'Unidades de suministro medicinales':'Unidades de suministro medicinales',
        'Con una variada gama de soluciones para áreas ':'Con una variada gama de soluciones para áreas ',
        'especializadas, UCIs, quirófanos, urgencias y ':'especializadas, UCIs, quirófanos, urgencias y ',
        'hospitalización. ':'hospitalización. ',
        'En cumplimiento de la Ley 1672 del 19 de julio de ':'En cumplimiento de la Ley 1672 del 19 de julio de ',
        '2013, Decreto 284 de 2018 y Resolución 851 del ':'2013, Decreto 284 de 2018 y Resolución 851 del ',
        '2022. Amarey Nova Medical S.A, Ucipharma S.A. ':'2022. Amarey Nova Medical S.A, Ucipharma S.A. ',
        'y Novatécnica S.A.S., ponen a su disposición ':'y Novatécnica S.A.S., ponen a su disposición ',
        'nuestro proceso de disposición y gestión de los ':'nuestro proceso de disposición y gestión de los ',
        'residuos de aparatos eléctricos y electrónicos ':'residuos de aparatos eléctricos y electrónicos ',
        '(RAEE) relacionados específicamente con los ':'(RAEE) relacionados específicamente con los ',
        'equipos biomédicos eléctricos o electrónicos ': 'equipos biomédicos eléctricos o electrónicos ',
        '(con excepción de todos los productos ':'(con excepción de todos los productos ',
        'implantados e infectados) que distribuye ':'implantados e infectados) que distribuye ',
        'nuestras compañías. Para este fin, solicitamos ':'nuestras compañías. Para este fin, solicitamos ',
        'comunicarse directamente con nuestro ':'comunicarse directamente con nuestro ',
        'representante de ventas asignado a su ':'representante de ventas asignado a su ',
        'institución con el objetivo de programar su ':'institución con el objetivo de programar su ',
        'recolección. Para la disposición final de los RAEE ':'recolección. Para la disposición final de los RAEE ',
        'realizamos la gestión a través de una empresa ':'realizamos la gestión a través de una empresa ',
        'autorizada por la Autoridad Ambiental.  ':'autorizada por la Autoridad Ambiental.  ' ,
        'Somos líderes con tecnología que aporta a los ':'Somos líderes con tecnología que aporta a los ',
        'profesionales de la salud y sus pacientes las ':'profesionales de la salud y sus pacientes las ',
        'herramientas en cirugías que permiten alcanzar ':'herramientas en cirugías que permiten alcanzar ',
        'una calidad de vida más activa y satisfactoria ':'una calidad de vida más activa y satisfactoria ',
        'mediante las líneas de:  ':'mediante las líneas de:  ',
        'Trauma':'Trauma',
        'Reemplazo articular':'Reemplazo articular ',
        'Artroscopia':'Artroscopia ',
        'Cráneo y maxilofacial':'Cráneo y maxilofacial ',
        'ortopedia ofrece disponibilidad 24/7 los 365':'ortopedia ofrece disponibilidad 24/7 los 365',
        'días del año, con un servicio que ofrece ':'días del año, con un servicio que ofrece ',
        'acompañamiento en el quirófano y servicio ':'acompañamiento en el quirófano y servicio ',
        'posventa. ':'posventa. ',
        'Contamos con una red logística de ortopedia ':'Contamos con una red logística de ortopedia ',
        'para atención de cirugías en ciudades ':'para atención de cirugías en ciudades ',
        'principales e intermedias de Colombia y':'principales e intermedias de Colombia y',
        'Costa Rica. ':'Costa Rica. ',
        'LOGÍSTICA ':'LOGÍSTICA',
        'HOSPITALARIA ':'HOSPITALARIA',
        'Nuestro portafolio de soluciones tecnológicas':'Nuestro portafolio de soluciones tecnológicas',
        'está enfocado en la automatización de ':'está enfocado en la automatización de ',
        'procesos intrahospitalarios para el ':'procesos intrahospitalarios para el ',
        'almacenamiento y la dispensación de ':'almacenamiento y la dispensación de ',
        'medicamentos y material médico-quirúrgico, ': 'medicamentos y material médico-quirúrgico, ',
        'aumentando la seguridad del paciente y la ':'aumentando la seguridad del paciente y la ',
        'eficiencia en los procesos.':'eficiencia en los procesos. ',
        'Alineamos estos procesos a los lineamientos de ':'Alineamos estos procesos a los lineamientos de ',
        'LA ':'la ',
        'Joint Commission International,':'Joint Commission International,',
        'buscando las ':'buscando las ',
        'mejores prácticas para asegurar la trazabilidad ':'mejores prácticas para asegurar la trazabilidad ',
        'de medicamentos y generando en tiempo real ':'de medicamentos y generando en tiempo real ',
        'los movimientos de inventario.':'los movimientos de inventario.',
        'Somos un aliado estratégico para los ':'Somos un aliado estratégico para los ',
        'profesionales que realizan procedimientos ':'profesionales que realizan procedimientos ',
        'quirúrgicos con equipos y dispositivos médicos ':'quirúrgicos con equipos y dispositivos médicos ',
        'que mejoran los resultados del paciente en ':'que mejoran los resultados del paciente en ',
        'una intervención.':'una intervención.',
        'Equipos y dispositivos médicos para':'Equipos y dispositivos médicos para ',
        'cirugía laparoscópica':'cirugía laparoscópica ',
        'Dispositivos para reparo de hernias':'Dispositivos para reparo de hernias ',
        'inguinales y ventrales':'inguinales y ventrales ',
        'Ropa quirúrgica y barreras de protección':'Ropa quirúrgica y barreras de protección ',
        'especializadas':'especializadas',
        'Hemostáticos y sellantes para cirugía':'Hemostáticos y sellantes para cirugía ',
        'mínimamente invasiva ':'mínimamente invasiva',
        'Todos productos de nuestro portafolio están ':'Todos productos de nuestro portafolio están ',
        'aprobados por la FDA y cuentan con altos ':'aprobados por la FDA y cuentan con altos ',
        'estándares de calidad. ':'estándares de calidad. ',
        'Contamos con un centro de lavado con la última ':'Contamos con un centro de lavado con la última ',
        'tecnología y estándares, para permitirnos ':'tecnología y estándares, para permitirnos ',
        'ofrecer un producto con la mejor calidad y ':'ofrecer un producto con la mejor calidad y ',
        'garantizar las condiciones sanitarias de kits de ':'garantizar las condiciones sanitarias de kits de ',
        'cirugía utilizados. Adicionalmente, realizamos ':'cirugía utilizados. Adicionalmente, realizamos ',
        'acompañamiento quirúrgico en cirugía. ':'acompañamiento quirúrgico en cirugía. ',
        'Genómica  ':'Genómica',
        'La gen\xF3mica analiza la forma en que se ':'La gen\xF3mica analiza la forma en que se ',
        'comporta un conjunto de genes. ':'comporta un conjunto de genes. ',
        'La gen\xE9tica es el estudio de rasgos y genes ':'La gen\xE9tica es el estudio de rasgos y genes ',
        'heredados que pueden predisponer a una ':'heredados que pueden predisponer a una ',
        'persona a una condici\xF3n de salud particular.':'persona a una condici\xF3n de salud particular.',
        'Las pruebas genómicas (también conocidas ':'Las pruebas genómicas (también conocidas ',
        'como pruebas multigénicas) analizan una ':'como pruebas multigénicas) analizan una ',
        'muestra del tumor del cáncer para analizar el ':'muestra del tumor del cáncer para analizar el ',
        'nivel de actividad de determinados genes, el ':'nivel de actividad de determinados genes, el ',
        'cual afecta el comportamiento del cáncer, ':'cual afecta el comportamiento del cáncer, ',
        'incluyendo su probabilidad de crecimiento y ':'incluyendo su probabilidad de crecimiento y ',
        'propagación. Una de estas pruebas genómicas ':'propagación. Una de estas pruebas genómicas ',
        'en particular proporciona, además, una ':'en particular proporciona, además, una ',
        'estimación del beneficio de la quimioterapia. Se ':'estimación del beneficio de la quimioterapia. Se ',
        'usan para ayudar en la toma de decisiones ':'usan para ayudar en la toma de decisiones ',
        'sobre tratamiento después de la cirugía. ':'sobre tratamiento después de la cirugía. ',
        'En el caso del cáncer de mama puede ayudar a':'En el caso del cáncer de mama puede ayudar a ',
        'determinar la agresividad de un tumor o la':'determinar la agresividad de un tumor o la ',
        'probabilidad que un determinado tratamiento':'probabilidad que un determinado tratamiento ',
        'mejore el resultado, permitiendo escoger el plan':'mejore el resultado, permitiendo escoger el plan ',
        'de cuidado adecuado para el paciente.':'de cuidado adecuado para el paciente.',
        'Genética':'Genética',
        'Esto significa que si usted tiene un historial ':'Esto significa que si usted tiene un historial ',
        'familiar marcado de cáncer de mama, es ':'familiar marcado de cáncer de mama, es ',
        'probable que haya heredado un gen anormal ':'probable que haya heredado un gen anormal ',
        'vinculado a un mayor riesgo de sufrir cáncer ':'vinculado a un mayor riesgo de sufrir cáncer ',
        'de mama (como las mutaciones de los genes ':'de mama (como las mutaciones de los genes ',
        'BRCA 1 o 2).':'BRCA 1 o 2). ',
        '¿Por qué realizar pruebas genómicas':'¿Por qué realizar pruebas genómicas',
        ' y genéticas? ': ' y genéticas? ',
        'Son las pruebas base de medicina de':'Son las pruebas base de medicina de ',
        'precisión o medicina personalizada, ya que':'precisión o medicina personalizada, ya que ',
        'cada individuo es diferente.':'cada individuo es diferente. ',
        'Guían al profesional en la elección del':'Guían al profesional en la elección del ',
        'tratamiento más adecuado para el':'tratamiento más adecuado para el ',
        'paciente. ':'paciente.',
        'Permite calcular el riesgo de recurrencia.':'Permite calcular el riesgo de recurrencia. ',
        'Muestra un beneficio real para el paciente':'Muestra un beneficio real para el paciente ',
        'y su familia. ':'y su familia.',
        'Se pueden analizar más de 3.800':'Se pueden analizar más de 3.800 ',
        'enfermedades. ':'enfermedades.',
        'Determinan calidad de vida del paciente. ':'Determinan calidad de vida del paciente.',
        'Con un completo portafolio de instrumentos ':'Con un completo portafolio de instrumentos ',
        'para las diferentes especialidades quirúrgicas, y ':'para las diferentes especialidades quirúrgicas, y ',
        'la interacción entre los productos y los procesos ':'la interacción entre los productos y los procesos ',
        'de lavado y esterilización.':'de lavado y esterilización.',
        'nuestro equipo profesional está presto a brindar ':'nuestro equipo profesional está presto a brindar ',
        'información, asesoría, acompañamiento, ':'información, asesoría, acompañamiento, ',
        'sugerencias y soluciones a sus planes y ':'sugerencias y soluciones a sus planes y ',
        'proyectos con:':'proyectos con:',
        'CUIDADO AVANZADO':'CUIDADO AVANZADO',
        'DE HERIDAS ':'DE HERIDAS',
        'Ofrecemos una línea de apósitos avanzados y ':'Ofrecemos una línea de apósitos avanzados y ',
        'terapia de presión negativa para el tratamiento ':'terapia de presión negativa para el tratamiento ',
        'integral de heridas agudas, crónicas y ':'integral de heridas agudas, crónicas y ',
        'complejas, como las ocasionadas por pie ':'complejas, como las ocasionadas por pie ',
        'diabético, lesiones por presión y lesiones de ':'diabético, lesiones por presión y lesiones de ',
        'origen vascular.':'origen vascular.',
        'Ayudando al paciente a mejorar su calidad de ':'Ayudando al paciente a mejorar su calidad de ',
        'vida, mediante:':'vida, mediante:',
        'Estimulación de la formación de':'Estimulación de la formación de',
        'nuevo tejido':'nuevo tejido',
        'Nuestro compromiso es asesorar a los ':'Nuestro compromiso es asesorar a los ',
        'profesionales de la salud en el uso, manejo y ':'profesionales de la salud en el uso, manejo y ',
        'aprovechamiento correcto de dichas tecnologías. ':'aprovechamiento correcto de dichas tecnologías. ',
        'El ACV - Accidente Cerebro Vascular es algo que ':'El ACV - Accidente Cerebro Vascular es algo que ',
        'le puede ocurrir a 1 de cada 4 personas y menos ':'le puede ocurrir a 1 de cada 4 personas y menos ',
        'del 5% de las personas que lo sufren son ':'del 5% de las personas que lo sufren son ',
        'atendidas a tiempo.':'atendidas a tiempo.',
        'La ciencia viene evolucionando en forma rápida ':'La ciencia viene evolucionando en forma rápida ',
        'para identificar y tratar esta patología conocida ':'para identificar y tratar esta patología conocida ',
        'como ictus, dividido entre isquémico (trombo) y ':'como ictus, dividido entre isquémico (trombo) y ',
        'hemorrágico (aneurisma), donde el ':'hemorrágico (aneurisma), donde el ',
        'neurointervencionismo como disciplina joven se ':'neurointervencionismo como disciplina joven se ',
        'va consolidando como alternativa para ':'va consolidando como alternativa para ',
        'determinados casos. ':'determinados casos. ',
        'Nuestro portafolio es reconocido por su calidad ':'Nuestro portafolio es reconocido por su calidad ',
        'y renombre, con fortalezas en opciones de ':'y renombre, con fortalezas en opciones de ',
        'ACCESO, una gran variedad de Coils y una ':'ACCESO, una gran variedad de Coils y una ',
        'tecnología única para trombectomía, incluyendo ':'tecnología única para trombectomía, incluyendo ',
        'una estructura de logística inversa que nos ':'una estructura de logística inversa que nos ',
        'permite asegurar un servicio de alta calidad ':'permite asegurar un servicio de alta calidad ',
        'para los requerimientos de tiempo y ':'para los requerimientos de tiempo y ',
        'disponibilidad en las ventanas terapéuticas. ':'disponibilidad en las ventanas terapéuticas. ',
        ' Neurointervencionismo ':'Neurointervencionismo',
        'GRUPO AMAREY EN SUS 40 ':'GRUPO AMAREY EN SUS 40 ',
        'AÑOS ESTRENA SEDES':'AÑOS ESTRENA SEDES',
        'Gracias a su actual y exitoso crecimiento, ':'Gracias a su actual y exitoso crecimiento, ',
        'unificó sus bodegas en un gran centro de ':'unificó sus bodegas en un gran centro de ',
        'distribución logística, CEDI y trasladó su sede ':'distribución logística, CEDI y trasladó su sede ',
        'administrativa a una exclusiva torre empresarial ':'administrativa a una exclusiva torre empresarial ',

        'El Grupo Amarey, una institución emblemática en ':'El Grupo Amarey, una institución emblemática en ',
        'el ámbito de la salud en Colombia, está de ':'el ámbito de la salud en Colombia, está de ',
        'celebración al cumplir 40 años de servicio ':'celebración al cumplir 40 años de servicio ',
        'dedicado a la comunidad médica del país. En ':'dedicado a la comunidad médica del país. En ',
        'este hito importante, nos complace anunciar la ':'este hito importante, nos complace anunciar la ',
        'apertura de sus nuevas oficinas, ubicadas en ':'apertura de sus nuevas oficinas, ubicadas en ',
        'uno de los lugares más exclusivos de Bogotá. ':'uno de los lugares más exclusivos de Bogotá. ',
        'Como resultado de sus continuos esfuerzos y ':'Como resultado de sus continuos esfuerzos y ',
        'excelentes resultados, el Grupo Amarey marca el ':'excelentes resultados, el Grupo Amarey marca el ',
        'inicio de una nueva etapa en su desarrollo ':'inicio de una nueva etapa en su desarrollo ',
        'organizacional y su visión de expansión hacia el ':'organizacional y su visión de expansión hacia el ',
        'futuro. Por ello, la sección administrativa de la ':'futuro. Por ello, la sección administrativa de la ',
        'compañía estrena una nueva sede, que abarca ':'compañía estrena una nueva sede, que abarca ',
        'más de 2.000 metros cuadrados en una torre ':'más de 2.000 metros cuadrados en una torre ',
        'empresarial ubicada en la calle 100 con carrera ':'empresarial ubicada en la calle 100 con carrera ',
        'séptima en Bogotá. ':'séptima en Bogotá. ',
        'Las nuevas instalaciones están diseñadas para ':'Las nuevas instalaciones están diseñadas para ',
        'proporcionar espacios modernos y funcionales, ':'proporcionar espacios modernos y funcionales, ',
        'incluyendo oficinas, salas de reuniones, ':'incluyendo oficinas, salas de reuniones, ',
        'showroom, áreas sociales y espacios de ':'showroom, áreas sociales y espacios de ',
        'coworking. Estas instalaciones refuerzan la ':'coworking. Estas instalaciones refuerzan la ',
        'nueva imagen del Grupo Amarey y su ':'nueva imagen del Grupo Amarey y su ',
        'compromiso con la innovación y la excelencia. ':'compromiso con la innovación y la excelencia. ',
        'LIDERES EN DISTRIBUCIÓN':'LIDERES EN DISTRIBUCIÓN ',
        'Además de este traslado a sus nuevas oficinas ':'Además de este traslado a sus nuevas oficinas ',
        'administrativas, la compañía unificó sus ':'administrativas, la compañía unificó sus ',
        'bodegas para brindar un servicio más eficiente y ':'bodegas para brindar un servicio más eficiente y ',
        'oportuno a sus clientes, con la apertura del ':'oportuno a sus clientes, con la apertura del ',
        'Centro de Distribución Logística (CEDI), ubicado ':'Centro de Distribución Logística (CEDI), ubicado ',
        'estratégicamente en la carrera 68 con calle 17, ':'estratégicamente en la carrera 68 con calle 17, ',
        'en la zona industrial de Montevideo en Bogotá. ':'en la zona industrial de Montevideo en Bogotá. ',
        'El CEDI está dotado con la más alta tecnología y ':'El CEDI está dotado con la más alta tecnología y ',
        'los más altos estándares operativos enfocados ':'los más altos estándares operativos enfocados ',
        'en optimizar los procesos de valor tanto para los ':'en optimizar los procesos de valor tanto para los ',
        'clientes como para los pacientes. Este centro ':'clientes como para los pacientes. Este centro ',
        'permite cumplir de manera más efectiva las ':'permite cumplir de manera más efectiva las ',
        'promesas de entrega y mejorar la eficiencia en ':'promesas de entrega y mejorar la eficiencia en ',
        'toda la cadena operativa. ':'toda la cadena operativa. ',
        'Con el lanzamiento del CEDI, se reafirma una vez ':'Con el lanzamiento del CEDI, se reafirma una vez ',
        'más el compromiso de liderar la logística en el ':'más el compromiso de liderar la logística en el ',
        'sector, manteniendo un servicio operativo las 24 ':'sector, manteniendo un servicio operativo las 24 ',
        'horas del día, los 7 días de la semana, los 365 ':'horas del día, los 7 días de la semana, los 365 ',
        'días del año. Esto permitirá seguir sirviendo a ':'días del año. Esto permitirá seguir sirviendo a ',
        'instituciones y pacientes de manera ':'instituciones y pacientes de manera ',
        'ininterrumpida, garantizando la prontitud y ':'ininterrumpida, garantizando la prontitud y ',
        'calidad en cada entrega. ':'calidad en cada entrega. ',
        'GRUPO AMAREY EN SUS 40 AÑOS ESTRENA SEDES':'GRUPO AMAREY EN SUS 40 AÑOS ESTRENA SEDES ',
        'GRUPO AMAREY EN SUS': 'GRUPO AMAREY EN SUS',
        '40 AÑOS ESTRENA SEDES ':'40 AÑOS ESTRENA SEDES ',
        'Otras noticias ':'Otras noticias ',
        'Grupo ': 'Grupo ',
        'amarey en ':'amarey en ',
        'sus 40 años ':'sus 40 años ',
        'estrena ':'estrena ',
        'sedes ':'sedes ',
        'estrena sedes ':'estrena sedes ',
        'GRUPO AMAREY EN SUS 40 AÑOS ':'GRUPO AMAREY EN SUS 40 AÑOS ',
        'ESTRENA SEDES':'ESTRENA SEDES',
        'Gracias a su actual y exitoso crecimiento, unificó sus bodegas en un gran ':'Gracias a su actual y exitoso crecimiento, unificó sus bodegas en un gran ',
        'centro de distribución logística, CEDI y trasladó su sede administrativa a una ':'centro de distribución logística, CEDI y trasladó su sede administrativa a una ',
        'exclusiva torre empresarial ':'exclusiva torre empresarial ',
        'El Grupo Amarey, una institución emblemática en el ámbito de la salud en Colombia, está de celebración al ':'El Grupo Amarey, una institución emblemática en el ámbito de la salud en Colombia, está de celebración al ',
        'cumplir 40 años de servicio dedicado a la comunidad médica del país. En este hito importante, nos complace ':'cumplir 40 años de servicio dedicado a la comunidad médica del país. En este hito importante, nos complace ',
        'anunciar la apertura de sus nuevas oficinas, ubicadas en uno de los lugares más exclusivos de Bogotá. ':'anunciar la apertura de sus nuevas oficinas, ubicadas en uno de los lugares más exclusivos de Bogotá. ',
        'Como resultado de sus continuos esfuerzos y excelentes resultados, el Grupo Amarey marca el inicio de una ':'Como resultado de sus continuos esfuerzos y excelentes resultados, el Grupo Amarey marca el inicio de una ',
        'nueva etapa en su desarrollo organizacional y su visión de expansión hacia el futuro. Por ello, la sección ':'nueva etapa en su desarrollo organizacional y su visión de expansión hacia el futuro. Por ello, la sección ',
        'administrativa de la compañía estrena una nueva sede, que abarca más de 2.000 metros cuadrados en una torre ':'administrativa de la compañía estrena una nueva sede, que abarca más de 2.000 metros cuadrados en una torre ',
        'empresarial ubicada en la calle 100 con carrera séptima en Bogotá. ':'empresarial ubicada en la calle 100 con carrera séptima en Bogotá. ',
        'Las nuevas instalaciones están diseñadas para proporcionar espacios modernos y funcionales, incluyendo ':'Las nuevas instalaciones están diseñadas para proporcionar espacios modernos y funcionales, incluyendo ',
        'oficinas, salas de reuniones, showroom, áreas sociales y espacios de coworking. Estas instalaciones refuerzan la ':'oficinas, salas de reuniones, showroom, áreas sociales y espacios de coworking. Estas instalaciones refuerzan la ',
        'nueva imagen del Grupo Amarey y su compromiso con la innovación y la excelencia. ':'nueva imagen del Grupo Amarey y su compromiso con la innovación y la excelencia. ',
        'Además de este traslado a sus nuevas oficinas administrativas, la compañía unificó sus bodegas para brindar un ':'Además de este traslado a sus nuevas oficinas administrativas, la compañía unificó sus bodegas para brindar un ',
        'servicio más eficiente y oportuno a sus clientes, con la apertura del Centro de Distribución Logística (CEDI), ':'servicio más eficiente y oportuno a sus clientes, con la apertura del Centro de Distribución Logística (CEDI), ',
        'ubicado estratégicamente en la carrera 68 con calle 17, en la zona industrial de Montevideo en Bogotá. ':'ubicado estratégicamente en la carrera 68 con calle 17, en la zona industrial de Montevideo en Bogotá. ',
        'El CEDI está dotado con la más alta tecnología y los más altos estándares operativos enfocados en optimizar los ':'El CEDI está dotado con la más alta tecnología y los más altos estándares operativos enfocados en optimizar los ',
        'procesos de valor tanto para los clientes como para los pacientes. Este centro permite cumplir de manera más ':'procesos de valor tanto para los clientes como para los pacientes. Este centro permite cumplir de manera más ',
        'efectiva las promesas de entrega y mejorar la eficiencia en toda la cadena operativa. ':'efectiva las promesas de entrega y mejorar la eficiencia en toda la cadena operativa. ',
        'Con el lanzamiento del CEDI, se reafirma una vez más el compromiso de liderar la logística en el sector, ':'Con el lanzamiento del CEDI, se reafirma una vez más el compromiso de liderar la logística en el sector, ',
        'manteniendo un servicio operativo las 24 horas del día, los 7 días de la semana, los 365 días del año. Esto ':'manteniendo un servicio operativo las 24 horas del día, los 7 días de la semana, los 365 días del año. Esto ',
        'permitirá seguir sirviendo a instituciones y pacientes de manera ininterrumpida, garantizando la prontitud y ':'permitirá seguir sirviendo a instituciones y pacientes de manera ininterrumpida, garantizando la prontitud y ',
        ' calidad en cada entrega.':'calidad en cada entrega. ',
        'Prográmese con Grupo Amarey ':'Prográmese con Grupo Amarey ',
        '02 de febrero de 2024': '02 de febrero de 2024',
        'Febrero 2024 ':'Febrero 2024',
        'Do ':'Do',
        'Lu ':'Lu',
        'Ma ':'Ma',
        'Mi ':'Mi',
        'Ju ':'Ju',
        'Vi ':'Vi',
        'Sa ':'Sa',
        '06 de marzo de 2024':'06 de marzo de 2024',
        'ÉTICA Y COMPLIANCE ':'ÉTICA Y COMPLIANCE',
        'En el Grupo Amarey estamos comprometidos en ':'En el Grupo Amarey estamos comprometidos en ',
        'mantener relaciones honestas, constructivas e ':'mantener relaciones honestas, constructivas e ',
        'idóneas con nuestros grupos de interés. Guiados ':'idóneas con nuestros grupos de interés. Guiados ',
        'por nuestros valores corporativos hemos creado ':'por nuestros valores corporativos hemos creado ',
        'el presente Código de ética, a través del cual ':'el presente Código de ética, a través del cual ',
        'establecemos las reglas de conducta ética que ':'establecemos las reglas de conducta ética que ',
        'deben ser seguidas y observadas en su ':'deben ser seguidas y observadas en su ',
        'comportamiento diario por todas las personas ':'comportamiento diario por todas las personas ',
        'vinculadas a la empresa, tanto los miembros de ':'vinculadas a la empresa, tanto los miembros de ',
        'la junta directiva, directores, empleados de ':'la junta directiva, directores, empleados de ',
        'todos los niveles, proveedores, asesores y ':'todos los niveles, proveedores, asesores y ',
        'contratistas, así como para los empleados ':'contratistas, así como para los empleados ',
        'indirectos. ':'indirectos.',
        'Ver Código de ética':'Ver Código de ética',
        'La línea ética es un canal diseñado para que ':'La línea ética es un canal diseñado para que ',
        'empleados, proveedores, aliados estratégicos y ':'empleados, proveedores, aliados estratégicos y ',
        'clientes puedan reportar de forma anónima y ':'clientes puedan reportar de forma anónima y ',
        'confidencial presuntas violaciones a nuestro ':'confidencial presuntas violaciones a nuestro ',
        'Código de ética, así como posibles actos ':'Código de ética, así como posibles actos ',
        'incorrectos, fraudes, corrupción, acoso y otros ':'incorrectos, fraudes, corrupción, acoso y otros ',
        'aspectos que afecten la imagen y buen nombre ':'aspectos que afecten la imagen y buen nombre ',
        'de la compañía.':'de la compañía.',
        'Las denuncias pueden realizarse a través del ':'Las denuncias pueden realizarse a través del ',
        'correo electrónico los 7 días de la semana, las ':'correo electrónico los 7 días de la semana, las ',
        '24 horas del día.':'24 horas del día.',
        '¿Cómo puedo comunicarme con la línea ética? ':'¿Cómo puedo comunicarme con la línea ética?',
        'A través del correo electrónico: ':'A través del correo electrónico: ',
        'linea.etica@grupoamarey.com':'linea.etica@grupoamarey.com',
        '¿Cuándo debo utilizar': '¿Cuándo debo utilizar',
        'la línea ética?':'la línea ética?',
        'Cuando identifique situaciones no éticas que ' :'Cuando identifique situaciones no éticas que ' ,
        'puedan afectar los intereses del Grupo ':'puedan afectar los intereses del Grupo ',
        'Amarey como: ':'Amarey como:',
        'Incumplimiento de ':'Incumplimiento de ',
        'leyes y regulaciones':'leyes y regulaciones',
        'La situación en virtud de la cual una ':'La situación en virtud de la cual una ',
        'persona, por su actividad o cargo, o ':'persona, por su actividad o cargo, o ',
        'por la información que posee, se ':'por la información que posee, se ',
        'enfrenta a distintas alternativas de ':'enfrenta a distintas alternativas de ',
        'conducta en relación con intereses ':'conducta en relación con intereses ',
        'incompatibles, siendo incorrecto ':'incompatibles, siendo incorrecto ',
        'privilegiar alguno de ellos, pues se ':'privilegiar alguno de ellos, pues se ',
        'vulneraría o debilitaría injustamente ':'vulneraría o debilitaría injustamente ',
        'la posición del otro.':'la posición del otro.',
        'Negocios de la compañía que no se ':'Negocios de la compañía que no se ',
        'realicen dentro del marco legal y/o que ':'realicen dentro del marco legal y/o que ',
        'sus colaboradores no obren dentro de ':'sus colaboradores no obren dentro de ',
        'este. Incumplimiento a lo dispuesto en ':'este. Incumplimiento a lo dispuesto en ',
        'el código de ética, reglamento interno ':'el código de ética, reglamento interno ',
        'de trabajo, políticas de contratación y ':'de trabajo, políticas de contratación y ',
        'pago a terceros, reglamentos, políticas ':'pago a terceros, reglamentos, políticas ',
        'y/o procedimientos específicos ':'y/o procedimientos específicos ',
        'establecidos por el Grupo Amarey.':'establecidos por el Grupo Amarey.',
        'Confidencialidad ': 'Confidencialidad',
        'Los empleados utilizarán la ':'Los empleados utilizarán la ',
        'información no pública que sea de su ':'información no pública que sea de su ',
        'conocimiento, para realizar las ':'conocimiento, para realizar las ',
        'actividades que desempeñen, de ':'actividades que desempeñen, de ',
        'manera confidencial y reservada, y ':'manera confidencial y reservada, y ',
        'se abstendrán en todo momento de ':'se abstendrán en todo momento de ',
        'obtener o recibir beneficios ':'obtener o recibir beneficios ',
        'personales o provocar perjuicios a la ':'personales o provocar perjuicios a la ',
        'empresa por su divulgación o ':'empresa por su divulgación o ',
        'utilización indebida.':'utilización indebida.',
        'Son operaciones que busca dar ':'Son operaciones que busca dar ',
        'apariencia de legalidad a recursos ':'apariencia de legalidad a recursos ',
        'provenientes de actividades ilícitas, ':'provenientes de actividades ilícitas, ',
        'a través de diversas transacciones ':'a través de diversas transacciones ',
        'ocultando así el origen y trayectoria ':'ocultando así el origen y trayectoria ',
        'de los recursos.':'de los recursos.',
        'Actividades ': 'Actividades ',
        'inapropiadas':'inapropiadas',
        'La relación entre los empleados del ':'La relación entre los empleados del ',
        'Grupo Amarey deberá estar siempre ':'Grupo Amarey deberá estar siempre ',
        'basada en el debido y mutuo respeto, ':'basada en el debido y mutuo respeto, ',
        'a fin de asegurar un ambiente ':'a fin de asegurar un ambiente ',
        'armonioso conducente al trabajo ':'armonioso conducente al trabajo ',
        'productivo.':'productivo.',
        'En la relación con los clientes de ':'En la relación con los clientes de ',
        'Grupo Amarey, los empleados de la ':'Grupo Amarey, los empleados de la ',
        'organización siempre deberán actuar ':'organización siempre deberán actuar ',
        'con objetividad, imparcialidad, ':'con objetividad, imparcialidad, ',
        'equidad, excelencia y de acuerdo con ':'equidad, excelencia y de acuerdo con ',
        'los objetivos de imagen, crecimiento ':'los objetivos de imagen, crecimiento ',
        'y rentabilidad de la empresa.':'y rentabilidad de la empresa.',
        'Son todas aquellas ventajas, artículos ':'Son todas aquellas ventajas, artículos ',
        'o incentivos en dinero o especie que ':'o incentivos en dinero o especie que ',
        'se entregan a terceros (cualquier ':'se entregan a terceros (cualquier ',
        'relacionado con compra, distribución, ':'relacionado con compra, distribución, ',
        'comercialización, prescripción y ':'comercialización, prescripción y ',
        'dispensación de fármacos, ': 'dispensación de fármacos, ',
        'dispositivos médicos o insumos de ':'dispositivos médicos o insumos de ',
        'salud), con el propósito de obtener ':'salud), con el propósito de obtener ',
        'beneficios comerciales que no se ':'beneficios comerciales que no se ',
        'ajustan a los principios de la ':'ajustan a los principios de la ',
        'promoción ética, de la competencia ':'promoción ética, de la competencia ',
        'leal y del respeto por los principios, la ':'leal y del respeto por los principios, la ',
        'moral y el buen actuar.':'moral y el buen actuar.',
        'Juntos trabajamos por ':'Juntos trabajamos por ',
        'alcanzar nuestro prop\xF3sito de ':'alcanzar nuestro prop\xF3sito de ',
        'un compromiso inquebrantable ':'un compromiso inquebrantable ',
        'con la salud y la vida.':'con la salud y la vida.',
        '\xBFC\xF3mo unirse?':'\xBFC\xF3mo unirse?',
        'Cada d\xEDa nos esforzamos por el bienestar de ':'Cada d\xEDa nos esforzamos por el bienestar de ',
        'nuestros colaboradores. Somos una empresa de ':'nuestros colaboradores. Somos una empresa de ',
        'capital colombiano ':'capital colombiano',
        ', comprometida con la salud ':', comprometida con la salud ',
        'y dedicada a ampliar las oportunidades de vida.':'y dedicada a ampliar las oportunidades de vida.',
        'En Grupo Amarey, sabemos que el talento ':'En Grupo Amarey, sabemos que el talento ',
        'excepcional es la clave para hacer ':'excepcional es la clave para hacer ',
        'cosas ':'cosas ',
        'incre\xEDbles y alcanzar resultados extraordinarios. ':'incre\xEDbles y alcanzar resultados extraordinarios. ',
        'Generamos':'Generamos ',
        'entornos de trabajo inclusivos, ':'entornos de trabajo inclusivos, ',
        'colaborativos, retadores y estimulantes':'colaborativos, retadores y estimulantes',
        ' donde ':' donde ',
        'cada colaborador puede aportar, crecer y ':'cada colaborador puede aportar, crecer y ',
        'contribuir de manera significativa al':'contribuir de manera significativa al',
        ' logro de ':' logro de ',
        'nuestros objetivos.':'nuestros objetivos.',
        'Estamos en b\xFAsqueda de personas':'Estamos en b\xFAsqueda de personas ',
        'apasionadas ':'apasionadas ',
        'y comprometidas,':'y comprometidas,',
        'que ':' que ',
        'compartan y reflejen':'compartan y reflejen ',
        'nuestros valores, ':'nuestros valores,',
        ' alineados con':' alineados con ',
        ' la':'la ',
        'Filosofia ser':'Filosofia ser',
        '(Servicio, Educaci\xF3n y Relacionamiento)':'(Servicio, Educaci\xF3n y Relacionamiento)',
        'y est\xE9n ':' y est\xE9n ',
        'dispuestos a asumir retos diarios y llevar la ':'dispuestos a asumir retos diarios y llevar la ',
        'Organizaci\xF3n a otros niveles. ':'Organizaci\xF3n a otros niveles. ',

        'Si est\xE1s interesado en formar parte del Grupo ':'Si est\xE1s interesado en formar parte del Grupo ',
        'Amarey, te invitamos a enviar tu hoja de vida a ':'Amarey, te invitamos a enviar tu hoja de vida a ',
        'atracciondetalento@grupoamarey.com ':'atracciondetalento@grupoamarey.com ',
        ',':', ',
        'indicando la ciudad donde vives.':'indicando la ciudad donde vives.',
        'avanzando de la nacion': 'avanzando de la',



}
import {englishTextsMobile,spanishTextsMobile} from './translation-movil'

//spanish
const spanishTexts = {
    translation: {
        'biomédico_2': 'Biomédico',
        "Conoce Más":  "Conoce Más",
        "Un compromiso inquebrantable": "Un compromiso inquebrantable",
        'con la salud y la vida': 'con la salud y la vida',
        'El origen de nuestra historia es hoy': 'El origen de nuestra historia es hoy',
        'la esencia de nuestra marca': 'la esencia de nuestra marca',
        'Conoce más de': 'Conoce más de',
        'Grupo Amarey dando clic': 'Grupo Amarey dando clic',
        'en cada uno': 'en cada uno',
        'ROBOT DAVINCI': 'ROBOT DAVINCI',
        'Llevando la precisión quirúrgica más allá de los límites de': 'Llevando la precisión quirúrgica más allá de los límites de',
        'nuestras manos': 'nuestras manos',
        'Conoce mas': 'Conoce mas',
        'desarrollo de tecnología en salud': 'desarrollo de tecnología en salud',
        'Representamos las marcas de mayor': 'Representamos las marcas de mayor',
        'prestigio a nivel mundial en investigación y': 'prestigio a nivel mundial en investigación y',
        'Conoce más': 'Conoce Más',
        'Cifras de': 'Cifras de',
        'impacto': 'impacto',
        'profesionales capacitados':'profesionales capacitados',
        'cirugías robóticas realizadas': 'cirugías robóticas realizadas',
        'con el modelo de logística hospitalaria': 'con el modelo de logística hospitalaria',
        'pacientes atendidos':'pacientes atendidos', 
        'Servicio': 'Servicio',
        'El servicio es uno de los pilares del Grupo Amarey, una misión': 'El servicio es uno de los pilares del Grupo Amarey, una misión',
        'que se ha convertido en la vocación de la compañía para': 'que se ha convertido en la vocación de la compañía para',
        'cumplir el compromiso de ser un actor determinante en la': 'cumplir el compromiso de ser un actor determinante en la',
        'labor de cuidar y salvar vidas.': 'labor de cuidar y salvar vidas.',
        'Grupo Amarey, la cadena que salva vidas.': 'Grupo Amarey, la cadena que salva vidas.',
        'NUESTROS CLIENTES,':'NUESTROS CLIENTES,',
        'NUESTRA RAZÓN DE SER': 'NUESTRA RAZÓN DE SER',
        'Resultados medición de': 'Resultados medición de',
        'experiencia y lealtad (NPS) 2023:': 'experiencia y lealtad (NPS) 2023:',
        'SATISFACCIÓN ': 'SATISFACCIÓN',
        'COBERTURA': 'COBERTURA',
        'CEDIS en ciudades principales': 'CEDIS en ciudades principales',
        'CEDIS en ciudades intermedias': 'CEDIS en ciudades intermedias',
        'Servicio - 3': 'Servicio - 3',
        'logístico de': 'logístico de',
        'clase mundial': 'clase mundial',
        'Nuestra red en': 'Nuestra red en',
        'distribución incluye': 'distribución incluye',
        'transportadoras que': 'transportadoras que',
        'nos permiten llegar a': 'nos permiten llegar a',
        'cualquier parte del país': 'cualquier parte del país',
        'Entregas de urgencias en': 'Entrega de urgencias en',
        'máximo 4 horas': 'máximo 4 horas',
        ' en las ': ' en las ',
        'principales ciudades': 'principales ciudades',
        'Pedidos electrónicos': 'Pedidos electrónicos',
        'CRM Salesforce': 'CRM Salesforce',
        'SAP S/4HANA': 'SAP S/4HANA',
        'Trazabilidad y': 'Trazabilidad y',
        'monitoreo de la': 'monitoreo de la',
        'cadena de frío': 'cadena de frío',
        'Seguimiento por': 'Seguimiento por',
        'GPS para garantizar': 'GPS para garantizar',
        'la entrega': 'la entrega',
        'Equipo logístico de': 'Equipo logístico de',
        '91 personas en': '91 personas en',
        'Colombia, Panamá y': 'Colombia, Panamá y',
        'Costa Rica': 'Costa Rica',
        'PORTAFOLIO': 'PORTAFOLIO',
        'Somos un grupo empresarial con una clara': 'Somos un grupo empresarial con una clara',
        'inspiraci\xF3n profesional y humana; ofrecemos': 'inspiraci\xF3n profesional y humana; ofrecemos',
        'un portafolio de soluciones en salud de la m\xE1s': 'un portafolio de soluciones en salud de la m\xE1s',
        'alta calidad para el cuidado de los pacientes.': 'alta calidad para el cuidado de los pacientes.',
        'Carrera 7 # 99-53, pisos 19 y 20': 'Carrera 7 # 99-53, pisos 19 y 20',
        'L\xEDnea de atenci\xF3n al cliente 018000 180066 - (601) 7447300': 'L\xEDnea de atenci\xF3n al cliente 018000 180066 - (601) 7447300',
        'Reporte de eventos adversos o PQR: servicioalcliente@grupoamarey.c-5om': 'Reporte de eventos adversos o PQR: servicioalcliente@grupoamarey.c-5om',
        'Línea ética: linea.etica@grupoamarey.c-5om': 'Línea ética: linea.etica@grupoamarey.c-5om',
        'Tratamiento de datos personales': 'Tratamiento de datos personales',
        'Pol\xEDtica de protecci\xF3n de datos personales': 'Pol\xEDtica de protecci\xF3n de datos personales',
        'NUESTRA HISTORIA': 'NUESTRA HISTORIA',
        'Grupo Amarey es una compañía con más de 40 años de': 'Grupo Amarey es una compañía con más de 40 años de',
        'trayectoria, comprometida con acercar el conocimiento, la': 'trayectoria, comprometida con acercar el conocimiento, la',
        'ciencia y la tecnolog\xEDa al sector de la salud en Colombia,': 'ciencia y la tecnolog\xEDa al sector de la salud en Colombia,',
        'Panam\xE1 y Costa Rica.': 'Panam\xE1 y Costa Rica.',
        'Con 6 sedes en Colombia y operaci\xF3n internacional en': 'Con 6 sedes en Colombia y operaci\xF3n internacional en',
        'Panam\xE1 y Costa Rica, el grupo empresarial cuenta con un': 'Panam\xE1 y Costa Rica, el grupo empresarial cuenta con un',
        'equipo de colaboradores que trabajan bajo una \xFAnica premisa,': 'equipo de colaboradores que trabajan bajo una \xFAnica premisa,',
        '\u201Cun compromiso inquebrantable con': '\u201Cun compromiso inquebrantable con',
        'la salud y la vida\u201D.': 'la salud y la vida\u201D.',
        'Nace Amarey Nova Medical al': 'Nace Amarey Nova Medical al',
        'obtener la distribuci\xF3n de la': 'obtener la distribuci\xF3n de la',
        'inmunoglobulina humana IgG': 'inmunoglobulina humana IgG',
        'enriquecida, que le dio vida al': 'enriquecida, que le dio vida al',
        'pent\xE1mero que ha sido el': 'pent\xE1mero que ha sido el',
        's\xEDmbolo de la compa\xF1\xEDa.': 's\xEDmbolo de la compa\xF1\xEDa.',
        'Avanzar': 'Avanzar',
        "Se crea la línea respiratoria con": "Se crea la línea respiratoria con",
        "el lanzamiento de un": "el lanzamiento de un",
        "surfactante pulmonar para el": "surfactante pulmonar para el",
        "tratamiento del síndrome de": "tratamiento del síndrome de",
        "distrés respiratorio en neonatos.": "distrés respiratorio en neonatos.",
        "Regresar": "Regresar",
        "Inicia la distribución de": "Inicia la distribución de",
        "dispositivos e insumos  ": "dispositivos e insumos  ",
        "médico-quirúrgicos.": "médico-quirúrgicos.",
        "La compañía se expande a ": "La compañía se expande a ",
        "Panamá y Costa Rica con la ": "Panamá y Costa Rica con la ",
        "distribución del portafolio ": "distribución del portafolio ",
        "quirúrgico y de ortopedia, a ": "quirúrgico y de ortopedia, a ",
        "través de su marca ": "través de su marca ",
        "internacional UCITECH. ": "internacional UCITECH. ",
        'Se amplía el portafolio de ': 'Se amplía el portafolio de ',
        'productos con las líneas de ': 'productos con las líneas de ',
        'implantes ortopédicos de Stryker, ': 'implantes ortopédicos de Stryker, ',
        'equipos médicos, pruebas ': 'equipos médicos, pruebas ',
        'diagnósticas oncológicas y ': 'diagnósticas oncológicas y ',
        'cuidado avanzado de heridas. ': 'cuidado avanzado de heridas. ',
        "Se crea la línea de apoyo en ": "Se crea la línea de apoyo en ",
        "gestión de tecnología ": "gestión de tecnología ",
        "biomédica en el modelo inhouse ": "biomédica en el modelo inhouse ",
        "y servicio de mantenimiento ": "y servicio de mantenimiento ",
        "biomédico.": "biomédico.",
        'Grupo Amarey obtiene la ': 'Grupo Amarey obtiene la ',
        'representación del sistema ': 'representación del sistema ',
        'robótico da Vinci en Colombia ': 'robótico da Vinci en Colombia ',
        'para cirugía mínimamente ': 'para cirugía mínimamente ',
        'invasiva.': 'invasiva.',
        'Nihon Kohden': 'Nihon Kohden',
        "Se inauguran cinco sedes ": "Se inauguran cinco sedes ",
        "propias en Bogotá, Cali, ": "propias en Bogotá, Cali, ",
        "Medellín, Barranquilla y Pereira.": "Medellín, Barranquilla y Pereira.",
        "Obtiene el Premio Colombiano ": "Obtiene el Premio Colombiano ",
        "a la Calidad de la Gestión.": "a la Calidad de la Gestión.",
        'Inicia la distribución de ': 'Inicia la distribución de ',
        'productos para logística ': 'productos para logística ',
        'hospitalaria. ': 'hospitalaria. ',
        'Los Premios Portafolio otorgan ': 'Los Premios Portafolio otorgan ',
        'la Mención de Honor a Amarey ': 'la Mención de Honor a Amarey ',
        'Nova Medical en la categoría ': 'Nova Medical en la categoría ',
        'de Servicio al cliente.': 'de Servicio al cliente.',
        'A lo largo de su existencia el ': 'A lo largo de su existencia el ',
        'Grupo Amarey ha crecido ': 'Grupo Amarey ha crecido ',
        'positivamente con los más altos ': 'positivamente con los más altos ',
        'estándares de calidad e ': 'estándares de calidad e ',
        'innovación, por eso decide ': 'innovación, por eso decide ',
        'lanzar al mercado el primer ': 'lanzar al mercado el primer ',
        'antibiótico con marca propia.': 'antibiótico con marca propia.',
        'El portafolio del Grupo Amarey ': 'El portafolio del Grupo Amarey ',
        'sigue creciendo con la ': 'sigue creciendo con la ',
        'representación de KLS Martin ': 'representación de KLS Martin ',
        'para ortopedia cráneo ': 'para ortopedia cráneo ',
        'maxilofacial, instrumental y ': 'maxilofacial, instrumental y ',
        'esterilización.': 'esterilización.',
        'Se crea la línea de ': 'Se crea la línea de ',
        'neurointervencionismo con ': 'neurointervencionismo con ',
        'la llegada del portafolio de ': 'la llegada del portafolio de ',
        'CERENOVUS para el ': 'CERENOVUS para el ',
        'tratamiento de ACV.': 'tratamiento de ACV.',
        'Grupo Amarey renueva su ': 'Grupo Amarey renueva su ',
        'identidad de marca, logrando ': 'identidad de marca, logrando ',
        'que refleje los valores, la cultura ': 'que refleje los valores, la cultura ',
        'y la misión de la compañía.': 'y la misión de la compañía.',
        'Se inaugura el Centro de ': 'Se inaugura el Centro de ',
        'Distribución Logística – CEDI en ': 'Distribución Logística – CEDI en ',
        'la zona industrial de Montevideo ': 'la zona industrial de Montevideo ',
        'en Bogotá, con el objetivo de ': 'en Bogotá, con el objetivo de ',
        'lograr mejores niveles de servicio ': 'lograr mejores niveles de servicio ',
        'logístico para los clientes.': 'logístico para los clientes.',
        'Misión':'Misión',
        'Somos un grupo empresarial orgullosamente colombiano ': 'Somos un grupo empresarial orgullosamente colombiano ',
        'que representa productos y servicios de calidad guiados ': 'que representa productos y servicios de calidad guiados ',
        'por nuestra filosofía SER (Servicio, Educación, ': 'por nuestra filosofía SER (Servicio, Educación, ',
        'Relacionamiento).': 'Relacionamiento).',
        'FILOSOFÍA': 'FILOSOFÍA',
        'Generamos valor a los pacientes, sus familias, instituciones ': 'Generamos valor a los pacientes, sus familias, instituciones ',
        'de salud y accionistas con un talento humano excepcional, ': 'de salud y accionistas con un talento humano excepcional, ',
        'con sentido ético y altamente comprometido con la salud ': 'con sentido ético y altamente comprometido con la salud ',
        'y la vida.': 'y la vida.',
        'En el 2025 el Grupo Amarey será el aliado preferido de ': 'En el 2025 el Grupo Amarey será el aliado preferido de ',
        'las instituciones de salud y de los fabricantes de ': 'las instituciones de salud y de los fabricantes de ',
        'productos especializados, en Colombia y los países del ': 'productos especializados, en Colombia y los países del ',
        'área andina y Centroamérica.': 'área andina y Centroamérica.',
        'Esto será posible por nuestra trayectoria, reputación, por ': 'Esto será posible por nuestra trayectoria, reputación, por ',
        'cumplir lo que prometemos y por la capacidad para ': 'cumplir lo que prometemos y por la capacidad para ',
        'ofrecer soluciones integrales innovadoras.': 'ofrecer soluciones integrales innovadoras.',
        'Valores': 'Valores',
        'Respeto y calidez': 'Respeto y calidez',
        'Valoramos y respetamos el ': 'Valoramos y respetamos el ',
        'punto de vista, el saber y el ': 'punto de vista, el saber y el ',
        'quehacer de otros. Nos ': 'quehacer de otros. Nos ',
        'caracteriza el relacionamiento ': 'caracteriza el relacionamiento ',
        'basado en las buenas maneras.': 'basado en las buenas maneras.',
        'Excelencia en calidad': 'Excelencia en calidad',
        'Realizamos las cosas con ': 'Realizamos las cosas con ',
        'calidad, oportunidad y actitud ': 'calidad, oportunidad y actitud ',
        'desde el principio, cumpliendo ': 'desde el principio, cumpliendo ',
        'con la promesa de valor a ': 'con la promesa de valor a ',
        'nuestros clientes y propendiendo ': 'nuestros clientes y propendiendo ',
        'con el mejoramiento continuo.': 'con el mejoramiento continuo.',
        'Convicción': 'Convicción',
        'Actuamos con conocimiento ': 'Actuamos con conocimiento ',
        'profundo sobre los bienes y ': 'profundo sobre los bienes y ',
        'servicios que le brindamos a la ': 'servicios que le brindamos a la ',
        'comunidad, identificándonos ': 'comunidad, identificándonos ',
        'con la filosofía corporativa.': 'con la filosofía corporativa.',
        'Transparencia': 'Transparencia',
        'Nuestras actuaciones siempre ': 'Nuestras actuaciones siempre ',
        'se realizan a la luz pública y ': 'se realizan a la luz pública y ',
        'están regidas siempre por la ': 'están regidas siempre por la ',
        'buena voluntad, la buena fe y ': 'buena voluntad, la buena fe y ',
        'la orientación a los resultados ': 'la orientación a los resultados ',
        'éticos y morales.': 'éticos y morales.',
        'Sentido de responsabilidad': 'Sentido de responsabilidad',
        'con otros': 'con otros',
        'Somos sensibles a las necesidades ': 'Somos sensibles a las necesidades ',
        'de los demás comprometiéndonos ': 'de los demás comprometiéndonos ',
        'con el mejoramiento de su calidad ': 'con el mejoramiento de su calidad ',
        'de vida y bienestar.': 'de vida y bienestar.',
        'Filosofía SER': 'Filosofía SER',
        'El Grupo Amarey trabaja bajo una única premisa: la filosofía SER ': 'El Grupo Amarey trabaja bajo una única premisa: la filosofía SER ',
        '(Servicio, Educación y Relacionamiento), pilares que marcan la ': '(Servicio, Educación y Relacionamiento), pilares que marcan la ',
        'diferencia de la compañía en la industria.': 'diferencia de la compañía en la industria.',
        'Servicio ': 'Servicio ',
        'Una compañía con sentido humano, que ofrece un servicio que ': 'Una compañía con sentido humano, que ofrece un servicio que ',
        'trasciende hacia brindar más oportunidades para la vida. ': 'trasciende hacia brindar más oportunidades para la vida. ',
        'Educación ': 'Educación ',
        'Representa el compromiso del Grupo Amarey con la generación de ': 'Representa el compromiso del Grupo Amarey con la generación de ',
        'conocimiento para el personal de la salud, contribuyendo a la ': 'conocimiento para el personal de la salud, contribuyendo a la ',
        'construcción de un país que ofrezca mayores beneficios para la ': 'construcción de un país que ofrezca mayores beneficios para la ',
        'calidad de vida de los pacientes. ': 'calidad de vida de los pacientes. ',
        'Relacionamiento ': 'Relacionamiento ',
        'Ha permitido construir la base de confianza y reputación del ': 'Ha permitido construir la base de confianza y reputación del ',
        'Grupo Amarey frente a sus públicos de interés. Hoy la compañía es ': 'Grupo Amarey frente a sus públicos de interés. Hoy la compañía es ',
        'reconocida por su integridad, compromiso, excelencia, humanidad ': 'reconocida por su integridad, compromiso, excelencia, humanidad ',
        'e innovación. ': 'e innovación. ',
        'RECONOCIMIENTOS': 'RECONOCIMIENTOS',
        'Certificaciones': 'Certificaciones',
        'Premios y reconocimientos': 'Premios y reconocimientos',
        'Certificado Amarey Nova ': 'Certificado Amarey Nova ',
        'Medical ISO 90001': 'Medical ISO 90001',
        'Certificado Ucipharma ': 'Certificado Ucipharma ',
        'ISO 90001': 'ISO 90001',
        'Certificado Novatécnica Sistema de gestión de ': 'Certificado Novatécnica Sistema de gestión de ',
        'calidad en dispositivos médicos ISO 13485': 'calidad en dispositivos médicos ISO 13485',
        'Certificación OEA (Operador Económico ': 'Certificación OEA (Operador Económico ',
        'Autorizado) de la DIAN, obtenida en 2001, ': 'Autorizado) de la DIAN, obtenida en 2001, ',
        'demostrando la seguridad en la cadena de ': 'demostrando la seguridad en la cadena de ',
        'suministro internacional de la compañía.': 'suministro internacional de la compañía.',
        'RESPONSABILIDAD ': 'RESPONSABILIDAD ',
        'SOCIAL EMPRESARIAL': 'SOCIAL EMPRESARIAL',
        'La ': 'La ',
        ' Fundaci\xF3n OCMAES':' Fundaci\xF3n OCMAES',
        ' es una entidad sin \xE1nimo de lucro con ': ' es una entidad sin \xE1nimo de lucro con ',
        '18 a\xF1os de trayectoria': '18 a\xF1os de trayectoria',
        ', que hace parte del': ', que hace parte del',
        ' Grupo Amarey y ': ' Grupo Amarey y ',
        'que fue creada con el prop\xF3sito de promover el desarrollo ': 'que fue creada con el prop\xF3sito de promover el desarrollo ',
        'humano y profesional de j\xF3venes colombianos.': 'humano y profesional de j\xF3venes colombianos.',
        'Apoya a j\xF3venes con potencial acad\xE9mico y dificultades ': 'Apoya a j\xF3venes con potencial acad\xE9mico y dificultades ',
        'econ\xF3micas para acceder a educaci\xF3n superior de calidad, ': 'econ\xF3micas para acceder a educaci\xF3n superior de calidad, ',
        'contribuyendo a su formaci\xF3n como l\xEDderes y profesionales ': 'contribuyendo a su formaci\xF3n como l\xEDderes y profesionales ',
        'competitivos comprometidos con el desarrollo social del pa\xEDs. ': 'competitivos comprometidos con el desarrollo social del pa\xEDs. ',
        'Lo que hace la ': 'Lo que hace la ',
        'Fundaci\xF3n OCMAES': 'Fundaci\xF3n OCMAES',
        ' es simple pero profundo:': ' es simple pero profundo:',
        'Promueve    ': 'Promueve    ',
        'el desarrollo ': 'el desarrollo ',
        'humano y ': 'humano y ',
        'del pa\xEDs': 'del pa\xEDs',
        'Cree en la ': 'Cree en la ',
        'transformaci\xF3n ': 'transformaci\xF3n ',
        'de vidas a ': 'de vidas a ',
        'trav\xE9s de la ': 'trav\xE9s de la ',
        'educaci\xF3n': 'educaci\xF3n',
        'Por eso otorga ': 'Por eso otorga ',
        'becas universitarias ': 'becas universitarias ',
        'a estudiantes con ': 'a estudiantes con ',
        'un alto potencial ': 'un alto potencial ',
        'acad\xE9mico que se ': 'acad\xE9mico que se ',
        'encuentran en ': 'encuentran en ',
        'circunstancias ': 'circunstancias ',
        'econ\xF3micas y ': 'econ\xF3micas y ',
        'sociales dif\xEDciles': 'sociales dif\xEDciles',
        '+ DE 4.600 BENEFICIARIOS': '+ DE 4.600 BENEFICIARIOS',
        'a lo largo de sus 18 a\xF1os de trayectoria': 'a lo largo de sus 18 a\xF1os de trayectoria',
        'Conoce más de La Fundación OCMAES': 'Conoce más de La Fundación OCMAES',
        'NUESTROS': 'NUESTROS',
        'ALIADOS': 'ALIADOS',
        'Europa': 'Europa',
        'Japón': 'Japón',
        'mantenimiento ': 'mantenimiento ',
        'biom\xE9dico ': 'biom\xE9dico ',
        'Somos la compañía de servicio técnico ':'Somos la compañía de servicio técnico ',
        'biom\xE9dico m\xE1s grande del pa\xEDs y la primera ':'biom\xE9dico m\xE1s grande del pa\xEDs y la primera ',
        'empresa a nivel nacional en certificarse con ': 'empresa a nivel nacional en certificarse con ',
        'la': 'la',
        'norma ISO 13485 de ICONTEC': 'norma ISO 13485 de ICONTEC',
        ' para el ': ' para el ',
        'servicio t\xE9cnico de dispositivos m\xE9dicos. ': 'servicio t\xE9cnico de dispositivos m\xE9dicos. ',
        'MANTENIMIENTO BIOM\xC9DICO ': 'MANTENIMIENTO BIOM\xC9DICO ',
        'Ofrecemos soluciones de mantenimiento ': 'Ofrecemos soluciones de mantenimiento ',
        'biom\xE9dico': 'biom\xE9dico',
        ' para todos los actores del ': ' para todos los actores del ',
        'sector salud. Nuestro apoyo es ' : 'sector salud. Nuestro apoyo es ',
        'ejecutado con entrenamiento, ': 'ejecutado con entrenamiento, ',
        'experiencia e innovaci\xF3n en los procesos ': 'experiencia e innovaci\xF3n en los procesos ',
        'humanos y t\xE9cnicos.  ': 'humanos y t\xE9cnicos.  ',
        'Nuestro modelo de ': 'Nuestro modelo de ',
        'mantenimiento': 'mantenimiento',
        'inhouse y mantenimiento posventa': 'inhouse y mantenimiento posventa',
        ' nos ': ' nos ',
        'permiten construir soluciones ': 'permiten construir soluciones ',
        'personalizadas para cada cliente, ': 'personalizadas para cada cliente, ',
        'asegurando los m\xE1s altos est\xE1ndares de': 'asegurando los m\xE1s altos est\xE1ndares de ',
        'calidad y un plan de mantenimiento ': 'calidad y un plan de mantenimiento ',
        'eficiente para toda la ': 'eficiente para toda la ',
        'tecnolog\xEDa ': 'tecnolog\xEDa ',
        'biom\xE9dica involucrada. ': 'biom\xE9dica involucrada. ',
        'Más': 'Más',
        'Nuestras manos expertas pueden entender y cuidar la tecnolog\xEDa para la tranquilidad de nuestros clientes y ': 'Nuestras manos expertas pueden entender y cuidar la tecnolog\xEDa para la tranquilidad de nuestros clientes y ',
        'proveedores, todo para crear soluciones para el cuidado de la salud y los pacientes. ': 'proveedores, todo para crear soluciones para el cuidado de la salud y los pacientes. ',
        'Atendemos una capacidad instalada cercana a los ': 'Atendemos una capacidad instalada cercana a los ',
        '12.000 equipos': '12.000 equipos',
        ' de marcas representadas ': ' de marcas representadas ',
        'Contamos con ':'Contamos con ',
        '130 profesionales': '130 profesionales',
        'dedicados al cuidado de la tecnolog\xEDa biom\xE9dica y ': 'dedicados al cuidado de la tecnolog\xEDa biom\xE9dica y ',
        'comprometidos con la seguridad de los pacientes ': 'comprometidos con la seguridad de los pacientes ',
        ' SOLUCIONES EN SALUD':' SOLUCIONES EN SALUD',
        'Medicamentos': 'Medicamentos',
        'Cirugía robótica': 'Cirugía robótica',
        'Equipos médicos': 'Equipos médicos',
        'Ortopedia': 'Ortopedia',
        'Logística hospitalaria': 'Logística hospitalaria',
        'Dispositivos': 'Dispositivos',
        'médico-quirúrgicos': 'médico-quirúrgicos',
        'Genómica y genética': 'Genómica y genética',
        'Instrumental y esterilización': 'Instrumental y esterilización',
        'Cuidado avanzado': 'Cuidado avanzado',
        'de heridas': 'de heridas',
        'Neurointervencionismo': 'Neurointervencionismo',
        'SOLUCIONES EN SALUD': 'SOLUCIONES EN SALUD',
        'MEDICAMENTOS ': 'MEDICAMENTOS ',
        'Con medicamentos de la más alta tecnología para el cuidado de la': 'Con medicamentos de la más alta tecnología para el cuidado de la',
        'salud de los pacientes, ya sea adultos, ni\xF1os o neonatos, ': 'salud de los pacientes, ya sea adultos, ni\xF1os o neonatos, ',
        'ofrecemos mayor eficacia y tolerabilidad al paciente en productos.': 'ofrecemos mayor eficacia y tolerabilidad al paciente en productos.',
        'especializados para:': 'especializados para:',
        'Trasplantes ': 'Trasplantes ',
        'Hemoderivados ': 'Hemoderivados ',
        'Antibi\xF3ticos  ': 'Antibi\xF3ticos  ',
        'Reumatolog\xEDa ': 'Reumatolog\xEDa ',
        'Respiratoria  ': 'Respiratoria  ',
        'UCI Neonatolog\xEDa ': 'UCI Neonatolog\xEDa ',
        'UCI Pedi\xE1trica  ': 'UCI Pedi\xE1trica  ',
        'UCI Adultos ': 'UCI Adultos ',
        'Dolor ': 'Dolor ',
        'Gen\xE9tica\u202Fy\u202Fgen\xF3mica ': 'Gen\xE9tica\u202Fy\u202Fgen\xF3mica ',
        'Neurointervencionismo ': 'Neurointervencionismo ',
        'Volver': 'Volver',
        'Una extensi\xF3n natural de los ojos, a trav\xE9s de una visi\xF3n en 3D y ':'Una extensi\xF3n natural de los ojos, a trav\xE9s de una visi\xF3n en 3D y ',
        'magnificaci\xF3n hasta 10 veces. Movimientos intuitivos con mayor ': 'magnificaci\xF3n hasta 10 veces. Movimientos intuitivos con mayor ',
        'rango de movimiento que exceden las capacidades de la mano ': 'rango de movimiento que exceden las capacidades de la mano ',
        'humana. ': 'humana. ',
        'Somos el comercializador del sistema quir\xFArgico da Vinci, con una ': 'Somos el comercializador del sistema quir\xFArgico da Vinci, con una ',
        'experiencia de m\xE1s de 3.000 cirug\xEDas en nuestro pa\xEDs, una ': 'experiencia de m\xE1s de 3.000 cirug\xEDas en nuestro pa\xEDs, una ',
        'sofisticada plataforma rob\xF3tica que permite potenciar las ': 'sofisticada plataforma rob\xF3tica que permite potenciar las ',
        'capacidades del cirujano y ofrecer una opci\xF3n m\xEDnimamente ': 'capacidades del cirujano y ofrecer una opci\xF3n m\xEDnimamente ',
        'invasiva en procedimientos de cirug\xEDa compleja.  ': 'invasiva en procedimientos de cirug\xEDa compleja.  ',
        'El sistema da Vinci da tratamiento a cuatro de los cinco tipos de ': 'El sistema da Vinci da tratamiento a cuatro de los cinco tipos de ',
        'c\xE1ncer m\xE1s frecuente en Colombia: c\xE1ncer de pr\xF3stata, c\xE1ncer de ': 'c\xE1ncer m\xE1s frecuente en Colombia: c\xE1ncer de pr\xF3stata, c\xE1ncer de ',
        'est\xF3mago, c\xE1ncer de pulm\xF3n, c\xE1ncer colorrectal y muchas m\xE1s ': 'est\xF3mago, c\xE1ncer de pulm\xF3n, c\xE1ncer colorrectal y muchas m\xE1s ',
        'patolog\xEDas.': 'patolog\xEDas.',
        'CIRUG\xCDA ROB\xD3TICA ': 'CIRUG\xCDA ROB\xD3TICA ',
        'Beneficios de la cirug\xEDa rob\xF3tica:': 'Beneficios de la cirug\xEDa rob\xF3tica:',
        'Paciente': 'Paciente',
        'Cirujano': 'Cirujano',
        'Instituci\xF3n': 'Instituci\xF3n',
        'Menor tiempo de hospitalizaci\xF3n. ': 'Menor tiempo de hospitalizaci\xF3n. ',
        'Recuperaci\xF3n con menos dolor y riesgo ': 'Recuperaci\xF3n con menos dolor y riesgo ',
        'de infecci\xF3n. ': 'de infecci\xF3n. ',
        'Cicatrices m\xE1s peque\xF1as. ': 'Cicatrices m\xE1s peque\xF1as. ',
        'R\xE1pido retorno a las actividades ': 'R\xE1pido retorno a las actividades ',
        'diarias. ': 'diarias. ',
        'Mejor calidad de vida. ': 'Mejor calidad de vida. ',
        'Movimientos intuitivos. Rotaci\xF3n de ':'Movimientos intuitivos. Rotaci\xF3n de ',
        '540\xB0, articulaci\xF3n de 180\xB0.': '540\xB0, articulaci\xF3n de 180\xB0.',
        'Visi\xF3n 3D y magnificaci\xF3n 10X.': 'Visi\xF3n 3D y magnificaci\xF3n 10X.',
        'Mayor visi\xF3n, control y ergonom\xEDa. ': 'Mayor visi\xF3n, control y ergonom\xEDa. ',
        'Mayor precisi\xF3n y seguridad. ': 'Mayor precisi\xF3n y seguridad. ',
        'Innovaci\xF3n y diferenciaci\xF3n. ': 'Innovaci\xF3n y diferenciaci\xF3n. ',
        'Investigaci\xF3n y academia. ': 'Investigaci\xF3n y academia. ',
        'Tecnolog\xEDa de vanguardia. ': 'Tecnolog\xEDa de vanguardia. ',
        'Atracci\xF3n de nuevos pacientes y ': 'Atracci\xF3n de nuevos pacientes y ',
        'cirujanos. ': 'cirujanos. ',
        'Mejores resultados cl\xEDnicos. ': 'Mejores resultados cl\xEDnicos. ',
        'EQUIPOS M\xC9DICOS ': 'EQUIPOS M\xC9DICOS ',
        'Ofrecemos a los especialistas equipos de la m\xE1s alta tecnolog\xEDa ': 'Ofrecemos a los especialistas equipos de la m\xE1s alta tecnolog\xEDa ',
        'para el servicio y asistencia de los pacientes.': 'para el servicio y asistencia de los pacientes.',
        'Monitores de signos vitales ': 'Monitores de signos vitales ',
        'Centrales de monitoreo ': 'Centrales de monitoreo ',
        'Electrocardiograf\xEDa ': 'Electrocardiograf\xEDa ',
        'Ventilaci\xF3n  ': 'Ventilaci\xF3n  ',
        'Equipos para neurolog\xEDa: ': 'Equipos para neurolog\xEDa: ',
        'electroencefalograf\xEDa, polisomnograf\xEDa ': 'electroencefalograf\xEDa, polisomnograf\xEDa ',
        'y electromiograf\xEDa. ': 'y electromiograf\xEDa. ',
        'Camas y camillas hospitalarias ': 'Camas y camillas hospitalarias ',
        'Mesas de cirug\xEDa ': 'Mesas de cirug\xEDa ',
        'Incubadora neonatal y l\xE1mparas de ': 'Incubadora neonatal y l\xE1mparas de ',
        'fototerapia ': 'fototerapia ',
        'Desfibrilador Externo Autom\xE1tico (DEA) ': 'Desfibrilador Externo Autom\xE1tico (DEA) ',
        'L\xE1mparas para quir\xF3fano, ': 'L\xE1mparas para quir\xF3fano, ',
        'procedimientos y exploraci\xF3n ': 'procedimientos y exploraci\xF3n ',
        'Unidades de suministro medicinales ': 'Unidades de suministro medicinales ',
        'Con una variada gama de soluciones para \xE1reas especializadas, UCIs, quir\xF3fanos, urgencias y hospitalizaci\xF3n. ':'Con  una  variada  gama  de  soluciones  para \xE1reas especializadas, UCIs, quir\xF3fanos, urgencias y hospitalizaci\xF3n. ',
        'En cumplimiento de la Ley 1672 del 19 de julio de 2013, Decreto 284 de 2018 y Resoluci\xF3n 851 del 2022. Amarey Nova ': 'En cumplimiento de la Ley 1672 del 19 de julio de 2013, Decreto 284 de 2018 y Resoluci\xF3n 851 del 2022. Amarey Nova ',
        'Medical S.a-medical, Ucipharma S.a-medical. y Novat\xE9cnica S.a-medical.S., ponen a su disposici\xF3n nuestro proceso ': 'Medical S.a-medical, Ucipharma S.a-medical. y Novat\xE9cnica S.a-medical.S., ponen a su disposici\xF3n nuestro proceso ',
        'de disposici\xF3n y gesti\xF3n de los residuos de aparatos el\xE9ctricos y electr\xF3nicos (RAEE) relacionados espec\xEDficamente con los equipos ': 'de disposici\xF3n y gesti\xF3n de los residuos de aparatos el\xE9ctricos y electr\xF3nicos (RAEE) relacionados espec\xEDficamente',
        'biom\xE9dicos el\xE9ctricos o electr\xF3nicos (con excepci\xF3n de todos los productos implantados e infectados) que ':'con los equipos biom\xE9dicos el\xE9ctricos o electr\xF3nicos (con excepci\xF3n de todos los productos implantados e infectados)',
        'distribuye nuestras compa\xF1\xEDas. Para este fin, solicitamos comunicarse directamente con nuestro representante de ': 'que distribuye nuestras compa\xF1\xEDas. Para este fin, solicitamos comunicarse directamente con nuestro representante de ',
        'ventas asignado a su instituci\xF3n con el objetivo de programar su recolecci\xF3n. Para la disposici\xF3n final de los RAEE ': 'ventas asignado a su instituci\xF3n con el objetivo de programar su recolecci\xF3n. Para la disposici\xF3n final de los RAEE ',
        'realizamos la gesti\xF3n a trav\xE9s de una empresa autorizada por la Autoridad Ambiental.  ': 'realizamos la gesti\xF3n a trav\xE9s de una empresa autorizada por la Autoridad Ambiental.  ',
        'ORTOPEDIA': 'ORTOPEDIA',
        'Somos líderes con tecnología que aporta a los profesionales de la ':'Somos líderes con tecnología que aporta a los profesionales de la ',
        'salud y sus pacientes las herramientas en cirugías que permiten ':'salud y sus pacientes las herramientas en cirugías que permiten ',
        'alcanzar una calidad de vida más activa y satisfactoria mediante ':'alcanzar una calidad de vida más activa y satisfactoria mediante ',
        'las líneas de:  ':'las líneas de:  ',
        'Nuestro modelo integral de servicio de ': 'Nuestro modelo integral de servicio de ',
        'ortopedia ofrece disponibilidad 24/7 los ': 'ortopedia ofrece disponibilidad 24/7 los ',
        '365 d\xEDas del a\xF1o, con un servicio que ': '365 d\xEDas del a\xF1o, con un servicio que ',
        'ofrece acompa\xF1amiento en el quir\xF3fano ': 'ofrece acompa\xF1amiento en el quir\xF3fano ',
        'y servicio posventa. ': 'y servicio posventa. ',
        'Contamos con una red log\xEDstica de ': 'Contamos con una red log\xEDstica de ',
        'ortopedia para atenci\xF3n de cirug\xEDas en ': 'ortopedia para atenci\xF3n de cirug\xEDas en ',
        'ciudades principales e intermedias de ': 'ciudades principales e intermedias de ',
        'Colombia y Costa Rica. ': 'Colombia y Costa Rica. ',
        'LOG\xCDSTICA':'LOG\xCDSTICA',
        'HOSPITALARIA':'HOSPITALARIA',
        'Nuestro portafolio de soluciones tecnol\xF3gicas est\xE1 enfocado en la ': 'Nuestro portafolio de soluciones tecnol\xF3gicas est\xE1 enfocado en la ',
        'automatizaci\xF3n de procesos intrahospitalarios para el ': 'automatizaci\xF3n de procesos intrahospitalarios para el ',
        'almacenamiento y la dispensaci\xF3n de medicamentos y material ': 'almacenamiento y la dispensaci\xF3n de medicamentos y material ',
        'm\xE9dico-quir\xFArgico, aumentando la seguridad del paciente y la ': 'm\xE9dico-quir\xFArgico, aumentando la seguridad del paciente y la ',
        'eficiencia en los procesos. ': 'eficiencia en los procesos. ',
        'Alineamos estos procesos a los ': 'Alineamos estos procesos a los ',
        'lineamientos de la ': 'lineamientos de la ',
        'Joint Commission ': 'Joint Commission ',
        'International':'International',
        ', buscando las mejores ': ', buscando las mejores ',
        'pr\xE1cticas para asegurar la trazabilidad ': 'pr\xE1cticas para asegurar la trazabilidad ',
        'de medicamentos y generando en ': 'de medicamentos y generando en ',
        'tiempo real los movimientos de ': 'tiempo real los movimientos de ',
        'inventario. ': 'inventario. ',
        'Dispositivos ': 'Dispositivos',
        'médico-quirúrgicos ': 'médico-quirúrgicos',
        'Somos un aliado estrat\xE9gico para los profesionales que realizan ':'Somos un aliado estrat\xE9gico para los profesionales que realizan ',
        'procedimientos quir\xFArgicos con equipos y dispositivos m\xE9dicos que ':'procedimientos quir\xFArgicos con equipos y dispositivos m\xE9dicos que ',
        'mejoran los resultados del paciente en una intervenci\xF3n.':'mejoran los resultados del paciente en una intervenci\xF3n.',
        'Dispositivos m\xE9dico-quir\xFArgicos': 'Dispositivos m\xE9dico-quir\xFArgicos',
        'Mejoramos los resultados en una ': 'Mejoramos los resultados en una ',
        'intervenci\xF3n con:  ': 'intervenci\xF3n con:  ',
        'Apoyamos la seguridad del ':'Apoyamos la seguridad del ',
        'paciente con:':'intervenci\xF3n con:  ',
        'Equipos y dispositivos m\xE9dicos para cirug\xEDa ': 'Equipos y dispositivos m\xE9dicos para cirug\xEDa ',
        'laparosc\xF3pica ': 'laparosc\xF3pica ',
        'Dispositivos para reparo de hernias inguinales ': 'Dispositivos para reparo de hernias inguinales ',
        'y ventrales ': 'y ventrales ',
        'Ropa quir\xFArgica y barreras de protecci\xF3n ':'Ropa quir\xFArgica y barreras de protecci\xF3n ',
        'especializadas ':'especializadas ',
        'Hemost\xE1ticos y sellantes para cirug\xEDa ':'Hemost\xE1ticos y sellantes para cirug\xEDa ',
        'm\xEDnimamente invasiva':'m\xEDnimamente invasiva',
        'Protecci\xF3n de la herida quir\xFArgica. ': 'Protecci\xF3n de la herida quir\xFArgica. ',
        'Control del sangrado. ': 'Control del sangrado. ',
        'Disminuci\xF3n de la infecci\xF3n en el sitio ': 'Disminuci\xF3n de la infecci\xF3n en el sitio ',
        'operatorio. ': 'operatorio. ',
        'Disminuci\xF3n de tiempos quir\xFArgicos. ': 'Disminuci\xF3n de tiempos quir\xFArgicos. ',
        'Disminuci\xF3n de estancias hospitalarias ':'Disminuci\xF3n de estancias hospitalarias ',
        'Todos productos de nuestro portafolio est\xE1n aprobados por la FDA y cuentan con altos est\xE1ndares de calidad. ': 'Todos productos de nuestro portafolio est\xE1n aprobados por la FDA y cuentan con altos est\xE1ndares de calidad. ',
        'Contamos con un centro de lavado con la \xFAltima tecnolog\xEDa y est\xE1ndares, para permitirnos ofrecer un producto ': 'Contamos con un centro de lavado con la \xFAltima tecnolog\xEDa y est\xE1ndares, para permitirnos ofrecer un producto ',
        'con la mejor calidad y garantizar las condiciones sanitarias de kits de cirug\xEDa utilizados. Adicionalmente, ':'con la mejor calidad y garantizar las condiciones sanitarias de kits de cirug\xEDa utilizados. Adicionalmente, ',
        'realizamos acompa\xF1amiento quir\xFArgico en cirug\xEDa. ':'realizamos acompa\xF1amiento quir\xFArgico en cirug\xEDa. ',
        'Gen\xF3mica Y Gen\xE9ticA': 'Gen\xF3mica Y Gen\xE9ticA',
        'Gen\xE9ticA': 'Gen\xE9ticA',
        'Gen\xF3mica': 'Gen\xF3mica',
        'La gen\xE9tica es el estudio de rasgos y genes heredados que ': 'La gen\xE9tica es el estudio de rasgos y genes heredados que ',
        'pueden predisponer a una persona a una condici\xF3n de salud ':'pueden predisponer a una persona a una condici\xF3n de salud ',
        'particular.':'particular.',
        'La gen\xF3mica analiza la forma en que se comporta un conjunto ':'La gen\xF3mica analiza la forma en que se comporta un conjunto ',
        'de genes. ':'de genes. ',
        'Las pruebas gen\xF3micas (tambi\xE9n conocidas como ':'Las pruebas gen\xF3micas (tambi\xE9n conocidas como ',
        'pruebas multig\xE9nicas) analizan una muestra del ':'pruebas multig\xE9nicas) analizan una muestra del ',
        'tumor del c\xE1ncer para analizar el nivel de actividad ':'tumor del c\xE1ncer para analizar el nivel de actividad ',
        'de determinados genes, el cual afecta el ':'de determinados genes, el cual afecta el ',
        'comportamiento del c\xE1ncer, incluyendo su ':'comportamiento del c\xE1ncer, incluyendo su ',
        'probabilidad de crecimiento y propagaci\xF3n. Una de ':'probabilidad de crecimiento y propagaci\xF3n. Una de ',
        'estas pruebas gen\xF3micas en particular ':'estas pruebas gen\xF3micas en particular ',
        'proporciona, adem\xE1s, una estimaci\xF3n del beneficio ':'proporciona, adem\xE1s, una estimaci\xF3n del beneficio ',
        'de la quimioterapia. Se usan para ayudar en la ':'de la quimioterapia. Se usan para ayudar en la ',
        'toma de decisiones sobre tratamiento despu\xE9s de ':'toma de decisiones sobre tratamiento despu\xE9s de ',
        'la cirug\xEDa. ':'la cirug\xEDa. ',
        'En el caso del c\xE1ncer de mama puede ayudar a ':'En el caso del c\xE1ncer de mama puede ayudar a ',
        'determinar la agresividad de un tumor o la ':'determinar la agresividad de un tumor o la ',
        'probabilidad que un determinado tratamiento ':'probabilidad que un determinado tratamiento ',
        'mejore el resultado, permitiendo escoger el plan de ':'mejore el resultado, permitiendo escoger el plan de ',
        'cuidado adecuado para el paciente.':'cuidado adecuado para el paciente.',
        'Gen\xE9tica ':'Gen\xE9tica ',
        'Esto significa que si usted tiene un historial familiar ':'Esto significa que si usted tiene un historial familiar ',
        'marcado de c\xE1ncer de mama, es probable que haya ':'marcado de c\xE1ncer de mama, es probable que haya ',
        'heredado un gen anormal vinculado a un mayor riesgo ':'heredado un gen anormal vinculado a un mayor riesgo ',
        'de sufrir c\xE1ncer de mama (como las mutaciones de los ':'de sufrir c\xE1ncer de mama (como las mutaciones de los ',
        'genes BRCA 1 o 2). ':'genes BRCA 1 o 2). ',
        'Son las pruebas base de medicina de precisi\xF3n o ':'Son las pruebas base de medicina de precisi\xF3n o ',
        'medicina personalizada, ya que cada individuo es ':'medicina personalizada, ya que cada individuo es ',
        'diferente ':'diferente ',
        'Gu\xEDan al profesional en la elecci\xF3n del tratamiento ':'Gu\xEDan al profesional en la elecci\xF3n del tratamiento ',
        'm\xE1s adecuado para el paciente. ':'m\xE1s adecuado para el paciente. ',
        'Permite calcular el riesgo de recurrencia. ':'Permite calcular el riesgo de recurrencia. ',
        'Muestra un beneficio real para el paciente y su ':'Muestra un beneficio real para el paciente y su ',
        'familia. ':'familia. ',
        'Se pueden analizar m\xE1s de 3.800 enfermedades. ':'Se pueden analizar m\xE1s de 3.800 enfermedades. ',
        'Determinan calidad de vida del paciente.':'Determinan calidad de vida del paciente.',
        'INSTRUMENTAL Y':'INSTRUMENTAL Y',
        'ESTERILIZACIÓN':'ESTERILIZACIÓN',
        'Con un completo portafolio de instrumentos para las diferentes ':'Con un completo portafolio de instrumentos para las diferentes ',
        'especialidades quir\xFArgicas, y la interacci\xF3n entre los productos y ':'especialidades quir\xFArgicas, y la interacci\xF3n entre los productos y ',
        'los procesos de lavado y esterilizaci\xF3n.':'los procesos de lavado y esterilizaci\xF3n.',
        
        'nuestro equipo profesional est\xE1 presto a ':'nuestro equipo profesional est\xE1 presto a ',
        'brindar informaci\xF3n, asesor\xEDa, ':'brindar informaci\xF3n, asesor\xEDa, ',
        'acompa\xF1amiento, sugerencias y soluciones ':'acompa\xF1amiento, sugerencias y soluciones ',
        'a sus planes y proyectos con:':'a sus planes y proyectos con:',
        'Siempre enfocados en optimizar y preservar ':'Siempre enfocados en optimizar y preservar ',
        'su inversi\xF3n. ':'su inversi\xF3n. ',
        'Instrumental quir\xFArgico y su ':'Instrumental quir\xFArgico y su ',
        'mantenimiento -2': 'mantenimiento -2',
        'Contenedores ecol\xF3gicos ':'Contenedores ecol\xF3gicos ',
        'Equipos para lavado y ':'Equipos para lavado y ',
        'termodesinfecci\xF3n ':'termodesinfecci\xF3n ',
        'Equipos para esterilizaci\xF3n ': 'Equipos para esterilizaci\xF3n ',
        'CUIDADO AVANZADO DE': 'CUIDADO AVANZADO',
        'HERIDAS':'DE HERIDAS',
        'Ofrecemos una l\xEDnea de ap\xF3sitos avanzados y terapia de presi\xF3n ':'Ofrecemos una l\xEDnea de ap\xF3sitos avanzados y terapia de presi\xF3n ',
        'negativa para el tratamiento integral de heridas agudas, cr\xF3nicas y ':'negativa para el tratamiento integral de heridas agudas, cr\xF3nicas y ',
        'complejas, como las ocasionadas por pie diab\xE9tico, lesiones por ':'complejas, como las ocasionadas por pie diab\xE9tico, lesiones por ',
        'presi\xF3n y lesiones de origen vascular.':'presi\xF3n y lesiones de origen vascular.',
        'CUIDADO':'CUIDADO',
        'AVANZADO':'AVANZADO',
        'DE HERIDAS':'DE HERIDAS',
        'Nuestro compromiso es asesorar a los profesionales ':'Nuestro compromiso es asesorar a los profesionales ',
        'de la salud en el uso, manejo y aprovechamiento ':'de la salud en el uso, manejo y aprovechamiento ',
        'correcto de dichas tecnolog\xEDas. ':'correcto de dichas tecnolog\xEDas. ',
        'Ayudando al paciente a mejorar su ':'Ayudando al paciente a mejorar su ',
        'Soluciones simples y efectivas ':'Soluciones simples y efectivas ',
        'Aceleraci\xF3n del proceso de cicatrizaci\xF3n  ':'Aceleraci\xF3n del proceso de cicatrizaci\xF3n  ',
        'Estimulaci\xF3n de la formaci\xF3n de nuevo tejido  ':'Estimulaci\xF3n de la formaci\xF3n de nuevo tejido  ',
        'Prevenci\xF3n de infecciones ':'Prevenci\xF3n de infecciones ',
        'NEUROINTERVENCIONISMO ': 'NEUROINTERVENCIONISMO ',
        'El ACV - Accidente Cerebro Vascular es algo que le puede ocurrir a ':'El ACV - Accidente Cerebro Vascular es algo que le puede ocurrir a ',
        '1 de cada 4 personas y menos del 5% de las personas que lo sufren ':'1 de cada 4 personas y menos del 5% de las personas que lo sufren ',
        'son atendidas a tiempo.':'son atendidas a tiempo.', 
        'La ciencia viene evolucionando en forma r\xE1pida para identificar y tratar esta patolog\xEDa conocida como ictus, ':'La ciencia viene evolucionando en forma r\xE1pida para identificar y tratar esta patolog\xEDa conocida como ictus, ',
        'dividido entre isqu\xE9mico (trombo) y hemorr\xE1gico (aneurisma), donde el neurointervencionismo como disciplina ':'dividido entre isqu\xE9mico (trombo) y hemorr\xE1gico (aneurisma), donde el neurointervencionismo como disciplina ',
        'joven se va consolidando como alternativa para determinados casos. ':'joven se va consolidando como alternativa para determinados casos. ',
        'Nuestro portafolio es reconocido por su calidad y renombre, con fortalezas en opciones de ACCESO, una gran ':'Nuestro portafolio es reconocido por su calidad y renombre, con fortalezas en opciones de ACCESO, una gran ',
        'variedad de Coils y una tecnolog\xEDa \xFAnica para trombectom\xEDa, incluyendo una estructura de log\xEDstica inversa que ':'variedad de Coils y una tecnolog\xEDa \xFAnica para trombectom\xEDa, incluyendo una estructura de log\xEDstica inversa que ',
        'nos permite asegurar un servicio de alta calidad para los requerimientos de tiempo y disponibilidad en las ':'nos permite asegurar un servicio de alta calidad para los requerimientos de tiempo y disponibilidad en las ',
        'ventanas terap\xE9uticas. ': 'ventanas terap\xE9uticas. ',
        'Trauma ':'Trauma ',
        'Reemplazo articular ':'Reemplazo articular ',
        'Artroscopia ':'Artroscopia ',
        'Cráneo y maxilofacial ': 'Cráneo y maxilofacial ',
        '\xC9TICA Y COMPLIANCE':'\xC9TICA Y COMPLIANCE',
        'C\xF3digo de':'C\xF3digo de',
        '\xE9tica':'\xE9tica',
        'L\xEDnea ':  'L\xEDnea ',
        'ética ': 'ética ',
        'C\xF3digo de \xE9tica': 'C\xF3digo de \xE9tica',
        'En el Grupo Amarey estamos comprometidos en mantener relaciones honestas, ':'En el Grupo Amarey estamos comprometidos en mantener relaciones honestas, ',
        'constructivas e id\xF3neas con nuestros grupos de inter\xE9s. Guiados por nuestros ': 'constructivas e id\xF3neas con nuestros grupos de inter\xE9s. Guiados por nuestros ',
        'valores corporativos hemos creado el presente C\xF3digo de \xE9tica, a trav\xE9s del ': 'valores corporativos hemos creado el presente C\xF3digo de \xE9tica, a trav\xE9s del ',
        'cual establecemos las reglas de conducta \xE9tica que deben ser seguidas y ':'cual establecemos las reglas de conducta \xE9tica que deben ser seguidas y ',
        'observadas en su comportamiento diario por todas las personas vinculadas a ':'observadas en su comportamiento diario por todas las personas vinculadas a ',
        'la empresa, tanto los miembros de la junta directiva, directores, empleados de ':'la empresa, tanto los miembros de la junta directiva, directores, empleados de ',
        'todos los niveles, proveedores, asesores y contratistas, as\xED como para los ':'todos los niveles, proveedores, asesores y contratistas, as\xED como para los ',
        'empleados indirectos.':'empleados indirectos.',
        'Ver C\xF3digo':'Ver C\xF3digo',
        'de \xE9tica':'de \xE9tica',
        'Ver Manual de': 'Ver Manual de',
        'Compliance':'Compliance',
        'L\xEDnea \xE9tica': 'L\xEDnea \xE9tica',
        'La l\xEDnea \xE9tica es un canal dise\xF1ado para que empleados, proveedores, aliados ':'La l\xEDnea \xE9tica es un canal dise\xF1ado para que empleados, proveedores, aliados ',
        'estrat\xE9gicos y clientes puedan reportar de forma an\xF3nima y confidencial ':'estrat\xE9gicos y clientes puedan reportar de forma an\xF3nima y confidencial ',
        'presuntas violaciones a nuestro C\xF3digo de \xE9tica, as\xED como posibles actos ':'presuntas violaciones a nuestro C\xF3digo de \xE9tica, as\xED como posibles actos ',
        'incorrectos, fraudes, corrupci\xF3n, acoso y otros aspectos que afecten la ':'incorrectos, fraudes, corrupci\xF3n, acoso y otros aspectos que afecten la ',
        'imagen y buen nombre de la compa\xF1\xEDa.':'imagen y buen nombre de la compa\xF1\xEDa.',
        'Las denuncias pueden realizarse a trav\xE9s del correo electr\xF3nico los 7 d\xEDas de la ':'Las denuncias pueden realizarse a trav\xE9s del correo electr\xF3nico los 7 d\xEDas de la ',
        'semana, las 24 horas del d\xEDa.': 'semana, las 24 horas del d\xEDa.',
        '\xBFC\xF3mo puedo comunicarme con la l\xEDnea \xE9tica?': '\xBFC\xF3mo puedo comunicarme con la l\xEDnea \xE9tica?',
        'A trav\xE9s del correo electr\xF3nico: linea.etica@grupoamarey.com': 'A trav\xE9s del correo electr\xF3nico: linea.etica@grupoamarey.com',
        '\xBFCu\xE1ndo debo utilizar la l\xEDnea \xE9tica?':'\xBFCu\xE1ndo debo utilizar la l\xEDnea \xE9tica?', 
        '¿Cuándo debo utilizar la línea ética? ':'¿Cuándo debo utilizar la línea ética?',
        'Cuando identifique situaciones no \xE9ticas que puedan afectar los intereses del Grupo ':'Cuando identifique situaciones no \xE9ticas que puedan afectar los intereses del Grupo ',
        'Amarey como:':'Amarey como:',
        'Conflicto de interés':'Conflicto de interés',
        'Lavado de activos':'Lavado de activos',
        'D\xE1divas o prebendas':'D\xE1divas o prebendas',
        'Incumplimiento de leyes ':'Incumplimiento de leyes ',
        'y regulaciones':'y regulaciones',
        'Actividades inapropiadas': 'Actividades inapropiadas',
        'Confidencialidad':'Confidencialidad',
        'Pr\xE1cticas comerciales ':'Pr\xE1cticas comerciales ',
        'inadecuadas':'inadecuadas',
        'Conflicto de interés ':'Conflicto de interés',
        'La situación en virtud de la cual una persona, por su actividad o ':'La situación en virtud de la cual una persona, por su actividad o ',
        "cargo, o por la información que posee, se enfrenta a distintas ":  "cargo, o por la información que posee, se enfrenta a distintas ",
        "alternativas de conducta en relación con intereses incompatibles, ":"alternativas de conducta en relación con intereses incompatibles, ",
        "siendo incorrecto privilegiar alguno de ellos, pues se vulneraría o" :"siendo incorrecto privilegiar alguno de ellos, pues se vulneraría o",
        "debilitaría injustamente la posición del otro.":"debilitaría injustamente la posición del otro.",
        'Incumplimiento de leyes y regulaciones': 'Incumplimiento de leyes y regulaciones',
        'Negocios de la compañía que no se realicen dentro del marco legal ':'Negocios de la compañía que no se realicen dentro del marco legal ',
        'y/o que sus colaboradores no obren dentro de este. Incumplimiento ': 'y/o que sus colaboradores no obren dentro de este. Incumplimiento ',
        'a lo dispuesto en el código de ética, reglamento interno de trabajo, ':'a lo dispuesto en el código de ética, reglamento interno de trabajo, ',
        'políticas de contratación y pago a terceros, reglamentos, políticas ':'políticas de contratación y pago a terceros, reglamentos, políticas ',
        'y/o procedimientos específicos establecidos por el Grupo Amarey.':'y/o procedimientos específicos establecidos por el Grupo Amarey.',
        "Los empleados utilizarán la información no pública que sea de su": "Los empleados utilizarán la información no pública que sea de su",
        "conocimiento, para realizar las actividades que desempeñen, de":"conocimiento, para realizar las actividades que desempeñen, de",
        "manera confidencial y reservada, y se abstendrán en todo":"manera confidencial y reservada, y se abstendrán en todo",
        'momento de obtener o recibir beneficios personales o provocar ': 'momento de obtener o recibir beneficios personales o provocar ',
        "perjuicios a la empresa por su divulgación o utilización indebida.":"perjuicios a la empresa por su divulgación o utilización indebida.",
        'Son operaciones que busca dar apariencia de legalidad a recursos ':'Son operaciones que busca dar apariencia de legalidad a recursos ',
        'provenientes de actividades ilícitas, a través de diversas':'provenientes de actividades ilícitas, a través de diversas',
        'transacciones ocultando así el origen y trayectoria de los recursos.':'transacciones ocultando así el origen y trayectoria de los recursos.',
        'La relación entre los empleados del Grupo Amarey deberá estar':'La relación entre los empleados del Grupo Amarey deberá estar',
        'siempre basada en el debido y mutuo respeto, a fin de asegurar un': 'siempre basada en el debido y mutuo respeto, a fin de asegurar un',
        'ambiente armonioso conducente al trabajo productivo.': 'ambiente armonioso conducente al trabajo productivo.',
        'Prácticas comerciales inadecuadas':'Prácticas comerciales inadecuadas',
        'En la relación con los clientes de Grupo Amarey, los empleados de la':'En la relación con los clientes de Grupo Amarey, los empleados de la',
        'organización siempre deberán actuar con objetividad,':'organización siempre deberán actuar con objetividad,',
        'imparcialidad, equidad, excelencia y de acuerdo con los objetivos':'imparcialidad, equidad, excelencia y de acuerdo con los objetivos',
        'de imagen, crecimiento y rentabilidad de la empresa.':'de imagen, crecimiento y rentabilidad de la empresa.', 
        "Son todas aquellas ventajas, artículos o incentivos en dinero o":"Son todas aquellas ventajas, artículos o incentivos en dinero o",
        'especie que se entregan a terceros (cualquier relacionado con ':'especie que se entregan a terceros (cualquier relacionado con ',
        "compra, distribución, comercialización, prescripción y dispensación":"compra, distribución, comercialización, prescripción y dispensación",
        "de fármacos, dispositivos médicos o insumos de salud), con el":"de fármacos, dispositivos médicos o insumos de salud), con el",
        'propósito de obtener beneficios comerciales que no se ajustan a':'propósito de obtener beneficios comerciales que no se ajustan a',
        'los principios de la promoción ética, de la competencia leal y del':'los principios de la promoción ética, de la competencia leal y del',
        'respeto por los principios, la moral y el buen actuar.':'respeto por los principios, la moral y el buen actuar.',
        'Juntos trabajamos por alcanzar ':'Juntos trabajamos por alcanzar ',
        'nuestro prop\xF3sito de un compromiso ':'nuestro prop\xF3sito de un compromiso ',
        'inquebrantable con la salud y la vida.':'inquebrantable con la salud y la vida.',
        '¿Por qué unirte a nosotros?': '¿Por qué unirte a nosotros?',
        'Como unirse?': 'Como unirse?',
        '\xA1Esperamos conocerte pronto!':'\xA1Esperamos conocerte pronto!',
        'Cada d\xEDa nos esforzamos por el bienestar de nuestros colaboradores. Somos ':'Cada d\xEDa nos esforzamos por el bienestar de nuestros colaboradores. Somos ',
        'una empresa de ':'una empresa de ',
        'capital colombiano':'capital colombiano',
        ', comprometida con la salud y dedicada a ':', comprometida con la salud y dedicada a ',
        'ampliar las oportunidades de vida.':'ampliar las oportunidades de vida.',
        'En Grupo Amarey, sabemos que el talento excepcional es la clave para hacer ':'En Grupo Amarey, sabemos que el talento excepcional es la clave para hacer ',
        'cosas incre\xEDbles y ': 'cosas incre\xEDbles y ',
        'alcanzar resultados extraordinarios. ':'alcanzar resultados extraordinarios. ',
        'Generamos ':'Generamos ',
        'entornos de trabajo inclusivos, colaborativos, ':'entornos de trabajo inclusivos, colaborativos, ',
        'retadores y estimulantes':'retadores y estimulantes',
        ' donde cada colaborador puede aportar, crecer y contribuir de manera ':' donde cada colaborador puede aportar, crecer y contribuir de manera ',
        'significativa al':'significativa al',
        ' logro de nuestros objetivos.':' logro de nuestros objetivos.',
        'Si est\xE1s interesado en formar parte del Grupo Amarey, te invitamos a enviar tu hoja de ':'Si est\xE1s interesado en formar parte del Grupo Amarey, te invitamos a enviar tu hoja de ',
        'vida a ':'vida a ',
        'atracciondetalento@grupoamarey.com':'atracciondetalento@grupoamarey.com',
        ', indicando la ciudad donde vives.':', indicando la ciudad donde vives.',
        'Estamos en b\xFAsqueda de personas ':'Estamos en b\xFAsqueda de personas ',
        'apasionadas y comprometidas,':'apasionadas y comprometidas,',
        ' que ':' que ',
        'compartan y reflejen ':'compartan y reflejen ',
        'nuestros valores,':'nuestros valores,',
        ' alineados con ':' alineados con ',
        'la ':'la ',
        'filosof\xEDa SER':'filosof\xEDa SER',
        ' (Servicio, Educaci\xF3n y Relacionamiento)':' (Servicio, Educaci\xF3n y Relacionamiento)',
        ' y est\xE9n ':' y est\xE9n ',
        'dispuestos a asumir retos diarios y llevar la Organizaci\xF3n a otros niveles. ':'dispuestos a asumir retos diarios y llevar la Organizaci\xF3n a otros niveles. ',
        'CONTÁCTENOS':'CONTÁCTENOS',
        'Línea de atención al cliente 018000 180066 ': 'Línea de atención al cliente 018000 180066 ',
        'PBX: 6017447300':'PBX: 6017447300',
        'PQR: servicioalcliente@grupoamarey.com-item-1827':'PQR: servicioalcliente@grupoamarey.com-item-1827',
        'Atención de pedidos:':'Atención de pedidos:',
        '       atención.cliente@grupamarey.com':'       atención.cliente@grupamarey.com',
        '       ciac.ucipharma@grupoamarey.com':'       ciac.ucipharma@grupoamarey.com',
        'Carrera 7 # 99-53, pisos 19 y 20 ':'Carrera 7 # 99-53, pisos 19 y 20 ',
        'Formulario de contacto':'Formulario de contacto',
        'Nombre completo ':'Nombre completo ',
        'Email ': 'Email ',
        'Instituci\xF3n ':'Instituci\xF3n ',
        'Asunto ':'Asunto ',
        'Pa\xEDs ':'Pa\xEDs ',
        'Tel\xE9fono ':'Tel\xE9fono ',
        'Mensaje ':'Mensaje ',
        'Al enviar este formulario, usted reconoce haber le\xEDdo y estar de acuerdo con nuestra pol\xEDtica ':'Al enviar este formulario, usted reconoce haber le\xEDdo y estar de acuerdo con nuestra pol\xEDtica ',
        'de ':'de ',
        'Tratamiento de datos personales.':'Tratamiento de datos personales.',
        'enviar':'enviar',
        'Prográmese con ':'Prográmese con ',
        'Grupo Amarey ': 'Grupo Amarey ',
        "Mayo 2024":"Mayo 2024",
        "Do":"Do",
        "Lu":"Lu",
        "Ma":"Ma",
        "Mi":"Mi",
        "Ju":"Ju",
        "Vi":"Vi",
        "Sa":"Sa",
        "Fecha de hoy":"Fecha de hoy",
        "Fecha evento":"Fecha evento",
        "16 de mayo de 2024": "16 de mayo de 2024",
        "II Congreso Iberoamericano":"II Congreso Iberoamericano",
        "De Esterilización y II Congreso":"De Esterilización y II Congreso",
        "nacional de reprocesamiento":"nacional de reprocesamiento",
        "de dispositivos médicos":"de dispositivos médicos",
        "Cartagena 16 y 18 de mayo Hotel las Américas":"Cartagena 16 y 18 de mayo Hotel las Américas",
        '02 de mayo de 2024':'02 de mayo de 2024',
        '03 de febrero de 2024':'03 de febrero de 2024',
        '06 de Marzo de 2024':'06 de Marzo de 2024',
        '09 de febrero de 2024':'09 de febrero de 2024',
        '10 de febrero de 2024': '10 de febrero de 2024',
        '29 de febrero de 2024':'29 de febrero de 2024',
        '07 de marzo de 2024':'07 de marzo de 2024',
        '08 de marzo de 2024': '08 de marzo de 2024',
        '09 de marzo de 2024':'09 de marzo de 2024',
        '15 de marzo de 2024': '15 de marzo de 2024',
        '04 de mayo de 2024':'04 de mayo de 2024',
        "18 de mayo de 2024":"18 de mayo de 2024",
        "22 de mayo de 2024":"22 de mayo de 2024",
        "23 de mayo de 2024":"23 de mayo de 2024",
        "24 de mayo de 2024":"24 de mayo de 2024",
        "25 de mayo de 2024":"25 de mayo de 2024",
        '30 de mayo de 2024':'30 de mayo de 2024',
        '31 de mayo de 2024': '31 de mayo de 2024',
        'Junio':'Junio',
        'Junio 2024': 'Junio 2024',
        "Julio":"Julio",
        'July 2024':'July 2024',
        'Mayo 2024 ':'Mayo 2024',
        'Agosto':'Agosto',
        'Agosto 2024': 'Agosto 2024',
        "Septiembre":"Septiembre",
        "Septiembre 2024": "Septiembre 2024",
        "Octubre": "Octubre",
        "Octubre 2024": "Octubre 2024",
        "Noviembre": "Noviembre",
        "Noviembre 2024": "Noviembre 2024",
        "Diciembre":"Diciembre",
        "Diciembre 2024": "Diciembre 2024",
        "Enero":"Enero",
        "Enero 2025": "Enero 2025",
        'Febrero 2024': 'Febrero 2024',
        'Marzo 2024': 'Marzo 2024',
        'Trayectoria': 'Trayectoria',
        'Home': 'Home',
        'Soluciones': 'Soluciones',
        'en salud\u200B':'en salud\u200B',
        'Actualidad\u200B':'Actualidad\u200B',
        '\xC9tica y':'\xC9tica y',
        'compliance\u200B':'compliance\u200B',
        'Trabaje':'Trabaje',
        'con nosotros':'con nosotros',
        'Cont\xE1ctenos': 'Cont\xE1ctenos',
        'rse': 'rse',
        'Nuestros ALIADOS': 'Nuestros ALIADOS',
        'soluciones EN SALUD': 'soluciones EN SALUD',
        'nOTICIAS':'nOTICIAS',
        'eVENTOS': 'eVENTOS',
        'tRAYECTORIA': 'tRAYECTORIA',
        'soluciones':'soluciones',
        'EN SALUD':'EN SALUD',
        'aCTUALIDAD': 'aCTUALIDAD',
        'ETICA Y':'ETICA Y',
        'COMPLIANCE':'COMPLIANCE',
        'Descubra ':'Descubra ',
        'nuestro compromiso inquebrantable ':'nuestro compromiso inquebrantable ',
        'con la \xE9tica y el cumplimiento normativo.':'con la \xE9tica y el cumplimiento normativo.',
        'TRABAJE CON':'TRABAJE CON',
        'NOSOTROS':'NOSOTROS',
        'Sea parte de nuestro talentoso y comprometido equipo.':'Sea parte de nuestro talentoso y comprometido equipo.',
        'Encuentre oportunidades de carrera. \u200B':'Encuentre oportunidades de carrera. \u200B',
        'CONTACTENOS':'CONTACTENOS',

        '\xBFTiene alguna pregunta o necesita ': '\xBFTiene alguna pregunta o necesita ',
        'informaci\xF3n adicional?':'informaci\xF3n adicional?',
        'Cont\xE1ctenos y descubra c\xF3mo podemos ayudarle.\u200B':'Cont\xE1ctenos y descubra c\xF3mo podemos ayudarle.\u200B',
        ...spanishTextsMobile
    }
}

//english
const englishTexts = {
    translation: {
        'Portafolio ': 'Portfolio',
        'Equipos Médicos': 'Medical equipment',
        "Conoce Más": "LEARN MORE",
        "Un compromiso inquebrantable": "an unwavering commitment",
        'con la salud y la vida': 'to health and life',
        'El origen de nuestra historia es hoy': 'Our origin story is now the',
        'la esencia de nuestra marca': 'essence of our brand',
        'Conoce más de': 'Learn more about',
        'Grupo Amarey dando clic': 'Amarey Group by clicking',
        'en cada uno': 'on each one',
        'ROBOT DAVINCI': 'da Vinci Robot',
        'Llevando la precisión quirúrgica más allá de los límites de': 'Taking surgical precision beyond the limits of our',
        'nuestras manos': 'hands',
        'Conoce mas': 'Click to learn more',
        'desarrollo de tecnología en salud': ' research and development.',
        'Representamos las marcas de mayor': 'We represent the most prestigious',
        'prestigio a nivel mundial en investigación y': 'brands worldwide in health technology',
        'Conoce más': 'Click to view our portfolio',
        'Cifras de': 'Impact',
        'impacto': 'figures',
        'profesionales capacitados': 'professionals trained',
        'cirugías robóticas realizadas': 'robotic surgeries were performed',
        'con el modelo de logística hospitalaria': 'with the Amarey hospital logistics model',
        'pacientes atendidos': 'patients treated',
        'Servicio': 'Service',
        'El servicio es uno de los pilares del Grupo Amarey, una misión': 'Service is one of the pillars of Amarey Group, a mission',
        'que se ha convertido en la vocación de la compañía para': 'that has become the company`s vocation to fulfill the',
        'cumplir el compromiso de ser un actor determinante en la': 'commitment to be a decisive actor in the work of caring',
        'labor de cuidar y salvar vidas.': 'for and saving lives.',
        'Grupo Amarey, la cadena que salva vidas.': 'Amarey Group, the life-saving chain.',
        'NUESTROS CLIENTES,': 'Our clients, our',
        'NUESTRA RAZÓN DE SER': 'purpose for being:',
        'Resultados medición de': 'Experience and loyalty',
        'experiencia y lealtad (NPS) 2023:': 'measurement results (NPS) for 2023:',
        'SATISFACCIÓN ': 'Experience',
        'COBERTURA': 'coverage',
        'CEDIS en ciudades principales': 'CEDIS in main cities',
        'CEDIS en ciudades intermedias': 'CEDIS in intermediate cities',
        'Servicio - 3': 'World-class',
        'logístico de': 'logistics',
        'clase mundial': 'service',
        'Nuestra red en': 'Our distribution',
        'distribución incluye': 'network includes',
        'transportadoras que': 'carriers that allow us',
        'nos permiten llegar a': 'to reach any part of',
        'cualquier parte del país': "the country.",
        'Entregas de urgencias en': 'Emergency the',
        'máximo 4 horas': '4-hour deliveries',
        ' en las ': ' are available in',
        'principales ciudades': 'the country’s main cities.',
        'Pedidos electrónicos': 'Electronic orders',
        'CRM Salesforce': 'Salesforce CRM',
        'SAP S/4HANA': 'SAP S/4HANA',
        'Trazabilidad y': 'Cold Chain',
        'monitoreo de la': 'Traceability',
        'cadena de frío': 'and Monitoring',
        'Seguimiento por': 'GPS tracking',
        'GPS para garantizar': 'to guarantee',
        'la entrega': 'deliveries',
        'Equipo logístico de': 'A 91-person logistics',
        '91 personas en': 'team available in',
        'Colombia, Panamá y': 'Colombia, Panama,',
        'Costa Rica': 'and Costa Rica',
        'PORTAFOLIO': 'Portfolio',
        'Somos un grupo empresarial con una clara': 'We are a business group with a clear',
        'inspiraci\xF3n profesional y humana; ofrecemos': 'professional and humane inspiration, offering',
        'un portafolio de soluciones en salud de la m\xE1s': 'a high quality, patient-care-focused health',
        'alta calidad para el cuidado de los pacientes.': 'solutions portfolio',
        'Carrera 7 # 99-53, pisos 19 y 20': 'Carrera 7 # 99-53, 19th and 20th floors',
        'L\xEDnea de atenci\xF3n al cliente 018000 180066 - (601) 7447300': 'Customer service line 018000 180066 - (601) 7447300',
        'Reporte de eventos adversos o PQR: servicioalcliente@grupoamarey.c-5om': 'Report adverse events or Claims: servicioalcliente@grupoamarey.com',
        'Línea ética: linea.etica@grupoamarey.c-5om': 'Ethics line: linea.etica@grupoamarey.com',
        'Tratamiento de datos personales': 'Personal Data Management',
        'Pol\xEDtica de protecci\xF3n de datos personales': 'Personal Data Protection Policy',
        'NUESTRA HISTORIA': 'OUR HISTORY',
        'Grupo Amarey es una compañía con más de 40 años de': 'Grupo Amarey is a company with over 40 years of experience,',
        'trayectoria, comprometida con acercar el conocimiento, la': 'dedicated to bringing knowledge,',
        'ciencia y la tecnolog\xEDa al sector de la salud en Colombia,': 'science, and technology to healthcare sector s in Colombia,',
        'Panam\xE1 y Costa Rica.': 'Panama, and Costa Rica.',
        'Con 6 sedes en Colombia y operaci\xF3n internacional en': 'With six different facilities in Colombia and international operations in',
        'Panam\xE1 y Costa Rica, el grupo empresarial cuenta con un': 'both Panama and Costa Rica, our business group has a',
        'equipo de colaboradores que trabajan bajo una \xFAnica premisa,': ' team of collaborators all work ing under a single premise:',
        '\u201Cun compromiso inquebrantable con': '\u201Can unwavering commitment to',
        'la salud y la vida\u201D.': 'health and life.\u201D',
        'Nace Amarey Nova Medical al': 'Amarey Nova Medical is',
        'obtener la distribuci\xF3n de la': 'established after securing',
        'inmunoglobulina humana IgG': 'the distribution rights for',
        'enriquecida, que le dio vida al': 'enriched human',
        'pent\xE1mero que ha sido el': 'immunoglobulin IgG, which',
        's\xEDmbolo de la compa\xF1\xEDa.': 'became the company’s logo.',
        'Avanzar': 'Advance', 
        "Se crea la línea respiratoria con": 'A respiratory solutions line is',
        "el lanzamiento de un": 'introduced with the launch of',
        "surfactante pulmonar para el": 'a pulmonary surfactant for',
        "tratamiento del síndrome de": 'treating respiratory distress',
        "distrés respiratorio en neonatos.": 'syndrome in neonates.',
        "Regresar": 'Return',
        "Inicia la distribución de": 'A line for medical surgical',
        "dispositivos e insumos  ": 'surgical devices and',
        "médico-quirúrgicos.": 'supply distribution is born.',
        "La compañía se expande a ": 'Expansion to Panama and',  
        "Panamá y Costa Rica con la ": 'Costa Rica began with', 
        "distribución del portafolio ": 'distribution of a surgical',
        "quirúrgico y de ortopedia, a ": ' and orthopedic portfolio',
        "través de su marca ": 'under its own international',
        "internacional UCITECH. ": 'brand, UCITECH.',
        // 2009
        'Se amplía el portafolio de ': 'Our product portfolio',
        'productos con las líneas de ': 'expands to include Stryker',
        'implantes ortopédicos de Stryker, ': 'orthopedic implants,',
        'equipos médicos, pruebas ': ' medical equipment,',
        'diagnósticas oncológicas y ': 'and advanced wound care.',
        'cuidado avanzado de heridas. ': '',
        // 2011
        "Se crea la línea de apoyo en ": 'The biomedical technology',
        "gestión de tecnología ": 'management support line',
        "biomédica en el modelo inhouse ": 'is established along with in',
        "y servicio de mantenimiento ": 'house biomedical',
        "biomédico.": 'maintenance services.',
        // 2011 - 2
        'Grupo Amarey obtiene la ': 'Amarey Group secures',
        'representación del sistema ': 'representation of the da',
        'robótico da Vinci en Colombia ': 'Vinci robotic system in',
        'para cirugía mínimamente ': 'Colombia for minimally',
        'invasiva.': 'invasive surgery.',
        // 
        'Nihon Kohden': 'Partnership with Nihon Kohden is initiated.',
        "Se inauguran cinco sedes ": 'Five company owned',
        "propias en Bogotá, Cali, ": 'facilities are inaugurated',
        "Medellín, Barranquilla y Pereira.": 'in Bogotá, Cali, Medellín, Barranquilla, and Pereira.',
        "Obtiene el Premio Colombiano ": 'Amarey wins the Colombian ',
        "a la Calidad de la Gestión.": 'Award for Management Quality.',
        // 2014 - 2
        'Inicia la distribución de ': 'The distribution of',
        'productos para logística ': 'products for hospital ',
        'hospitalaria. ': 'logistics begins.',
        'Los Premios Portafolio otorgan ': 'Colombia’s Portfolio Awards',
        'la Mención de Honor a Amarey ': 'grant Honorable Mention to',
        'Nova Medical en la categoría ': 'Amarey Nova Medical in the',
        'de Servicio al cliente.': 'Customer Service category.',
        'A lo largo de su existencia el ': 'Amarey Group launches its',
        'Grupo Amarey ha crecido ': 'first own brand antibiotic',
        'positivamente con los más altos ': 'solution, marking positive',
        'estándares de calidad e ': ' growth throughout its',
        'innovación, por eso decide ': 'existence with the highest',
        'lanzar al mercado el primer ': 'standards of quality and',
        'antibiótico con marca propia.': 'innovation.',
        'El portafolio del Grupo Amarey ': 'The Amarey Group expands its',
        'sigue creciendo con la ': 'portfolio with representation of',
        'representación de KLS Martin ': 'KLS Martin for craniomaxillofacial',
        'para ortopedia cráneo ': 'orthopedics, instruments, and',
        'maxilofacial, instrumental y ': 'sterilization.',
        'esterilización.': '',
        // 2023 - 2
        'Se crea la línea de ': 'The neurointerventional',
        'neurointervencionismo con ': 'line is established with',
        'la llegada del portafolio de ': 'the introduction of the',
        'CERENOVUS para el ': 'CERENOVUS portfolio',
        'tratamiento de ACV.': 'for stroke treatment.',

        'Grupo Amarey renueva su ': 'Grupo Amarey undergoes brand',
        'identidad de marca, logrando ': 'identity renewal, aligning it with',
        'que refleje los valores, la cultura ': 'the companys values, culture,',
        'y la misión de la compañía.': 'and mission.',
        'Se inaugura el Centro de ': 'The Logistics Distribution ',
        'Distribución Logística – CEDI en ': 'Center CEDI is inaugurated in ',
        'la zona industrial de Montevideo ': 'the industrial zone of ',
        'en Bogotá, con el objetivo de ': 'Montevideo in Bogotá, aiming',
        'lograr mejores niveles de servicio ': 'to enhance logistics service ',
        'logístico para los clientes.': 'levels for customers.',
        'Misión': 'Mission',
        'Somos un grupo empresarial orgullosamente colombiano ': 'We are a proudly Colombian business group that',
        'que representa productos y servicios de calidad guiados ': ' distribute quality products and services, guided by our',
        'por nuestra filosofía SER (Servicio, Educación, ': 'SER philosophy (Service, Education, Relationships).',
        'Relacionamiento).': '',
        'FILOSOFÍA': 'PHILOSOPHY ',
        'Generamos valor a los pacientes, sus familias, instituciones ': 'We generate value for patients, their families, health',
        'de salud y accionistas con un talento humano excepcional, ': 'institutions and shareholders with exceptional human',
        'con sentido ético y altamente comprometido con la salud ': 'talent, with an ethical sense and highly committed to',
        'y la vida.': "health and life.",
        'En el 2025 el Grupo Amarey será el aliado preferido de ': 'In 2025, the Amarey Group will be the preferred ally of',
        'las instituciones de salud y de los fabricantes de ': 'health institutions and manufacturers of specialized',
        'productos especializados, en Colombia y los países del ': 'health products, in Colombia and Latin America.',
        'área andina y Centroamérica.': '',
        'Esto será posible por nuestra trayectoria, reputación, por ': 'This will be possible due to our track record, reputation,',
        'cumplir lo que prometemos y por la capacidad para ': 'for delivering what we promise and for our ability to',
        'ofrecer soluciones integrales innovadoras.': 'offer innovative comprehensive solutions.',
        'Valores': 'Core values ',
        'Respeto y calidez': 'Respect and warmth',
        'Valoramos y respetamos el ': 'We value and respect the point',
        'punto de vista, el saber y el ': 'of view, knowledge and work of',
        'quehacer de otros. Nos ': 'others. We are characterized by',
        'caracteriza el relacionamiento ': 'maintaining relationships based',
        'basado en las buenas maneras.': 'on good manners.',
        'Excelencia en calidad': 'Excellence in quality ',
        'Realizamos las cosas con ': 'We do things with quality,',
        'calidad, oportunidad y actitud ': 'opportunity and attitude from',
        'desde el principio, cumpliendo ': 'the beginning, fulfilling the',
        'con la promesa de valor a ': 'promise of value to our ',
        'nuestros clientes y propendiendo ': 'customers and promoting ',
        'con el mejoramiento continuo.': 'continuous improvement. ',
        'Convicción': 'Conviction',
        'Actuamos con conocimiento ': 'We act with in-depth knowledge',
        'profundo sobre los bienes y ': 'about the goods and services ',
        'servicios que le brindamos a la ': 'we provide to the community, ',
        'comunidad, identificándonos ': 'identifying ourselves with the',
        'con la filosofía corporativa.': 'corporate philosophy.',
        'Transparencia': 'Transparency',
        'Nuestras actuaciones siempre ': 'Our actions are always ',
        'se realizan a la luz pública y ': 'carried out in the public light ',
        'están regidas siempre por la ': 'and are always governed by ',
        'buena voluntad, la buena fe y ': 'good will, good faith and ',
        'la orientación a los resultados ': 'oriented to achieve ethical ',
        'éticos y morales.': 'and moral results.',
        'Sentido de responsabilidad': 'Sense of responsibility',
        'con otros': 'with others',
        'Somos sensibles a las necesidades ': 'We are sensitive to the needs of ',
        'de los demás comprometiéndonos ': 'others by committing ourselves to ',
        'con el mejoramiento de su calidad ': 'improving their quality of life and',
        'de vida y bienestar    ': 'well-being.',
        'Filosofía SER': 'SER PHILOSOPHY ',
        'El Grupo Amarey trabaja bajo una única premisa: la filosofía SER ': 'The Amarey Group works under a single premise: the SER ',
        '(Servicio, Educación y Relacionamiento), pilares que marcan la ': 'philosophy (Service, Education and Relationships), pillars that ',
        'diferencia de la compañía en la industria.': 'mark the companys distinction within the industry.',
        'Servicio ': 'SERVICE ',
        'Una compañía con sentido humano, que ofrece un servicio que ': 'We are a company with a humane sense, offering a service that ',
        'trasciende hacia brindar más oportunidades para la vida. ': 'transcends all others by providing more opportunities for life. ',
        'Educación ': 'EDUCATION',
        'Representa el compromiso del Grupo Amarey con la generación de ': 'The Amarey Group is committed to the generation of knowledge ',
        'conocimiento para el personal de la salud, contribuyendo a la ': 'for HCPs, contributing to the construction of a society',
        'construcción de un país que ofrezca mayores beneficios para la ': 'that offers greater benefits for patient’s quality',
        'calidad de vida de los pacientes. ': 'of life.',
        'Relacionamiento ': 'RELATIONSHIPS ',
        'Ha permitido construir la base de confianza y reputación del ': 'It has allowed the Amarey Group to build a solid foundation of ',
        'Grupo Amarey frente a sus públicos de interés. Hoy la compañía es ': 'trust and reputation amongst all its stakeholders. Today the ',
        'reconocida por su integridad, compromiso, excelencia, humanidad ': 'company is recognized for its integrity, commitment, excellence, ',
        'e innovación. ': 'humanity and innovation. ',
        'RECONOCIMIENTOS': 'RECOGNITIONS',
        'Certificaciones': 'Certifications',
        'Premios y reconocimientos': 'Awards and recognitions',
        'Certificado Amarey Nova ': 'ISO 90001 Certification - Amarey',
        'Medical ISO 90001': '',
        'Certificado Ucipharma ': 'ISO 90001',
        'ISO 90001': 'Certification Ucipharma',
        'Certificado Novatécnica Sistema de gestión de ': 'Quality Management System Certificate in Medical',
        'calidad en dispositivos médicos ISO 13485': 'Devices ISO 13485 Novateca.',
        'Certificado Amarey Nova Medical ': 'ISO 9001 Certification - Amarey',
        'Certificación OEA (Operador Económico ': 'Colombia’s DIAN OEA (Authorized Economic ',
        'Autorizado) de la DIAN, obtenida en 2001, ': 'Operator) Certificate obtained in 2001, for',
        'demostrando la seguridad en la cadena de ': 'demonstrating security in the companys ',
        'suministro internacional de la compañía.': 'international supply chain.',
        'RESPONSABILIDAD ': 'CORPORATE SOCIAL',
        'SOCIAL EMPRESARIAL': 'RESPONSIBILITY',
        'La ': 'The',
        ' Fundaci\xF3n OCMAES': ' OCMAES Foundation,',
        ' es una entidad sin \xE1nimo de lucro con ': 'a non profit entity with 18 years of ',
        '18 a\xF1os de trayectoria': 'experience is an integral part of the Amarey Group.',
        ', que hace parte del': 'Its mission ',
        ' Grupo Amarey y ': 'is ',
        'que fue creada con el prop\xF3sito de promover el desarrollo ': 'to foster the human and professional development of',
        'humano y profesional de j\xF3venes colombianos.': 'young Colombians.',
        'Apoya a j\xF3venes con potencial acad\xE9mico y dificultades ': 'The foundation extends support to individuals with academic potential ',
        'econ\xF3micas para acceder a educaci\xF3n superior de calidad, ': 'and financial constraints, enabling them to pursue top tier higher education.',
        'contribuyendo a su formaci\xF3n como l\xEDderes y profesionales ': 'This initiative contributes to their growth as leaders and competitive',
        'competitivos comprometidos con el desarrollo social del pa\xEDs. ': 'professionals dedicated to the social advancement of the nation.',
        'Lo que hace la ': 'The essence of what the',
        'Fundaci\xF3n OCMAES': ' OCMAES Foundation does',
        ' es simple pero profundo:': 'is simple yet profound:',
        'Promueve    ': 'Promotes',
        'el desarrollo ': 'human and ',
        'humano y ': 'country',
        'del pa\xEDs': 'development',
        'Cree en la ': 'Transforming ',
        'transformaci\xF3n ': 'lives',
        'de vidas a ': 'through',
        'trav\xE9s de la ': 'education',
        'educaci\xF3n': '',
        'Por eso otorga ': 'University',
        'becas universitarias ': 'scholarships',
        'a estudiantes con ': 'granted to students ',
        'un alto potencial ': 'with high academic ',
        'acad\xE9mico que se ': 'potential who find',
        'encuentran en ': 'themselves in',
        'circunstancias ': 'difficult economic',
        'econ\xF3micas y ': 'and social ',
        'sociales dif\xEDciles': 'circumstances.',
        '+ DE 4.600 BENEFICIARIOS': '+ 4,600 beneficiaries',
        'a lo largo de sus 18 a\xF1os de trayectoria': 'throughout its 18 years of experience',
        'Conoce más de La Fundación OCMAES': 'Learn more about The OCMAES Foundation',
        'NUESTROS': 'OUR',
        'ALIADOS': 'ALLIES',
        'Europa': 'Europe',
        'Japón': 'Japan',
        'mantenimiento ': 'BIOMEDICAL',
        'biom\xE9dico ': 'MAINTENANCE',
        'Somos la compañía de servicio técnico ': 'We are the largest biomedical technical',
        'biom\xE9dico m\xE1s grande del pa\xEDs y la primera ': 'service company in the country and the first',
        'empresa a nivel nacional en certificarse con ': 'company nationwide to be certified with the',
        'la': 'ICONTEC’s ISO 13485',
        'norma ISO 13485 de ICONTEC': ' ',
        ' para el ': 'standard for technical ',
        'servicio t\xE9cnico de dispositivos m\xE9dicos. ': 'service of medical devices.',
        'MANTENIMIENTO BIOM\xC9DICO ': 'BIOMEDICAL MAINTENANCE',
        'Ofrecemos soluciones de mantenimiento ': 'We offer biomedical maintenance solutions',
        'biom\xE9dico': 'for all actors in the health sector of our',
        ' para todos los actores del ': 'markets.',
        'sector salud. Nuestro apoyo es ' : 'Our support is executed with thorough training,',
        'ejecutado con entrenamiento, ': 'ample experience, and innovation across both',
        'experiencia e innovaci\xF3n en los procesos ': 'human and technical processes.',
        'humanos y t\xE9cnicos.  ': '',
        'Nuestro modelo de ': 'Our in-house maintenance and post-sale ',
        'mantenimiento 5': '',
        'inhouse y mantenimiento posventa': ' maintenance model allows us to build ',
        ' nos ': 'personalized',
        'permiten construir soluciones ': 'solutions for each client, ensuring the highest',
        'personalizadas para cada cliente, ': 'quality standards andan efficient maintenance',
        'asegurando los m\xE1s altos est\xE1ndares de ': 'plan for all any biomedical technology',
        'calidad y un plan de mantenimiento ': 'involved.',
        'eficiente para toda la ': '',
        'tecnolog\xEDa ': '',
        'biom\xE9dica involucrada. ': '',
        'Más': 'more',
        'Nuestras manos expertas pueden entender y cuidar la tecnolog\xEDa para la tranquilidad de nuestros clientes y ': 'Our expert hands can understand and take care of any type of medical technology, for the peace of mind of our clients,',
        'proveedores, todo para crear soluciones para el cuidado de la salud y los pacientes. ': ' and suppliers all focused on finding new and better solutions for patients and health care in general.',
        'Atendemos una capacidad instalada cercana a los ': 'Our installations currently serve about ',
        '12.000 equipos': '12.000 devices',
        ' de marcas representadas ': ' from all our represented brands.',
        'Contamos con ':'We have',
        '130 profesionales': '130 professionals ',
        'dedicados al cuidado de la tecnolog\xEDa biom\xE9dica y ': 'dedicated to the care of biomedical technology',
        'comprometidos con la seguridad de los pacientes ': 'and committed to patient safety.',
        ' SOLUCIONES EN SALUD': "HEALTH SOLUTIONS",
        'Medicamentos': 'Medications',
        'Cirugía robótica': 'Robotic surgery',
        'Equipos médicos': 'Medical equipment',
        'Ortopedia': 'Orthopedics',
        'Logística hospitalaria': 'Hospital logistics',
        'Dispositivos': 'Medical-surgical',
        'médico-quirúrgicos': 'devices',
        'Genómica y genética': 'Genomics and genetics',
        'Instrumental y esterilización': 'Instruments and sterilization',
        'Cuidado avanzado': 'Advanced',
        'de heridas': 'wound care',
        'Neurointervencionismo': 'Neurointerventionism',
        'SOLUCIONES EN SALUD': 'HEALTH SOLUTIONS',
        'MEDICAMENTOS ': 'Medications',
        'Con medicamentos de la más alta tecnología para el cuidado de la ': 'We deliver medications of the highest quality and cutting-edge technology, all',
        'salud de los pacientes, ya sea adultos, ni\xF1os o neonatos, ': 'center around the patients benefit.  Regardless of age—adults, children, or ',
        'ofrecemos mayor eficacia y tolerabilidad al paciente en productos.': 'neonates—we provide enhanced effectiveness and tolerability across all our products. ',
        'especializados para:': "specialized for:",
        'Trasplantes ': 'Transplants',
        'Hemoderivados ': "Blood products",
        'Antibi\xF3ticos  ': 'Antibiotics ',
        'Reumatolog\xEDa ': "Rheumatology ",
        'Respiratoria  ': 'Respiratory Solutions',
        'UCI Neonatolog\xEDa ': 'UCI Neonatolog\xEDa ',
        'UCI Pedi\xE1trica  ': 'Pediatric ICU',
        'UCI Adultos ': 'Adult ICU ',
        'Dolor ': 'Pain Management',
        'Gen\xE9tica\u202Fy\u202Fgen\xF3mica ': 'Genetics and genomics',
        'Neurointervencionismo ': 'Neuro-interventionism',
        'Volver': 'Return',
        'Una extensi\xF3n natural de los ojos, a trav\xE9s de una visi\xF3n en 3D y ':'',
        'magnificaci\xF3n hasta 10 veces. Movimientos intuitivos con mayor ': 'A natural extension of the human eye, through 3D vision and 10x',
        'rango de movimiento que exceden las capacidades de la mano ': 'magnification. Intuitive movements with a greater range of motion',
        'humana. ': 'that exceed the capabilities of the human hand. ',
        'Somos el comercializador del sistema quir\xFArgico da Vinci, con una ': 'We proudly represent and distribute the da Vinci surgical system, ',
        'experiencia de m\xE1s de 3.000 cirug\xEDas en nuestro pa\xEDs, una ': 'with more than 3,000 surgeries performed in our country, a ',
        'sofisticada plataforma rob\xF3tica que permite potenciar las ': 'sophisticated robotic platform that allows enhancing the surgeon’s ',
        'capacidades del cirujano y ofrecer una opci\xF3n m\xEDnimamente ': 'capabilities, thus offering a minimally invasive option in complex ',
        'invasiva en procedimientos de cirug\xEDa compleja.  ': 'surgical procedures.  ',
        'El sistema da Vinci da tratamiento a cuatro de los cinco tipos de ': 'The da Vinci system treats four of the five most common types of ',
        'c\xE1ncer m\xE1s frecuente en Colombia: c\xE1ncer de pr\xF3stata, c\xE1ncer de ': 'cancer in Colombia: prostate cancer, stomach cancer, lung cancer, ',
        'est\xF3mago, c\xE1ncer de pulm\xF3n, c\xE1ncer colorrectal y muchas m\xE1s ': 'colorectal cancer and many more pathologies. ',
        'patolog\xEDas.': '',
        'CIRUG\xCDA ROB\xD3TICA ': 'Robotic surgery',
        'Beneficios de la cirug\xEDa rob\xF3tica:': 'Robotic surgery benefits:',
        'Paciente': 'Patient',
        'Cirujano': 'Surgeon ',
        'Instituci\xF3n': 'Institution ',
        'Menor tiempo de hospitalizaci\xF3n. ': 'Shorter hospitalization time',
        'Recuperaci\xF3n con menos dolor y riesgo ': 'Recovery with less pain and less ',
        'de infecci\xF3n. ': 'risk of infection',
        'Cicatrices m\xE1s peque\xF1as. ': 'Smaller scars ',
        'R\xE1pido retorno a las actividades ': 'Quick return to daily ',
        'diarias. ': 'activities',
        'Mejor calidad de vida. ': 'Better quality of life ',
        'Movimientos intuitivos. Rotaci\xF3n de ':'Intuitive movements, 540° rotation, ',
        '540\xB0, articulaci\xF3n de 180\xB0.': '180° articulation ',
        'Visi\xF3n 3D y magnificaci\xF3n 10X.': '3D vision and 10X magnification ',
        'Mayor visi\xF3n, control y ergonom\xEDa. ': 'Greater vision, better control and ergonomics',
        'Mayor precisi\xF3n y seguridad. ': 'Greater precision and safety',
        'Innovaci\xF3n y diferenciaci\xF3n. ': 'Innovation and differentiation',
        'Investigaci\xF3n y academia. ': 'Research and academia',
        'Tecnolog\xEDa de vanguardia. ': 'Cutting-edge technology ',
        'Atracci\xF3n de nuevos pacientes y ': 'Attracting new patients and',
        'cirujanos. ': 'surgeons',
        'Mejores resultados cl\xEDnicos. ': 'Better clinical results ',
        'EQUIPOS M\xC9DICOS ': 'Medical equipment',
        'Ofrecemos a los especialistas equipos de la m\xE1s alta tecnolog\xEDa ': 'We offer specialists the highest technology equipment for patient ',
        'para el servicio y asistencia de los pacientes.': 'service and assistance: ',
        'Monitores de signos vitales ': 'Vital signs monitors  ',
        'Centrales de monitoreo ': 'Monitoring centers ',
        'Electrocardiograf\xEDa ': 'Electrocardiography ',
        'Ventilaci\xF3n  ': 'Ventilation',
        'Equipos para neurolog\xEDa: ': 'Neurology equipment:',
        'electroencefalograf\xEDa, polisomnograf\xEDa ': 'electroencephalography, ',
        'y electromiograf\xEDa. ': 'polysomnography, and electromyography',
        'Camas y camillas hospitalarias ': 'Hospital beds and stretchers ',
        'Mesas de cirug\xEDa ': 'Surgery tables ',
        'Incubadora neonatal y l\xE1mparas de ': 'Neonatal incubator and phototherapy ',
        'fototerapia ': 'lamps',
        'Desfibrilador Externo Autom\xE1tico (DEA) ': 'Automatic External Defibrillator (AED) ',
        'L\xE1mparas para quir\xF3fano, ': 'Lamps for operating room, procedures,',
        'procedimientos y exploraci\xF3n ': 'and examination ',
        'Unidades de suministro medicinales ': 'Medicinal supply units',
        'Con una variada gama de soluciones para \xE1reas especializadas, UCIs, quir\xF3fanos, urgencias y hospitalizaci\xF3n. ':'We offer an ample range of solutions for specialized areas, ICU, operating rooms, emergencies, and hospitalization.',
        'En cumplimiento de la Ley 1672 del 19 de julio de 2013, Decreto 284 de 2018 y Resoluci\xF3n 851 del 2022. Amarey Nova ': 'In compliance with Colombia’s Law 1672, July 19, 2013, Decree 284 of 2018 and Resolution 851 of 2022, our group’s ',
        'Medical S.a-medical, Ucipharma S.a-medical. y Novat\xE9cnica S.a-medical.S., ponen a su disposici\xF3n nuestro proceso ': 'companies: Amarey Nova Medical SA, Ucipharma SA and Novatécnica SAS, put at your service a process for waste',
        'de disposici\xF3n y gesti\xF3n de los residuos de aparatos el\xE9ctricos y electr\xF3nicos (RAEE) relacionados espec\xEDficamente con los equipos ': 'disposal and waster management for electrical and electronic equipment (WEEE), specifically related to electrical or',
        'biom\xE9dicos el\xE9ctricos o electr\xF3nicos (con excepci\xF3n de todos los productos implantados e infectados) que ': 'electronic biomedical equipment (with the exception of implanted and infected products) distributed by our ',
        'distribuye nuestras compa\xF1\xEDas. Para este fin, solicitamos comunicarse directamente con nuestro representante de ': 'companies. For this purpose, we kindly request that you contact our sales representative directly assigned to your ',
        'ventas asignado a su instituci\xF3n con el objetivo de programar su recolecci\xF3n. Para la disposici\xF3n final de los RAEE ': 'institution in order to schedule this collection. Management of final disposal of WEEE is executed through a company ',
        'realizamos la gesti\xF3n a trav\xE9s de una empresa autorizada por la Autoridad Ambiental.  ': 'authorized by the national Environmental Authority. ',
        'ORTOPEDIA': 'Orthopedics',
        'Somos líderes con tecnología que aporta a los profesionales de la ':'We are leaders in technology providing health professionals and',
        'salud y sus pacientes las herramientas en cirugías que permiten ':'their patients the needed tools for surgeries that allow a more',
        'alcanzar una calidad de vida más activa y satisfactoria mediante ':'active and satisfactory life, specifically in the following four ',
        'las líneas de:  ':'verticals: ',
        'Nuestro modelo integral de servicio de ': 'Our orthopedics integral model of ',
        'ortopedia ofrece disponibilidad 24/7 los ': 'service offers availability 24/7, 365 days',
        '365 d\xEDas del a\xF1o, con un servicio que ': 'a year, providing accompaniment both',
        'ofrece acompa\xF1amiento en el quir\xF3fano ': 'at the operating table and in post-sales ',
        'y servicio posventa. ': 'service needs.',
        'Contamos con una red log\xEDstica de ': 'Our logistic network for orthopedic ',
        'ortopedia para atenci\xF3n de cirug\xEDas en ': 'surgery support has presence across',
        'ciudades principales e intermedias de ': 'the main and intermediate cities of',
        'Colombia y Costa Rica. ': 'both Colombia and Costa Rica. ',
        'LOG\xCDSTICA':'Hospital',
        'HOSPITALARIA':'logistics',
        'Nuestro portafolio de soluciones tecnol\xF3gicas est\xE1 enfocado en la ': 'Our technological solutions portfolio is focused on the automation ',
        'automatizaci\xF3n de procesos intrahospitalarios para el ': 'of intrahospital processes for medication and medical-surgical',
        'almacenamiento y la dispensaci\xF3n de medicamentos y material ': 'material storage and dispensing, thus increasing patient safety and ',
        'm\xE9dico-quir\xFArgico, aumentando la seguridad del paciente y la ': 'efficiency across all processes. ',
        'eficiencia en los procesos. ': '',
        'Alineamos estos procesos a los ': 'We align these processes with the ',
        'lineamientos de la ': 'guidelines of the Joint Commission',
        'Joint Commission ': ' International (JCI),',
        'International':'always seeking ',
        ', buscando las mejores ': 'the best practices to ensure the',
        'pr\xE1cticas para asegurar la trazabilidad ': 'traceability of medicines and generating',
        'de medicamentos y generando en ': 'all inventory movements in real time. ',
        'tiempo real los movimientos de ': '',
        'inventario. ': '',
        'Dispositivos ': 'Medical-surgical',
        'médico-quirúrgicos ': 'devices',
        'Somos un aliado estrat\xE9gico para los profesionales que realizan ':'We are a strategic ally for professionals who perform surgical ',
        'procedimientos quir\xFArgicos con equipos y dispositivos m\xE9dicos que ':'procedures with sophisticated medical equipment and devices that',
        'mejoran los resultados del paciente en una intervenci\xF3n.':'improve patient outcomes in an intervention.',
        'Dispositivos m\xE9dico-quir\xFArgicos': 'Medical-surgical devices',
        'Mejoramos los resultados en una ': 'We improve results in an',
        'intervenci\xF3n con:  ': 'intervention with:',
        'Apoyamos la seguridad del ':'We support patient safety with:',
        'paciente con:':'',
        'Equipos y dispositivos m\xE9dicos para cirug\xEDa ': 'Medical equipment and devices for ',
        'laparosc\xF3pica ': 'laparoscopic surgery',
        'Dispositivos para reparo de hernias inguinales ': 'Devices for inguinal hernia ',
        'y ventrales ': 'repair and ventral',
        'Ropa quir\xFArgica y barreras de protecci\xF3n ':'Surgical clothing and specialized protective',
        'especializadas ':'barriers',
        'Hemost\xE1ticos y sellantes para cirug\xEDa ':'Hemostatic products and sealants for ',
        'm\xEDnimamente invasiva':'minimally invasive surgery',
        'Protecci\xF3n de la herida quir\xFArgica. ': 'Surgical wound protection',
        'Control del sangrado. ': 'Bleeding control',
        'Disminuci\xF3n de la infecci\xF3n en el sitio ': 'Reduction of infection in the ',
        'operatorio. ': 'operative site',
        'Disminuci\xF3n de tiempos quir\xFArgicos. ': 'Reduction in surgical times',
        'Disminuci\xF3n de estancias hospitalarias ':'Reduction in hospital stays',
        'Todos productos de nuestro portafolio est\xE1n aprobados por la FDA y cuentan con altos est\xE1ndares de calidad. ': 'All products in our portfolio are approved by the FDA and have high quality standards.',
        'Contamos con un centro de lavado con la \xFAltima tecnolog\xEDa y est\xE1ndares, para permitirnos ofrecer un producto ': 'We have a washing center with the latest technology and standards, to allow us to offer a product with the best',
        'con la mejor calidad y garantizar las condiciones sanitarias de kits de cirug\xEDa utilizados. Adicionalmente, ':'quality and guarantee the sanitary conditions of used surgery kits. Additionally, we provide surgical.',
        'realizamos acompa\xF1amiento quir\xFArgico en cirug\xEDa. ':'support during such surgeries',
        'Gen\xF3mica Y Gen\xE9ticA': 'Genomics and genetics',
        'Gen\xE9ticA': 'genetics',
        'Gen\xF3mica': 'Genomics',
        'La gen\xE9tica es el estudio de rasgos y genes heredados que ': 'Genetics is the study of inherited traits and genes that may ',
        'pueden predisponer a una persona a una condici\xF3n de salud ':'predispose a person to any health condition.',
        'particular.':'',
        'La gen\xF3mica analiza la forma en que se comporta un conjunto ':'Genomics the study of sets of genes and their functions. ',
        'de genes. ':'',
        'Las pruebas gen\xF3micas (tambi\xE9n conocidas como ':'Genomic testing (also known as multigene ',
        'pruebas multig\xE9nicas) analizan una muestra del ':'testing) analyzes a sample of a cancerous tumor',
        'tumor del c\xE1ncer para analizar el nivel de actividad ':'to understand the level of activity of certain',
        'de determinados genes, el cual afecta el ':'genes, which affect the behavior of the disease, ',
        'comportamiento del c\xE1ncer, incluyendo su ':'including growth and spread likelihoods. One of ',
        'probabilidad de crecimiento y propagaci\xF3n. Una de ':'these genomic tests also provides an estimate of ',
        'estas pruebas gen\xF3micas en particular ':'the benefit of chemotherapy. They are used to help ',
        'proporciona, adem\xE1s, una estimaci\xF3n del beneficio ':'make decisions about treatment after surgery.',
        'de la quimioterapia. Se usan para ayudar en la ':'',
        'toma de decisiones sobre tratamiento despu\xE9s de ':'',
        'la cirug\xEDa. ':'',
        'En el caso del c\xE1ncer de mama puede ayudar a ':'This test can help determine the aggressiveness of ',
        'determinar la agresividad de un tumor o la ':'a tumor or the probability that a certain ',
        'probabilidad que un determinado tratamiento ':'treatment will improve the result specifically In the ',
        'mejore el resultado, permitiendo escoger el plan de ':'case of breast cancer, allowing the physician to choose',
        'cuidado adecuado para el paciente.':'a determined treatment plan best fitting for the patient.  ',
        'Gen\xE9tica ': 'genetics',
        'Esto significa que si usted tiene un historial familiar ':'This means that if you have a strong family history of',
        'marcado de c\xE1ncer de mama, es probable que haya ':'breast cancer, you have likely inherited an abnormal ',
        'heredado un gen anormal vinculado a un mayor riesgo ':'gene linked to an increased risk of developing breast',
        'de sufrir c\xE1ncer de mama (como las mutaciones de los ':'cancer (such as mutations in the BRCA 1 or 2 genes).',
        'genes BRCA 1 o 2). ':'¿Why perform genomic and genetic tests? ',
        'Son las pruebas base de medicina de precisi\xF3n o ':'They are the basic tests of precision medicine',
        'medicina personalizada, ya que cada individuo es ':'or personalized medicine, understanding that',
        'diferente ':'everyone is different.',
        'Gu\xEDan al profesional en la elecci\xF3n del tratamiento ':'They guide professionals in choosing the most',
        'm\xE1s adecuado para el paciente. ':'appropriate treatment for patients ',
        'Permite calcular el riesgo de recurrencia. ':'They usher recurrence risk calculation. ',
        'Muestra un beneficio real para el paciente y su ':'They show a real benefit for patients and their',
        'familia. ':'families',
        'Se pueden analizar m\xE1s de 3.800 enfermedades. ':'More than 3,800 diseases can be analyzed ',
        'Determinan calidad de vida del paciente.':'They determine a patients quality of life',
        'INSTRUMENTAL Y':'Instruments and',
        'ESTERILIZACIÓN':'sterilization',
        'Con un completo portafolio de instrumentos para las diferentes ':'We have an ample portfolio of instruments for different surgical ',
        'especialidades quir\xFArgicas, y la interacci\xF3n entre los productos y ':'specialties that optimizes the interaction between products and',
        'los procesos de lavado y esterilizaci\xF3n.':'their washing and sterilization processes, our professional.',
        'nuestro equipo profesional est\xE1 presto a ':'Our team is ready to provide information, ',
        'brindar informaci\xF3n, asesor\xEDa, ':'advice, support, suggestions and solutions ',
        'acompa\xF1amiento, sugerencias y soluciones ':'to your plans and projects with:',
        'a sus planes y proyectos con:':'',
        'Siempre enfocados en optimizar y preservar ':'We’re always focused on optimizing and ',
        'su inversi\xF3n. ':'preserving your investments. ',
        'Instrumental quir\xFArgico y su ':'Surgical instruments and their ',
        'mantenimiento -2': 'maintenance',
        'Contenedores ecol\xF3gicos ':'Ecological containers',
        'Equipos para lavado y ':'Equipment for washing and ',
        'termodesinfecci\xF3n ':'thermo-disinfection ',
        'Equipos para esterilizaci\xF3n ': 'Equipment for sterilization',
        'CUIDADO AVANZADO DE': 'ADVANCED Wound Care',
        'HERIDAS':'',
        'Ofrecemos una l\xEDnea de ap\xF3sitos avanzados y terapia de presi\xF3n ':'We offer a line of advanced medical dressings and negative',
        'negativa para el tratamiento integral de heridas agudas, cr\xF3nicas y ':'pressure therapy for the comprehensive treatment of acute, chronic ',
        'complejas, como las ocasionadas por pie diab\xE9tico, lesiones por ':'and complex wounds, like those caused by diabetic foot, pressure ',
        'presi\xF3n y lesiones de origen vascular.':'injuries and injuries of vascular origin.',
        'CUIDADO':'Advanced',
        'AVANZADO':'wound care',
        'DE HERIDAS':'',
        'Nuestro compromiso es asesorar a los profesionales ':'Our commitment is to advise health professionals in ',
        'de la salud en el uso, manejo y aprovechamiento ':'the correct use, implementation and management',
        'correcto de dichas tecnolog\xEDas. ':'of these technologies. ',
        'Ayudando al paciente a mejorar su ':'Thus helping the patient to improve their quality of life, through:',
        'Soluciones simples y efectivas ':'Simple and effective solutions ',
        'Aceleraci\xF3n del proceso de cicatrizaci\xF3n  ':'Acceleration of the healing process',
        'Estimulaci\xF3n de la formaci\xF3n de nuevo tejido  ':'Stimulation of the formation of new tissue',
        'Prevenci\xF3n de infecciones ':'Infection prevention ',
        'NEUROINTERVENCIONISMO ': 'Neurointerventionism',
        'El ACV - Accidente Cerebro Vascular es algo que le puede ocurrir a ':'A Cerebral Vascular Accident (CVA) can occur to 1 out of every',
        '1 de cada 4 personas y menos del 5% de las personas que lo sufren ':'4 people and less than 5% of those who suffer from it are ',
        'son atendidas a tiempo.':'treated in time. ',
        'La ciencia viene evolucionando en forma r\xE1pida para identificar y tratar esta patolog\xEDa conocida como ictus, ':'Science has been evolving rapidly to identify and treat this pathology known as a stroke, that can be ischemic ',
        'dividido entre isqu\xE9mico (trombo) y hemorr\xE1gico (aneurisma), donde el neurointervencionismo como disciplina ':'(thrombus) or hemorrhagic (aneurysm). This is where the youg discipline known as neuro-interventionism is',
        'joven se va consolidando como alternativa para determinados casos. ':'increasingly consolidating as an alternative for certain cases.',
        'Nuestro portafolio es reconocido por su calidad y renombre, con fortalezas en opciones de ACCESO, una gran ':'Our portfolio is recognized for its quality and reputation, with strengths in ACCESS options, a wide variety of Coils',
        'variedad de Coils y una tecnolog\xEDa \xFAnica para trombectom\xEDa, incluyendo una estructura de log\xEDstica inversa que ':'and a unique thrombectomy technology, including a reverse logistics structure that allows us to ensure a high',
        'nos permite asegurar un servicio de alta calidad para los requerimientos de tiempo y disponibilidad en las ':'quality service for all time requirements and all requested therapeutic window availability.',
        'ventanas terap\xE9uticas. ': '',
        'Trauma ':'Trauma',
        'Reemplazo articular ':'Articulation replacements',
        'Artroscopia ':'Arthroscopy',
        'Cráneo y maxilofacial ': 'Skull and maxillofacial ',
        '\xC9TICA Y COMPLIANCE': 'Ethics and Compliance',
        'C\xF3digo de':'Code of',
        '\xE9tica':'Ethics',
        'L\xEDnea ': 'The Ethics',
        'ética ': 'Line',
        'biomédico_2': 'maintenance',
        'Mantenimiento': 'Biomedical',
        'C\xF3digo de \xE9tica': 'Code of Ethics',
        'En el Grupo Amarey estamos comprometidos en mantener relaciones honestas, ':'At the Amarey Group, we are committed to maintaining honest, constructive, ',
        'constructivas e id\xF3neas con nuestros grupos de inter\xE9s. Guiados por nuestros ': 'and appropriate relationships with all our stakeholders. Guided by our set of ',
        'valores corporativos hemos creado el presente C\xF3digo de \xE9tica, a trav\xE9s del ': 'corporate values, we have created a code of ethics to establish the rules of ',
        'cual establecemos las reglas de conducta \xE9tica que deben ser seguidas y ':'ethical conduct that must be followed and observed by all individuals ',
        'observadas en su comportamiento diario por todas las personas vinculadas a ':'associated with the company on a daily basis. This includes members of the ',
        'la empresa, tanto los miembros de la junta directiva, directores, empleados de ':'board of directors, C-suite executives, managers, and employees at all levels',
        'todos los niveles, proveedores, asesores y contratistas, as\xED como para los ':'as well as suppliers, advisors, contractors, and indirect employees.',
        'empleados indirectos.':'',
        'Ver C\xF3digo':'Read our Code',
        'de \xE9tica':'of Ethics',
        'Ver Manual de': 'Read our',
        'Compliance':'Compliance Manual',
        'L\xEDnea \xE9tica': 'The Ethics Line',
        'La l\xEDnea \xE9tica es un canal dise\xF1ado para que empleados, proveedores, aliados ':'The ethics line is a channel designed for employees, suppliers, strategic allies,',
        'estrat\xE9gicos y clientes puedan reportar de forma an\xF3nima y confidencial ':'and clients to anonymously and confidentially report alleged violations of our ',
        'presuntas violaciones a nuestro C\xF3digo de \xE9tica, as\xED como posibles actos ':'code of ethics, as well as possible incorrect acts, fraud, corruption, ',
        'incorrectos, fraudes, corrupci\xF3n, acoso y otros aspectos que afecten la ':'harassment, and other aspects that might affect the good name and image',
        'imagen y buen nombre de la compa\xF1\xEDa.':'of the company.',
        'Las denuncias pueden realizarse a trav\xE9s del correo electr\xF3nico los 7 d\xEDas de la ':'Reports can be made via email seven days a week, 24 hours a day.',
        'semana, las 24 horas del d\xEDa.': '',
        '\xBFC\xF3mo puedo comunicarme con la l\xEDnea \xE9tica?': 'How can I contact the ethics line?',
        'A trav\xE9s del correo electr\xF3nico: linea.etica@grupoamarey.com': 'Through email: linea.etica@grupoamarey.com',
        '\xBFCu\xE1ndo debo utilizar la l\xEDnea \xE9tica?': 'When should I use the ethics line?',
        '¿Cuándo debo utilizar la línea ética? ' :'When should I use the ethics line?',
        'Cuando identifique situaciones no \xE9ticas que puedan afectar los intereses del Grupo ':'You should use the ethics line when you identify unethical situations that may affect the ',
        'Amarey como:':'interests of the Amarey Group, such as:',
        'Conflicto de interés':'Conflict of interest',
        'Lavado de activos':'Money laundering',
        'D\xE1divas o prebendas':'Gifts or perks',
        'Incumplimiento de leyes ':'Non-compliance with',
        'y regulaciones':'laws and regulations',
        'Actividades inapropiadas': 'Inappropriate activities',
        'Confidencialidad':'Confidentiality',
        'Pr\xE1cticas comerciales ':'Inappropriate',
        'inadecuadas':'commercial practices',
        'La situación en virtud de la cual una persona, por su actividad o ': 'This occurs when a person, due to their activity or position, or due',
        "cargo, o por la información que posee, se enfrenta a distintas ":"to the information they possess, is faced with different behavioral ",
        "alternativas de conducta en relación con intereses incompatibles, ":"alternatives in relation to incompatible interests., where it would be ",
        "siendo incorrecto privilegiar alguno de ellos, pues se vulneraría o" : "incorrect to privilege any of them, as it would unfairly violate or" ,
        "debilitaría injustamente la posición del otro.":"weaken the position of the other.",
        'Incumplimiento de leyes y regulaciones': 'Non-compliance with laws and regulations',
        'Negocios de la compañía que no se realicen dentro del marco legal ':'This refers to any business related to the company that would not be carried',
        'y/o que sus colaboradores no obren dentro de este. Incumplimiento ': 'out within a legal framework and/or when collaborators do not act within it. ',
        'a lo dispuesto en el código de ética, reglamento interno de trabajo, ':'It includes non-compliance with the provisions of the code of ethics, internal',
        'políticas de contratación y pago a terceros, reglamentos, políticas ':'work regulations, third-party hiring and payment policies, regulations, and/',
        'y/o procedimientos específicos establecidos por el Grupo Amarey.':'or other specific policies and procedures established by the Amarey Group.',
        "Los empleados utilizarán la información no pública que sea de su": "Employees are expected to use non-public information they have ",
        "conocimiento, para realizar las actividades que desempeñen, de":"access to in a confidential and reserved manner. They should refrain ",
        "manera confidencial y reservada, y se abstendrán en todo":"at all times from obtaining or receiving personal benefits or ",
        'momento de obtener o recibir beneficios personales o provocar ': 'causing damage to the company due to the disclosure or improper ',
        "perjuicios a la empresa por su divulgación o utilización indebida.":"use of such information.",
        'Son operaciones que busca dar apariencia de legalidad a recursos ':'These are operations that give the appearance of legality seeking ',
        'provenientes de actividades ilícitas, a través de diversas':'resources coming from illicit activities through various transactions, ',
        'transacciones ocultando así el origen y trayectoria de los recursos.':'thus hiding the origin and trajectory of such resources.',
        'La relación entre los empleados del Grupo Amarey deberá estar':'Amarey Group employees must always conduct their interpersonal',
        'siempre basada en el debido y mutuo respeto, a fin de asegurar un': 'engagements with a basis of mutual respect to ensure a harmonious ',
        'ambiente armonioso conducente al trabajo productivo.': 'environment conducive to productive work.',
        'Prácticas comerciales inadecuadas': 'Inappropriate commercial practices',
        'En la relación con los clientes de Grupo Amarey, los empleados de la':'When engaging with the company’s clients, Grupo Amarey’s',
        'organización siempre deberán actuar con objetividad,':'employees must always act with objectivity, impartiality, fairness, ',
        'imparcialidad, equidad, excelencia y de acuerdo con los objetivos':'excellence, and in accordance with the companys image, growth,',
        'de imagen, crecimiento y rentabilidad de la empresa.':'and profitability objectives.',
        "Son todas aquellas ventajas, artículos o incentivos en dinero o":"These include all advantages, all items, or incentives in the form of ",
        'especie que se entregan a terceros (cualquier relacionado con ':'money or any other kind given to third parties (meaning, people ',
        "compra, distribución, comercialización, prescripción y dispensación":"related to the purchase, distribution, marketing, prescription, and ",
        "de fármacos, dispositivos médicos o insumos de salud), con el":"dispensing of medicines, medical devices, or health supplies) with ",
        'propósito de obtener beneficios comerciales que no se ajustan a':'the purpose of obtaining commercial benefits that do not comply ',
        'los principios de la promoción ética, de la competencia leal y del':'with the principles of ethical promotion, fair competition, and',
        'respeto por los principios, la moral y el buen actuar.':'respect for general principles, morality and good behavior.',
        'Juntos trabajamos por alcanzar ':'Together we work to achieve',
        'nuestro prop\xF3sito de un compromiso ':'our purpose of a commitment',
        'inquebrantable con la salud y la vida.':'unwavering with health and life.',
        '¿Por qué unirte a nosotros?': 'Why join us? ',
        'Como unirse?': 'How to join? ',
        '\xA1Esperamos conocerte pronto!': 'We hope to meet you soon!',
        'Cada d\xEDa nos esforzamos por el bienestar de nuestros colaboradores. Somos ':'Every day we strive for the well-being of our collaborators. We are a company ',
        'una empresa de ':'with Colombian capital, committed to health and dedicated to expanding life ',
        'capital colombiano':'',
        ', comprometida con la salud y dedicada a ':'',
        'ampliar las oportunidades de vida.':'opportunities. ',
        'En Grupo Amarey, sabemos que el talento excepcional es la clave para hacer ':'At Grupo Amarey, we know that exceptional talent is the key to doing incredible things and achieving',
        'cosas incre\xEDbles y ': '',
        'alcanzar resultados extraordinarios. ':'extraordinary results. We generate inclusive, collaborative, challenging and stimulating work ',
        'Generamos ': '',
        'entornos de trabajo inclusivos, colaborativos, ':'',
        'retadores y estimulantes':'environments where each employee can grow and contribute significantly to the achievement of our ',
        ' donde cada colaborador puede aportar, crecer y contribuir de manera ':'',
        'significativa al': 'objectives.',
        ' logro de nuestros objetivos.':'',
        'Si est\xE1s interesado en formar parte del Grupo Amarey, te invitamos a enviar tu hoja de ':'If you are interested in being part of the Amarey Group, we invite you to send your resume',
        'vida a ':'to ',
        'atracciondetalento@grupoamarey.com': 'atracciondetalento@grupoamarey.com',
        ', indicando la ciudad donde vives.':', indicating the city where you live.',
        'Estamos en b\xFAsqueda de personas ':'We are looking for passionate and committed people who share and reflect our values, aligned with ',
        'apasionadas y comprometidas,':'',
        ' que ':'',
        'compartan y reflejen ':'',
        'nuestros valores,':'the SER philosophy',
        ' alineados con ':' (Service, Education and Relationships) and that are willing to take on daily',
        'la ':'',
        'filosof\xEDa SER':'',
        ' (Servicio, Educaci\xF3n y Relacionamiento)':'',
        ' y est\xE9n ':'',
        'dispuestos a asumir retos diarios y llevar la Organizaci\xF3n a otros niveles. ':'challenges to lead the company to success. ',
        'CONTÁCTENOS': 'Contact us',
        'Línea de atención al cliente 018000 180066 ': 'Client services line: 018000 180066',
        'PBX: 6017447300':'PBX: 6017447300',
        'PQR: servicioalcliente@grupoamarey.com-item-1827':'PQR: servicioalcliente@grupoamarey.com',
        'Atención de pedidos:':'Order attention:',
        '       atención.cliente@grupamarey.com':'       atención.cliente@grupamarey.com',
        '       ciac.ucipharma@grupoamarey.com':'       ciac.ucipharma@grupoamarey.com',
        'Formulario de contacto': 'Contact Form',
        'Nombre completo ':'Full name* ',
        'Email ': 'Email* ',
        'Instituci\xF3n ':'Institution / Company',
        'Asunto ':'Issue',
        'Pa\xEDs ':'Country',
        'Tel\xE9fono ':'Phone',
        'Mensaje ':'Message',
        'Al enviar este formulario, usted reconoce haber le\xEDdo y estar de acuerdo con nuestra pol\xEDtica ':'By sending this form, you acknowledge that you have read and agreed with our',
        'de ': '',
        'Tratamiento de datos personales.': 'Personal data management policy.',
        'enviar': 'To send',
        'Prográmese con ': 'Schedule with',
        'Grupo Amarey ': 'Amarey Group',
        "Mayo 2024": 'May 2024',
        "Do":"Su",
        "Lu":"Mo",
        "Ma":"Tu",
        "Mi":"We",
        "Ju":"Th",
        "Vi":"Fr",
        "Sa":"Sa",
        "Fecha de hoy":"Today's date",
        "Fecha evento":"Event date",
        "16 de mayo de 2024": "May 16, 2024",
        '02 de mayo de 2024': 'May 2, 2024',
        '03 de febrero de 2024': 'February 3, 2024',
        '06 de Marzo de 2024': 'March 6, 2024',
        '09 de febrero de 2024': 'February 9, 2024',
        '10 de febrero de 2024': 'February 10, 2024',
        '29 de febrero de 2024': 'February 29, 2024',
        '07 de marzo de 2024': 'March 7, 2024',
        '08 de marzo de 2024': 'March 8, 2024',
        '09 de marzo de 2024': 'March 9, 2024',
        '15 de marzo de 2024': 'March 15, 2024',
        '04 de mayo de 2024': 'May 4, 2024',
        "18 de mayo de 2024": 'May 18, 2024',
        "22 de mayo de 2024": 'May 22, 2024',
        "23 de mayo de 2024": 'May 23, 2024',
        "24 de mayo de 2024": 'May 24, 2024',
        "25 de mayo de 2024": 'May 25, 2024',
        '30 de mayo de 2024': 'May 30, 2024',
        '31 de mayo de 2024': 'May 31, 2024',
        'Junio': 'June',
        "Julio": 'July',
        "Julio 2024": 'July 2024',
        'Junio 2024': 'June 2024',
        'Mayo 2024 ': 'May 2024',
        'Agosto': 'August',
        'Agosto 2024': 'August 2024',
        "Septiembre": 'September',
        "Septiembre 2024": 'September 2024',
        "Octubre": 'October',
        "Octubre 2024": 'October 2024',
        "Noviembre": "November",
        "Noviembre 2024": 'November 2024',
        "Diciembre": 'December',
        "Diciembre 2024": 'December 2024',
        "Enero": 'January',
        "Enero 2025": 'January 2025',
        'Febrero 2024': 'February 2024',
        'Marzo 2024': 'March 2024',
        'Trayectoria': 'Trajectory',
        'Home': 'Home',
        'Soluciones y': 'Solutions and',
        'productos distribuidos':'distributed products',
        'Actualidad\u200B': 'News & Events​',
        '\xC9tica y':'Ethics and',
        'compliance\u200B':'Compliance',
        'Trabaje':'Work',
        'con nosotros':'with us',
        'Cont\xE1ctenos': "Contact us",
        'rse':'CSR',
        'Nuestros ALIADOS': 'OUR ALLIES',
        'soluciones EN SALUD': 'Solutions and distributed products',
        'nOTICIAS': 'news',
        'eVENTOS': 'events',
        'tRAYECTORIA':'trajectory',
        'soluciones':'HEALTH',
        'EN SALUD':'SOLUTIONS',
        'aCTUALIDAD':'News & Events',
        'ETICA Y':'Ethics and',
        'COMPLIANCE':'Compliance',
        'Descubra ':'Discover our unwavering commitment to ethics ',
        'nuestro compromiso inquebrantable ':'',
        'con la \xE9tica y el cumplimiento normativo.':'and regulatory compliance.',
        'TRABAJE CON':'Work',
        'NOSOTROS':'with us',
        'Sea parte de nuestro talentoso y comprometido equipo.':'Be part of our talented and committed team.',
        'Encuentre oportunidades de carrera. \u200B':'Find career opportunities.​',
        'CONTACTENOS': 'Get in touch with us',
        '\xBFTiene alguna pregunta o necesita ': 'Do you have any questions or need ',
        'informaci\xF3n adicional?':'additional information?',
        'Cont\xE1ctenos y descubra c\xF3mo podemos ayudarle.\u200B':'Contact us and find out how we can help you.',
        'Comienza la distribución de la línea de equipos médicos': 'Distribution of the medical equipment line begins',
        'Reporte de eventos adversos o PQR: servicioalcliente@grupoamarey.com': 'Report adverse events or Claims: servicioalcliente@grupoamarey.com',
        '10 instituciones': '10 institutions',
        ...englishTextsMobile
    }
}

export const customTexts = {
    en: englishTexts,
    es: spanishTexts
};